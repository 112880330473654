import Keycloak from "keycloak-js";

import { configProvider } from "../../configProvider";

function getE2EToken(): string | null {
    return localStorage.getItem("e2e_idtoken");
}

let preemptiveRefresh: Promise<void>|null = null;
function getPreemptiveRefresh(kc: Keycloak.KeycloakInstance): Promise<void> {
    if (!preemptiveRefresh) {
        preemptiveRefresh = kc.updateToken(20)
            .then(() => { /* Swallow return value */})
            .catch(() => {
                console.error('[AuthService] getPreemptiveRefresh() failed');
            })
            .finally(() => {
                preemptiveRefresh = null; // Clear the singleton
            });
    }

    return preemptiveRefresh;
}

export async function jwtToken(): Promise<string | undefined> {
    if (configProvider.enableE2EToken) {
        const token = getE2EToken();
        if (token) {
            return token;
        }
    }

    if (!configProvider.disableKeyCloak) {
        const kc = window.keyCloak;
        if (!kc) {
            throw new Error("KeyCloak not available!");
        }

        // Fire off a preemtive token refresh, but don't await it yet
        const preemptiveRefresh = getPreemptiveRefresh(kc);

        const tokenIsExpired = kc.isTokenExpired(0);
        if (tokenIsExpired) {
            // Second chance: a succeful refresh
            await preemptiveRefresh;
            if (kc.isTokenExpired(0)) {
                // Token is expired and refresh failed
                // Discard the current page because the session is no longer authorized
                window.location.reload();
                return null;
            }
        }

        // We get here
        // (a) immediately if token is not expired, or
        // (b) after successfully awaiting a refresh
        return kc.token;
    }

    throw new Error("No token source available!");
}

export function jwtTokenExpired(timeAhead: number): boolean {
    if (configProvider.enableE2EToken) {
        const token = getE2EToken();
        if (token) {
            return false;
        }
    }

    if (!configProvider.disableKeyCloak) {
        const kc = window.keyCloak;
        if (!kc) {
            return true;
        }

        return kc.isTokenExpired(timeAhead);
    }

    // No token source available
    return true;
}
