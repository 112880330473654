import { IProject, IProjectForReporting } from "../project/ProjectModels";
import { JsonRESTApiClient } from "../api/JsonRESTApiClient";
import { ProjectApiV1Client } from "../project/ProjectApiV1Client";


/** @see `DH.BlueKitConnect.Backend.Features.Reporting.ReportingApiV1Controller` */
export class ReportingApiV1Client extends JsonRESTApiClient {

    public async submit(project: IProjectForReporting): Promise<IProject> {
        return await this._fetchJson<IProject>(this._getUrl(['submit']), {
            method: 'POST',
            headers: await this._getHeaders(),
            body: JSON.stringify(project),
        }, ProjectApiV1Client.ValidateIProject);
    }
}
