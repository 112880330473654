import * as React from 'react';
import { useState } from 'react';
import { Icon, Label, Table } from 'semantic-ui-react';

import CableModal from './_CableModal';
import { ICostPosition } from '../../CalculationModels';
import { FormatMontage, FormatPrice } from './_CostCalculationList';
import { PRODUCT_KEY } from '../../../product/lookup';
import { useLocalization, useProductLanguage } from '../../../../localization/hook';

export type ITableRowAccordionProps = {
    visible: boolean;
    title: string;
    className?: string;
    costs: ICostPosition;
    montage?: number;
    cabling?: boolean;
};

export const TableRowAccordion: React.FC<ITableRowAccordionProps> = props => {

    const [isVisible, setVisible] = useState(props.visible);
    const { localize } = useLocalization();
    const productLanguage = useProductLanguage();

    const rowClassName = "table-row-accordion " + props.className;
    const rowItemClassName = "table-row-item-accordion " + props.className;
    const hasProducts = props.costs?.products?.filter(x => x.quantity > 0).length > 0;


    function getEstimatedQuantity(key: PRODUCT_KEY | string) {
        const list = props.costs?.products?.filter(p => p.productKey === key);
        return list.length > 0
            ? list[0].estimatedQuantity
            : 0;
    }

    const fm2mEstimatedQuantity = getEstimatedQuantity('fm-2m');
    const cable4mEstimatedQuantity = getEstimatedQuantity('cable4m');
    const cable6mEstimatedQuantity = getEstimatedQuantity('cable6m');
    const cable12mEstimatedQuantity = getEstimatedQuantity('cable12m');


    function toggleVisibility() {
        setVisible(!isVisible);
    }

    return (
        <>
            <Table.Row className={rowClassName}>
                <Table.Cell colSpan="3" verticalAlign="top" className="no-padding">
                    <div className="title">
                        <span dangerouslySetInnerHTML={{__html: props.title}}/>
                        {
                            props.cabling &&
                            <CableModal
                                cable4mEstimatedQuantity={cable4mEstimatedQuantity}
                                cable6mEstimatedQuantity={cable6mEstimatedQuantity}
                                cable12mEstimatedQuantity={cable12mEstimatedQuantity}
                                fm2mEstimatedQuantity={fm2mEstimatedQuantity}
                            />
                        }
                    </div>
                </Table.Cell>
                <Table.Cell className="price-cell" textAlign="right" verticalAlign="top">
                    <FormatPrice pos={props.costs} />
                </Table.Cell>
                <Table.Cell className="price-cell" textAlign="right" verticalAlign="top">
                    <FormatMontage pos={{ montage: props.montage }} />
                </Table.Cell>
                <Table.Cell textAlign="right" verticalAlign="top">
                    {
                        hasProducts &&
                        <Label as="a" className="accordionLabel" onClick={toggleVisibility}>
                            {isVisible && hasProducts &&
                                <>{localize('accordion.reduce')}<Icon name="chevron up"/></>
                            }
                            {!isVisible && hasProducts &&
                                <>{localize('accordion.expand')}<Icon name="chevron down"/></>
                            }
                        </Label>
                    }
                </Table.Cell>
            </Table.Row>

            {isVisible && hasProducts && (
                <>
                    <Table.Row className={rowItemClassName}>
                        {['quantity', 'description', 'dhNo'].map(key => (
                            <Table.Cell className={'no-border ' + key} key={key}>{localize('costCalculation.table.accordion.column.' + key)}</Table.Cell>
                        ))}
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                    </Table.Row>
                    {
                        props.costs?.products?.filter(x => x.quantity > 0).map(product =>
                            <Table.Row className={rowItemClassName} key={product.id + '-' + product.description[productLanguage]}>
                                <Table.Cell className="no-border" verticalAlign="top">{product.quantity}</Table.Cell>
                                <Table.Cell className="no-border" verticalAlign="top">{product.description[productLanguage]}</Table.Cell>
                                <Table.Cell className="no-border" verticalAlign="top">{product.dhNo}</Table.Cell>
                                <Table.Cell className="price-cell" verticalAlign="top" textAlign="right">
                                    <FormatPrice pos={product} />
                                </Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>
                        )
                    }
                </>
            )}
        </>
    );
};
