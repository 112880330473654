import React from 'react';

export const TabSection = (props: React.PropsWithChildren<{ title?: JSX.Element | string }>) => (
    <div className="content-pane">
        {props.title &&
            <h2>{props.title}</h2>
        }
        <div className="description">
            {props.children}
        </div>
    </div>
);
