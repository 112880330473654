import {put, select, takeEvery} from 'redux-saga/effects';
import {change, initialize} from "redux-form";

import {IApplicationState} from '../../shared/ApplicationState';
import { IProductData } from '../product/types';
import {ReduxFormKeys} from "../form/ReduxFormKeys";
import {formActionCreator, FormActionTypes} from "../form/FormActions";

import {projectApi, projectApiActionCreator} from "./ProjectApi.redux";
import {calculationActionCreator, CalculationActionTypes} from './CalculationActions';
import { ICalculationFormsState } from "./CalculationModels";
import {
    getProductById,
} from "./CalculationService";
import { getApplicableSmokeDetectionMethods } from "./CalculationService/SmokeDetection";
import { getApplicatableLiftStatusDetectionMethods } from './CalculationService/LiftStatusDetection';
import {
    getAvailableLiftAreaPercentages,
    getTotalLiftArea,
    getTotalLiftAreaMax
} from "./CalculationService/LiftArea";
import { getAvailableSystems } from "./CalculationService/System";
import {
    getAvailableVentilationComponents,
    getQuantitiesFromVentilationComponentsFormModel
} from "./CalculationService/VentilationComponents";
import {COUNTRY, LIFT_AREA_PERCENTAGE, SMOKE_DETECTION} from '../project/lookup';
import { getActiveTabIndex, getForm, getProductData } from './calculation.selector';
import { Direction, Tabs } from './CalculationReducer';


interface ReduxFormChangeAction {
    type: CalculationActionTypes.REDUX_FORM_CHANGE;
    payload: any;
    meta: {
        field: string;
        form: string;
        touch: boolean;
    };
}

/** @see `CalculationActionTypes.REQUEST_SWITCH_TAB` */
function* requestSwitchTab({ payload: tabKey }: ReturnType<typeof calculationActionCreator.requestSwitchTab>) {
    const activeTabIndex = yield select(getActiveTabIndex);
    const loadedProject = yield select(projectApi.makeSelectBase('entity'));

    if (activeTabIndex === Tabs.BasicInput && tabKey === Direction.Next) {
        if (loadedProject?.id == null) {
            // Send update request to create the project
            yield put(projectApiActionCreator.updateRequest(loadedProject?.id, loadedProject?.state));
        }
    }

    window.scrollTo({top: 0, behavior: 'smooth'});

    yield put(calculationActionCreator.switchTab(tabKey));
    yield calculateCostsAndLoadFiles();
}

/** @see `CalculationActionTypes.REQUEST_SWITCH_TAB_INDEX` */
function* requestSwitchTabIndex({ payload: tabIndex }: ReturnType<typeof calculationActionCreator.requestSwitchTabIndex>) {
    const activeTabIndex = yield select(getActiveTabIndex);

    if (activeTabIndex === tabIndex) return;

    const loadedProject = yield select(projectApi.makeSelectBase('entity'));
    const form = yield select(getForm);

    if (form.ventilationComponentsForm) {
        if (activeTabIndex === Tabs.BasicInput && tabIndex !== Tabs.BasicInput) {
            if (loadedProject?.id == null) {
                // Send update request to create the project
                yield put(projectApiActionCreator.updateRequest(loadedProject?.id, loadedProject?.state));
            }
        }
    }

    window.scrollTo({top: 0, behavior: 'smooth'});

    yield put(calculationActionCreator.switchTabIndex(tabIndex));
    yield calculateCostsAndLoadFiles();
}

function* checkForAIOBasicSets() {
    const aioBasicSet = yield select((state: IApplicationState) => state.form.basicInputsForm.values.aioBasicSet);
    const activeTabIndex = yield select(getActiveTabIndex);

    if (aioBasicSet != null && activeTabIndex === 0) {
        yield put(calculationActionCreator.ignoreAIOBasicSets(false));
        yield put(calculationActionCreator.setAIOBasicSetModalVisibility(true));
    }
}

function sanitizeFormValuesForInitialize(formValues: ICalculationFormsState, productData: IProductData) {
    /* eslint-disable no-lone-blocks */

    // To support legacy projects with `manualVentilationArea`,
    // adjust `liftAreaPercentages` to `MANUAL`
    // if manual entry is allowed for this project.
    // @TODO: BLU-279: Remove `manualVentilationArea` so we can get rid of this hack
    {
        if (formValues.basicInputsForm.values.manualVentilationArea) {
            if (getAvailableLiftAreaPercentages(formValues.basicInputsForm.values).includes(LIFT_AREA_PERCENTAGE.MANUAL)) {
                formValues.basicInputsForm.values.liftAreaPercentage = LIFT_AREA_PERCENTAGE.MANUAL;
            }
        }
    }

    // Force liftAreaPercentage to legal value
    {
        const { liftAreaPercentage } = formValues.basicInputsForm.values;
        const percentages = getAvailableLiftAreaPercentages(formValues.basicInputsForm.values);
        if (!liftAreaPercentage || !percentages.includes(liftAreaPercentage as LIFT_AREA_PERCENTAGE)) {
            formValues.basicInputsForm.values.liftAreaPercentage = percentages[0];
        }
    }

    // Revalidate lift area
    formValues.basicInputsForm.values.calculatedVentilationArea = getTotalLiftArea(formValues.basicInputsForm.values);
    formValues.ventilationComponentsForm.values.ventilationArea = getTotalLiftAreaMax(formValues, productData);

    const basicInputFormValues = formValues.basicInputsForm.values;

    // Revalidate smoke detection method selection
    {
        const smokeDetectionMethods = getApplicableSmokeDetectionMethods(basicInputFormValues, formValues.configurationInputsForm.values);
        const defaultSmokeDetectionMethod = smokeDetectionMethods.length ? smokeDetectionMethods[0] : null;

        if (formValues.configurationInputsForm.values.smokeDetection == null) {
            if (!!defaultSmokeDetectionMethod) {
                formValues.configurationInputsForm.values.smokeDetection = defaultSmokeDetectionMethod;
            }
        } else if (!smokeDetectionMethods.find(x => x === formValues.configurationInputsForm.values.smokeDetection)) {
            // The selected smoke detection method is not applicable, replace with default
            if (!!defaultSmokeDetectionMethod) {
                formValues.configurationInputsForm.values.smokeDetection = defaultSmokeDetectionMethod;
            }
        }
    }

    // Revalidate system selection
    {
        if (formValues.configurationInputsForm.values.system !== null) {
            if (!getAvailableSystems(basicInputFormValues).find(x => x === formValues.configurationInputsForm.values.system)) {
                formValues.configurationInputsForm.values.system = null
            }
        }
        if (formValues.configurationInputsForm.values.system == null) {
            formValues.configurationInputsForm.values.system = getAvailableSystems(basicInputFormValues)[0];
        }
    }

    // No montage outside Germany
    {
        if (basicInputFormValues.country !== COUNTRY.DE) {
            formValues.costCalculationForm.values.montageIncluded = false;
            formValues.costCalculationForm.values.montageBuildingLocation = null;
        }
    }

    /* eslint-enable no-lone-blocks */
}

/** @see `CalculationActionTypes.INITIALIZE_FORM` */
function* initializeForm({ formValues, productData }: ReturnType<typeof calculationActionCreator.initializeForm>) {
    console.log("---> INITIALIZE FORM");

    sanitizeFormValuesForInitialize(formValues, productData);

    yield put(initialize(ReduxFormKeys.basicInputsForm, formValues.basicInputsForm.values));
    yield put(initialize(ReduxFormKeys.configurationInputsForm, formValues.configurationInputsForm.values));
    yield put(initialize(ReduxFormKeys.ventilationComponentsForm, formValues.ventilationComponentsForm.values));
    yield put(initialize(ReduxFormKeys.costCalculationForm, formValues.costCalculationForm.values));

    console.log("---> PROCESS FORM: INIT");
    yield put(calculationActionCreator.processFormValues(formValues, productData));

    yield put(change(ReduxFormKeys.ventilationComponentsForm, 'validationTrigger', new Date()));

    yield checkForAIOBasicSets();

    yield calculateCostsAndLoadFiles();
}

function* calculateCostsAndLoadFiles() {
    const activeTabIndex = yield select(getActiveTabIndex);
    const loadedProject = yield select(projectApi.makeSelectBase('entity'));

    if (!loadedProject) {
        return;
    }

    if (activeTabIndex === 2) {
        const formValues: ICalculationFormsState = yield select(getForm);
        const productData: IProductData = yield select(getProductData);

        const basicValues = formValues?.basicInputsForm?.values;
        const configValues = formValues?.configurationInputsForm?.values;
        const ventilationComponentsValues = formValues?.ventilationComponentsForm?.values;

        const minArea = getTotalLiftArea(basicValues);
        const maxArea = ventilationComponentsValues?.ventilationArea || minArea + 1000000;

        const availableVentilationComponents = getAvailableVentilationComponents(minArea, minArea, maxArea, basicValues, configValues, productData);

        // Ventilation components that are not available due to slider min/max
        const ventComponentIdsToRemove = getQuantitiesFromVentilationComponentsFormModel(ventilationComponentsValues).filter(([id, _]) =>
            getProductById(availableVentilationComponents.ventComponents, id) == null &&
            getProductById(availableVentilationComponents.weatherProtectionComponents, id) == null
        );

        yield put(formActionCreator.resetFields(ReduxFormKeys.ventilationComponentsForm, ventComponentIdsToRemove.map(id => `quantity-${id}`)));
        yield put(change(ReduxFormKeys.ventilationComponentsForm, 'validationTrigger', new Date()));
    }
}

/**
 * Change form value with `key` in `form` to `value`.  
 * Dispatches a change action.
 * @param formValues The current form values
 * @param form The form key
 * @param key The form value key
 * @param value The new value
 * @returns `true` if the value has been changed, `false` if the previous value is equal to `value`.
 */
function* tryChange(formValues: ICalculationFormsState, form: keyof ICalculationFormsState, key: string, value: any) {
    if (value !== formValues[form].values[key]) {
        // console.log(`CHANGE NOW ${form}.${key}`, value);
        yield put(change(form, key, value));
        return true;
    }
    // console.log(`CHANGE NOT NOW ${form}.${key}`, value);
    return false;
}

/**
 * Reset the product quantity form values in `ventilationComponentsForm`.  
 * Dispatches `resetFields` and `change` actions.
 */
function* _resetVentilationComponentQuantities(formValues: ICalculationFormsState) {
    // Get applicable ventilation and weather components
    const formValueKeys = getQuantitiesFromVentilationComponentsFormModel(formValues.ventilationComponentsForm?.values).map(([id, _]) => `quantity-${id}`);
    // Reset the quantity fields of all applicable ventilation components
    yield put(formActionCreator.resetFields(ReduxFormKeys.ventilationComponentsForm, formValueKeys));

    // Trigger re-validation
    yield put(change(ReduxFormKeys.ventilationComponentsForm, 'validationTrigger', new Date()));
};

/** @see `CalculationActionTypes.REDUX_FORM_CHANGE` @see `FormActionTypes.RESET_FIELDS` */
function* changeForm(action: ReduxFormChangeAction) {
    // console.log("---> CHANGE FORM");

    let formValues: ICalculationFormsState = yield select(getForm);

    const basicInputFormValues = formValues.basicInputsForm.values;
    const configurationInputsFormValues = formValues.configurationInputsForm.values;
    const costCalculationFormValues = formValues.costCalculationForm.values;

    const productData: IProductData = yield select(getProductData);

    // Field updates that may change the available lift area percentages
    if (['country', 'projectType'].includes(action.meta?.field)) {
        const projectType = action.meta.field === 'projectType' ? action.payload : basicInputFormValues.projectType;
        const country = action.meta.field === 'country' ? action.payload : basicInputFormValues.country;

        const { liftAreaPercentage } = basicInputFormValues;

        const percentages = getAvailableLiftAreaPercentages({ projectType, country });
        if (!liftAreaPercentage || !percentages.includes(liftAreaPercentage as LIFT_AREA_PERCENTAGE)) {
            yield put(change(ReduxFormKeys.basicInputsForm, 'liftAreaPercentage', percentages[0]));
            return;
        }
    }

    // Recalculate ventilation area; short-circuit on a change
    if (yield tryChange(formValues, ReduxFormKeys.basicInputsForm, 'calculatedVentilationArea', getTotalLiftArea(basicInputFormValues))) return;
    if (yield tryChange(formValues, ReduxFormKeys.ventilationComponentsForm, 'ventilationArea', getTotalLiftAreaMax(formValues, productData))) return;

    // No montage outside Germany
    if (action.meta?.field === 'country' && action.payload !== COUNTRY.DE) {
        yield put(change(ReduxFormKeys.costCalculationForm, 'montageIncluded', false));
        yield put(change(ReduxFormKeys.costCalculationForm, 'montageBuildingLocation', null));
    }

    // Field updates that may change the available systems
    if (['abzRequired', 'nrwgDelivered', 'lowEnergyStandard', 'quiet', 'noOfElevators', 'additionalSmokeDetector'].includes(action.meta?.field)) {
        const previousSystem = configurationInputsFormValues.system;

        const systems = getAvailableSystems(basicInputFormValues);
        const defaultSystem = systems.length ? systems[0] : null;

        if (!previousSystem || !systems.includes(previousSystem)) {
            // If a system wasn't specified, or is no longer applicable, replace with default system
            if (yield tryChange(formValues, ReduxFormKeys.configurationInputsForm, 'system', defaultSystem)) return;
        }
    }

    // Determine applicable smoke detection methods
    {
        const smokeDetectionMethods = getApplicableSmokeDetectionMethods(basicInputFormValues, configurationInputsFormValues);
        const defaultSmokeDetectionMethod = smokeDetectionMethods.length ? smokeDetectionMethods[0] : null;

        // If only one smoke detection method is applicable, select it
        if (smokeDetectionMethods.length === 1) {
            if (yield tryChange(formValues, ReduxFormKeys.configurationInputsForm, 'smokeDetection', defaultSmokeDetectionMethod)) return;
        }
        // If the selected smoke detection method is not applicable, select the default
        if (!smokeDetectionMethods.find(x => x === configurationInputsFormValues.smokeDetection)) {
            if (yield tryChange(formValues, ReduxFormKeys.configurationInputsForm, 'smokeDetection', defaultSmokeDetectionMethod)) return;
        }

        // Field updates that may change the available smoke detection methods
        if (['shaftHeight', 'payload', 'country', 'liftAreaPercentage', 'systemIntegration', 'machineRoom', 'system'].includes(action.meta?.field)) {
            const prevSmokeDetectionMethod = configurationInputsFormValues.smokeDetection;

            if (!prevSmokeDetectionMethod || !smokeDetectionMethods.includes(prevSmokeDetectionMethod)) {
                // If a smoke detection method wasn't specified, or is no longer applicable, replace with default method
                if (yield tryChange(formValues, ReduxFormKeys.configurationInputsForm, 'smokeDetection', defaultSmokeDetectionMethod)) return;
            }
        }
    }

    // Field updates that may change the available lift status detection methods
    if (['shaftHeight', 'machineRoom', 'system'].includes(action.meta?.field)) {
        const prevLiftStatusDetectionMethod = configurationInputsFormValues.liftStatusDetection;

        // Determine applicable lift status detection methods
        const liftStatusDetectionMethods = getApplicatableLiftStatusDetectionMethods(basicInputFormValues, configurationInputsFormValues);
        const defaultLiftStatusDetectionMethod = liftStatusDetectionMethods.length ? liftStatusDetectionMethods[0] : null;

        if (!prevLiftStatusDetectionMethod || !liftStatusDetectionMethods.includes(prevLiftStatusDetectionMethod)) {
            if (yield tryChange(formValues, ReduxFormKeys.configurationInputsForm, 'liftStatusDetection', defaultLiftStatusDetectionMethod)) return;
        }
    }

    // On montage enable, copy building location into montage location as a default
    if (action.meta?.field === 'montageIncluded' && action.payload && !costCalculationFormValues.montageBuildingLocation) {
        yield put(change(ReduxFormKeys.costCalculationForm, 'montageBuildingLocation', basicInputFormValues.buildingLocation));
    }

    // Field updates that cause a reset on the `ventilationComponentsForm`
    if (action.meta?.field === 'aioBasicSet') {
        yield _resetVentilationComponentQuantities(formValues);

        // If a set is selected, attempt to update `smokeDetection` to the set's detection method
        if (action.payload) {
            const aioBasicSet = getProductById(productData.products, basicInputFormValues.aioBasicSet);
            if (!aioBasicSet) {
                console.debug(`Encountered missing product with id ${basicInputFormValues.aioBasicSet}`);
            } else {
                const smokeDetectionMethods = getApplicableSmokeDetectionMethods(basicInputFormValues, configurationInputsFormValues);

                if (aioBasicSet.productKey.includes('PD')) {
                    if (yield tryChange(formValues, ReduxFormKeys.configurationInputsForm, 'smokeDetection', smokeDetectionMethods.find(x => x === SMOKE_DETECTION.POINTDETECTOR))) return;
                }
                if (aioBasicSet.productKey.includes('SD-L-F1')) {
                    if (yield tryChange(formValues, ReduxFormKeys.configurationInputsForm, 'smokeDetection', smokeDetectionMethods.find(x => x === SMOKE_DETECTION.SDLF1))) return;
                }
            }
        }
    }
    if (['installationPosition', 'nrwgDelivered', 'projectType', 'system', 'weathershelter', 'quiet', 'lowEnergyStandard', 'abzRequired'].includes(action.meta?.field)) {
        yield _resetVentilationComponentQuantities(formValues);
    }

    console.log(`---> PROCESS FORM: CHANGE ${action.meta?.field}`);
    formValues = yield select(getForm);
    yield put(calculationActionCreator.processFormValues(formValues, productData));
}

/** @see `CalculationActionTypes.IGNORE_AIO_BASIC_SETS` */
function* ignoreAIOBasicSets(action: ReturnType<typeof calculationActionCreator.ignoreAIOBasicSets>) {
    console.log("---> IGNORE AIO BASIC SETS");

    const formValues: ICalculationFormsState = yield select(getForm);

    // Delete selected AIO Basic Set when ignoring Sets (e.g. by closing modal)
    if (action.payload === true) {
        if (yield tryChange(formValues, ReduxFormKeys.basicInputsForm, 'aioBasicSet', null)) return;
    }

    console.log("---> PROCESS FORM: IGN_SET");
    const productData: IProductData = yield select(getProductData);
    yield put(calculationActionCreator.processFormValues(formValues, productData));
}


function* saga(dispatch: any) {
    yield takeEvery(CalculationActionTypes.REQUEST_SWITCH_TAB, requestSwitchTab);
    yield takeEvery(CalculationActionTypes.REQUEST_SWITCH_TAB_INDEX, requestSwitchTabIndex);

    yield takeEvery(CalculationActionTypes.INITIALIZE_FORM, initializeForm);
    yield takeEvery(CalculationActionTypes.REDUX_FORM_CHANGE, changeForm);
    // yield takeEvery(CalculationActionTypes.REDUX_FORM_BLUR, blurForm); // unused
    yield takeEvery(FormActionTypes.RESET_FIELDS, changeForm);

    yield takeEvery(CalculationActionTypes.IGNORE_AIO_BASIC_SETS, ignoreAIOBasicSets);
}

export default saga;
