import * as React from 'react';


export function useFetchOnce<T>(getter: () => Promise<T>) {
    const [[value, error], setValueAndError] = React.useState<[T|null, Error|null]>([null, null]);
    const fetchPromise = React.useRef<Promise<void>|null>(null);

    const loading = !!fetchPromise.current;

    const fetch = React.useCallback((replaceInflight: boolean = false) => {
        let p = fetchPromise.current;

        if (!p || replaceInflight) {
            fetchPromise.current = p = getter().then(v => {
                if (fetchPromise.current !== p) {
                    // This fetch has been replaced
                    return;
                }

                fetchPromise.current = null;
                setValueAndError([v, null]);
            }).catch(e => {
                if (fetchPromise.current !== p) {
                    // This fetch has been replaced
                    return;
                }

                fetchPromise.current = null;
                setValueAndError([null, e]);
            });
        }

        return p;
    }, [fetchPromise, setValueAndError, getter]);

    const reset = React.useCallback(() => {
        // an ongoing fetch will discard its result
        fetchPromise.current = null;
        setValueAndError([null, null]);
    }, [fetchPromise, setValueAndError]);

    return {
        value,
        error,
        loading,
        fetch,
        reset,
    };
}
