import {
    INonStandardComponent,
    ISelectedProduct,
    IProjectProduct,
} from '../calculation/CalculationModels';
import { IUser } from '../auth/IUser';


/** @see `DH.BlueKitConnect.Backend.Features.File.FileDto` */
export interface IFile {
    id: string;
    name: string;
    path: string;
}

/** @see `DH.BlueKitConnect.Backend.Features.Project.ProjectDto` */
export interface IProject {
    id: string;
    name: string;
    state: PROJECT_STATE;

    products: IProjectProduct[];
    formValues: IFormValue[];
    files: IFile[];

    createdAt: string; // datetime
    createdById: string; // uuid
    createdBy: IUser;

    lastReportingAt: string | null; // datetime

    owningCompanyId: number | null;
    owningCompany?: ICompany;
}

/** @see `DH.BlueKitConnect.Backend.Features.Project.EnrichedProjectDto` */
export interface IEnrichedProject extends IProject {
    owningCompany: ICompany;
}

export interface IProjectForReporting {
    id: string;

    products: IProjectProduct[];
    formValues: IFormValue[];

    selectedProducts:  ISelectedProduct[];
    selectedNonStandardComponents: INonStandardComponent[];
    selectedAIOBasicSet: ISelectedProduct;
}

/** @see `DH.BlueKitConnect.Backend.Features.Project.FormValueDto` */
export interface IFormValue {
    key: string;
    value: any;
}

export enum PROJECT_STATE {
    NEW = 'NEW',
    DRAFT = 'DRAFT',
    SUBMITTED = 'SUBMITTED',
    COMPLETED = 'COMPLETED',
    ORDER_RECEIVED = 'ORDER_RECEIVED',
    OFFER_CREATED = 'OFFER_CREATED',
    MESSAGE_FROM_DH = 'MESSAGE_FROM_DH',
}

/** @see `DH.BlueKitConnect.Graph.CompanyDto` */
export interface ICompany {
    id: number;
    name: string;
}
