import * as React from 'react';
import {Button, Input, Label, Table, TableProps} from 'semantic-ui-react';

import { useLocalization } from '../../../../localization/hook';

import {isAdmin} from '../../../auth/PermissionService';
import { useUser } from '../../../auth/User.hook';

import { INonStandardComponent } from '../../CalculationModels';
import { FormatPrice } from '../04-CostCalculation/_CostCalculationList';


export type NonStandardComponentsTableProps = TableProps & {
    items: INonStandardComponent[];
    onAddItem: (description: string, price: number, quantity: number) => unknown;
    onRemoveItem: (productId: INonStandardComponent['productId']) => unknown;
};
export const NonStandardComponentsTable: React.FC<NonStandardComponentsTableProps> = ({
    items,
    onAddItem,
    onRemoveItem,
    className,
    ...tableProps
}) => {
    const { localize } = useLocalization();
    const { user } = useUser();

    const userCanEdit = isAdmin(user);

    const descriptionInputRef = React.useRef<HTMLInputElement>();
    const priceInputRef = React.useRef<HTMLInputElement>();
    const quantityInputRef = React.useRef<HTMLInputElement>();

    const onCreateButtonClick = React.useCallback(() => {
        const description = descriptionInputRef.current?.value.trim();
        const price = parsePrice(priceInputRef.current?.value);
        const quantity = parseQuantity(quantityInputRef.current?.value);

        if (description == null || price == null || quantity == null) {
            return;
        }

        if (description.length === 0 || quantity < 1) {
            return;
        }

        onAddItem(description, price, quantity);

        descriptionInputRef.current.value = '';
        priceInputRef.current.value = '';
        quantityInputRef.current.value = '1';
    }, [descriptionInputRef, priceInputRef, quantityInputRef, onAddItem]);

    const itemRows = React.useMemo(() => items.map(item => (
        <Table.Row key={`item-${item.productId}`} className="row-item">
            <Table.Cell>{item.description}</Table.Cell>
            <Table.Cell textAlign="right"><FormatPrice pos={{ price: item.price }}/></Table.Cell>
            <Table.Cell textAlign="right">{item.quantity}</Table.Cell>
            <Table.Cell textAlign="right" className="col-actions">
                {userCanEdit && (
                    <Button size='small' icon='delete' onClick={() => onRemoveItem(item.productId)}/>
                )}
            </Table.Cell>
        </Table.Row>
    )), [userCanEdit, items]);

    return (
        <Table {...{ ...tableProps, className: (!!className ? `${className} ` : '') + 'NonStandardComponentsTable' }}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        {localize('nonStandardComponents.table.column.description')}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right" width={3}>
                        {localize('nonStandardComponents.table.column.price')}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right" width={3}>
                        {localize('nonStandardComponents.table.column.quantity')}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right" width={2}>
                        {userCanEdit && localize('nonStandardComponents.table.column.action')}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {itemRows}
                {userCanEdit && (
                    <Table.Row key='create' className='row-create'>
                        <Table.Cell>
                            <Input fluid>
                                <input type='text' ref={descriptionInputRef}/>
                            </Input>
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                            <Input labelPosition="right">
                                <input ref={priceInputRef} style={{ textAlign: 'right' }}/>
                                <Label>€</Label>
                            </Input>
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                            <Input>
                                <input ref={quantityInputRef} style={{ textAlign: 'right' }} defaultValue="1"/>
                            </Input>
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                            <Button size='small' icon='plus' onClick={onCreateButtonClick}/>
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );
};

function parsePrice(str: string|null): number|null {
    str = str?.trim();
    if (!str || !str.length) return null;
    if (!str.match(/^[0-9]+([,.][0-9]{2})?$/)) return null;
    str = str.replace(',', '.');
    return parseFloat(str);
}

function parseQuantity(str: string|null): number|null {
    str = str?.trim();
    if (!str || !str.length) return null;
    if (!str.match(/^[0-9]+$/)) return null;
    const result = parseInt(str);
    return result >= 0 ? result : null;
}
