import { ICompany, IEnrichedProject, IProject } from './ProjectModels';

import { JsonRESTApiClient } from '../api/JsonRESTApiClient';
import { isArrayOf, isObjectWithProperties } from '../api/simple-validators';


/** @see `DH.BlueKitConnect.Backend.Features.Project.GetProjectsView` */
export enum GetProjectsView {
    ALL = 'all',
    OWN = 'own',
    OWN_COMPANY = 'own_company',
}

export type IProjectSynopsis = Pick<IProject, 'id'|'name'|'state'|'createdAt'|'createdBy'|'owningCompany'>;

export type GetProjectsResponse = [IProjectSynopsis[], boolean];

/** @see `DH.BlueKitConnect.Backend.Features.Project.ProjectApiV1Controller` */
export class ProjectApiV1Client extends JsonRESTApiClient {

    /// API Endpoints

    public async saveProject(project: IProject, sendMail: boolean = false): Promise<IEnrichedProject> {
        return await this._fetchJson(this._getUrl(), {
            method: 'POST',
            headers: await this._getHeaders(),
            body: JSON.stringify({
                ...project,
                sendMail,
            }),
        }, ProjectApiV1Client.ValidateIEnrichedProject);
    }

    /**
     * @note As of 2024-02-01, the backend still actually returns IEnrichedProject[],
     *       but I want to narrow down the listing API's response interface to the essential project properties,
     *       decoupling it from the shape of the individual IProject or IEnrichedProject.
     */
    public async getProjects(
        view: GetProjectsView,
        query: string|null = null,
        first: number = 0,
        count: number|null = null
    ): Promise<GetProjectsResponse> {
        const queryParams: [string, string][] = [];

        queryParams.push(['view', String(view)]);

        query = query?.trim();
        if (typeof query === 'string' && query.length) {
            queryParams.push(['query', query]);
        }

        queryParams.push(['first', String(first)]);

        if (typeof count === 'number') {
            queryParams.push(['count', String(count)]);
        }

        return await this._fetchJson(this._getUrl([], queryParams), {
            method: 'GET',
            headers: await this._getHeaders(),
        }, ProjectApiV1Client.ValidateGetProjectsResponse);
    }

    public async deleteProjectById(id: IProject['id']): Promise<void> {
        await this._fetchJson(this._getUrl([id]), {
            method: 'DELETE',
            headers: await this._getHeaders(),
        });
    }

    public async getProjectById(id: IProject['id']): Promise<IEnrichedProject> {
        return await this._fetchJson(this._getUrl([id]), {
            method: 'GET',
            headers: await this._getHeaders(),
        }, ProjectApiV1Client.ValidateIEnrichedProject);
    }

    /// Validators for API types

    private static ValidateGetProjectsResponse(v: unknown): v is GetProjectsResponse {
        return Array.isArray(v) && v.length === 2
            && isArrayOf(ProjectApiV1Client.ValidateIProjectSynopsis)(v[0])
            && typeof v[1] === 'boolean';
    }

    private static ValidateIProjectSynopsis(v: unknown): v is IProjectSynopsis {
        return isObjectWithProperties('id', 'name', 'state', 'createdAt', 'createdBy', 'owningCompany')(v);
    }

    public static ValidateIProject(v: unknown): v is IProject {
        return isObjectWithProperties('id', 'state', 'createdAt', 'createdById', 'owningCompanyId', 'lastReportingAt')(v);
    }

    private static ValidateIEnrichedProject(v: unknown): v is IEnrichedProject {
        return ProjectApiV1Client.ValidateIProject(v)
            && isObjectWithProperties('owningCompany')(v)
            && ProjectApiV1Client.ValidateICompany(v.owningCompany);
    }

    private static ValidateICompany(v: unknown): v is ICompany {
        return isObjectWithProperties('id', 'name')(v);
    }

}
