import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import { getFormValues } from 'redux-form';
import { Button } from 'semantic-ui-react';
import moment from 'moment';

import { LocalizationProps, RestrictLanguages, useLocalization, useProductLanguage } from '../../localization/hook';
import { LanguageKey } from '../../localization/LocalizationService';
import { RestrictedLanguageMessage } from '../../localization/RestrictedLanguageMessage';
import { RestrictedCurrencyMessage } from '../../localization/RestrictedCurrencyMessage';
import { Spinner } from '../../shared/components/Spinner';
import { IApplicationState } from "../../shared/ApplicationState";

import { ReduxFormKeys } from '../form/ReduxFormKeys';
import { isAdmin } from '../auth/PermissionService';
import { useUser } from '../auth/User.hook';
import { IUser } from '../auth/IUser';
import { projectApi, projectApiActionCreator } from './ProjectApi.redux';

import { calculationTabs, isCalculationTab } from "./constant";
import { CalculationTabs } from './components/CalculationTabs';
import { calculationActionCreator } from "./CalculationActions";
import { Direction, Tabs } from './CalculationReducer';
import * as select from "./calculation.selector";
import { COST_CALCULATION_FORM_VALUES_SELECTOR } from './components/02-ConfigurationInputs/ConfigurationInputsForm';
import DebugTable from './components/DebugTable';

import "./CalculationPage.scss";
import { useFreshworksWidgetPrefillProjectId } from '../freshworks-widget/useFreshworksWidget.hook';


const aioBasicSetCutoffTime = moment("2024-04-17 13:00:00+02:00");

class InnerCalculationPage extends React.Component<InnerProps, any> {

    componentDidMount(): void {
        const props = this.props;
        const { projectId, calculationTab, user } = this.props; 

        // Populate project data (existing or fresh)
        if (projectId !== 'new') {
            // Load existing project, maybe as a template
            const asTemplate = calculationTab === 'as-template';
            props.getProject(projectId, asTemplate);

        } else {
            props.makeProject(user.company.id);
        }

        // Switch to the right tab
        const switchToIndex = isCalculationTab(calculationTab) ? calculationTabs.indexOf(calculationTab) : 0;
        this.props.switchTabIndex(switchToIndex);

    }

    componentDidUpdate(prevProps: InnerProps) {
        const prevProject = prevProps.project;
        const currProject = this.props.project;

        const prevTabIndex = prevProps.activeTabIndex;
        const currTabIndex = this.props.activeTabIndex;

        const prevRouteForm = prevProps.calculationTab;
        const currRouteForm = this.props.calculationTab;

        const prevRouteProject = prevProps.projectId;
        const currRouteProject = this.props.projectId || 'new';

        if (prevTabIndex !== currTabIndex) {
            // form tab changed, push history entry
            const newRouteProject = currProject ? currProject.id : currRouteProject;
            const newRouteForm = calculationTabs[currTabIndex];
            if (newRouteForm !== currRouteForm) {
                this.props.history.push('/calculation/' + currRouteProject + '/' + newRouteForm);
                console.log("PUSH -->", '/calculation/' + currRouteProject + '/' + newRouteForm, "newRouteProject", newRouteProject);
            }
        }

        if (prevRouteForm !== currRouteForm) {
            // URL route form parameter changed
            console.log("FORM -->", prevRouteForm, ' --> ', currRouteForm);
            // Only switch tab when old form was a valid tab. Do not switch when old form was 'as-template'.
            if (isCalculationTab(prevRouteForm)) {
                this.props.requestSwitchTabIndex(calculationTabs.indexOf(currRouteForm as calculationTabs));
            }
        }
    }

    /** When either button is clicked, navigate towards the requested direction */
    onNavigate(event: React.MouseEvent<HTMLButtonElement>, direction: string) {
        event.preventDefault();


        const isAdvancingFromBasicInput = this.props.activeTabIndex === Tabs.BasicInput && direction === Direction.Next;
        let showSetsModal = isAdvancingFromBasicInput && this.props.aioBasicSets?.length > 0;

        if (
            !this.props.project?.createdAt?.length
            || aioBasicSetCutoffTime.isSameOrBefore(this.props.project?.createdAt)
            || !this.props.calculationState?.selectedAIOBasicSet
        ) {
            // Disable AIO Basic Set dialog for new projects
            showSetsModal = false;
        }

        if (showSetsModal) {
            this.props.setAIOBasicSetModalVisibility(true);
        } else {
            this.props.requestSwitchTab(direction);
        }
    }

    public render() {
        const {
            user,
            calculationState,
            activeTabIndex,
            selectedProducts,
            selectedAIOBasicSet,
            project,
            projectIsUpdating,
            productData,
            canNavigateToConfigurationForm,
            canNavigateToVentilationForm,
            canNavigateToCostCalculationForm,
            requestSwitchTabIndex,
            setAIOBasicSetModalVisibility,
            localize,
            productLanguage,
        } = this.props;

        const calcTabsProps = {
            activeTabIndex,
            selectedAIOBasicSet,
            canNavigateToConfigurationForm,
            canNavigateToVentilationForm,
            canNavigateToCostCalculationForm,
            requestSwitchTabIndex,
            setAIOBasicSetModalVisibility,
        }

        if (!calculationState || !productData) {
            return <Spinner/>;
        }

        let canNext = false;
        switch (activeTabIndex + 1) {
            case Tabs.Configuration:
                canNext = canNavigateToConfigurationForm;
                break;
            case Tabs.VentilationComponents:
                canNext = canNavigateToVentilationForm;
                break;
            case Tabs.CostCalculation:
                canNext = canNavigateToCostCalculationForm;
                break;
        }

        const showNextButton = activeTabIndex < 3;
        const showPrevButton = activeTabIndex > 0;

        return (
            <div className="ui CalculationPage">
                <RestrictedLanguageMessage className='full-width' />
                <RestrictedCurrencyMessage className='full-width' />

                <div>
                    <CalculationTabs {...calcTabsProps} />
                </div>
                <div className="btn-group">
                    {showNextButton && (
                        <Button disabled={!canNext}
                                loading={projectIsUpdating}
                                primary
                                onClick={e => this.onNavigate(e, Direction.Next)}
                        >
                            {localize('page.button.next')}
                        </Button>
                    )}
                    {showPrevButton && (
                        <Button secondary onClick={e => this.onNavigate(e, Direction.Previous)}>
                            {localize('page.button.prev')}
                        </Button>
                    )}
                </div>

                <div style={{height: '50px'}}></div>

                {isAdmin(user) && project && (
                    <DebugTable title={localize('debugTable.user.title')} header={false} columns={[
                        { key: "left" },
                        { key: "right" },
                    ]} rows={[
                        { key: "name",  cols: [ localize('debugTable.user.label.name'),  project.createdBy.firstName + ' ' + project.createdBy.lastName ] },
                        { key: "comp",  cols: [ localize('debugTable.user.label.company'), project.owningCompany?.name ?? '-' ] },
                        { key: "email", cols: [ localize('debugTable.user.label.email'), project.createdBy.email ] },
                    ]} />
                )}

                {isAdmin(user) && !!selectedAIOBasicSet && (
                    <DebugTable title={localize('debugTable.products.title')}  columns={[
                        { key: "description" },
                        { key: "dhNo"        },
                        { key: "quantity"    },
                    ].map(c => ({ ...c, header: localize('debugTable.products.column.' + c.key) }))}
                    rows={[{ key: selectedAIOBasicSet.id, cols: [
                        selectedAIOBasicSet.description[productLanguage],
                        selectedAIOBasicSet.dhNo,
                        String(selectedAIOBasicSet.quantity),
                    ]}]} />
                )}

                {isAdmin(user) && selectedProducts && selectedProducts.length > 0 && (
                    <DebugTable title={localize('debugTable.products.title')} columns={[
                        { key: "description" },
                        { key: "dhNo"        },
                        { key: "quantity"    },
                    ].map(c => ({ ...c, header: localize('debugTable.products.column.' + c.key) }))}
                    rows={selectedProducts.filter(p => p.quantity > 0).map(p => ({ key: p.id, cols: [
                        p.description[productLanguage],
                        p.dhNo,
                        String(p.quantity),
                    ]}))} />
                )}
            </div>
        );
    }
}

const LocalizedCalculationPage: React.FC<Omit<InnerProps, keyof LocalizationProps | 'productLanguage'>> = (props) => {
    const localizationProps = useLocalization();
    const productLanguage = useProductLanguage();

    // @TODO: Move to InnerCalculationPage once it's a functional component
    useFreshworksWidgetPrefillProjectId(props.projectId);

    return <InnerCalculationPage {...props} {...localizationProps} productLanguage={productLanguage} />;
};

const ProtectedCalculationPage: React.FC<Omit<InnerProps, keyof LocalizationProps | 'productLanguage' | 'user' | 'history'>> = (props) => {
    const { user } = useUser();
    const history = useHistory();

    return (
        <RestrictLanguages languages={['de', 'en', 'pl']}>
            <LocalizedCalculationPage {...props} user={user} history={history} />
        </RestrictLanguages>
    );
};

const {
    canNavigateToConfigurationForm,
    canNavigateToVentilationForm,
    canNavigateToCostCalculationForm,
} = select;

const mapStateToProps = createStructuredSelector({
    canNavigateToConfigurationForm,
    canNavigateToVentilationForm,
    canNavigateToCostCalculationForm,

    project: projectApi.makeSelectBase('entity'),
    projectIsUpdating: projectApi.makeSelectBase('isUpdating'),
    productData: select.getProductData,

    form: select.getForm,
    calculationState: (state: IApplicationState) => state.calculationState,
    activeTabIndex: select.getActiveTabIndex,
    aioBasicSets:  (state: IApplicationState) => state.calculationState?.aioBasicSets,
    selectedProducts: (state: IApplicationState) => state.calculationState?.selectedProducts,
    selectedAIOBasicSet: (state: IApplicationState) => state.calculationState?.selectedAIOBasicSet,

    costCalculationFormValues: getFormValues(ReduxFormKeys.costCalculationForm) as COST_CALCULATION_FORM_VALUES_SELECTOR,
});

const mapDispatchToProps = {
    setAIOBasicSetModalVisibility: calculationActionCreator.setAIOBasicSetModalVisibility,
    switchTabIndex: calculationActionCreator.switchTabIndex,
    requestSwitchTab: calculationActionCreator.requestSwitchTab,
    requestSwitchTabIndex: calculationActionCreator.requestSwitchTabIndex,

    getProject: projectApiActionCreator.getRequest,
    makeProject: projectApiActionCreator.makeProject,
} as const;

export const CalculationPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProtectedCalculationPage);

type ReduxProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
type OwnProps = {
    projectId: string | 'new';
    calculationTab: calculationTabs | 'as-template';
};
type InnerProps = ReduxProps & LocalizationProps & { productLanguage: LanguageKey, history: History, user: IUser, } & OwnProps;
