import React from "react";
import {Button, Popup, Table} from "semantic-ui-react";

import {nl2br} from '../../../../localization/LocalizationService';
import { useLocalization, useProductLanguage } from "../../../../localization/hook";
import {ISettings} from "../../../settings/SettingsModels";

import {ICostPosition, ICosts, ISelectedProduct} from "../../CalculationModels";
import {TableRowAccordion} from "./_TableRowAccordion";


function aggregateCostPositions(positions: (ICostPosition|null)[]): ICostPosition {
    const nonZeroPositions = positions.filter(_ => _ != null);
    return {
        price: nonZeroPositions.map(_ => _.price || 0).reduce((_, p) => _ + p, 0),
        incompletePrice: nonZeroPositions.map(_ => _.incompletePrice || _.price == null).includes(true),
        products: nonZeroPositions.map(_ => _.products).reduce((_, e) => _.concat(e), [] as ISelectedProduct[]),
        montage: nonZeroPositions.map(_ => _.montage || 0).reduce((_, m) => _ + m, 0),
    };
}

export interface FormatPriceProps {
    pos: Pick<ICostPosition, 'price'>&Partial<Pick<ICostPosition, 'incompletePrice'>>|null
};

export const FormatPrice: React.FC<FormatPriceProps> = ({ pos }) => {
    const { localize } = useLocalization();

    if (!pos) return <></>;
    if (pos.price === null || pos.price === undefined) return <>{localize('info.onRequest')}</>;

    const incompletePrice = 'incompletePrice' in pos && !!pos.incompletePrice;

    return <>
        {incompletePrice && <>
            <Popup content={localize('info.incompletePrice')}
                trigger={<Button className="info-button info-button-small" icon="exclamation triangle"/>}/>
            {' '}
        </>}
        {pos.price.toFixed(2)} €
    </>;
}

export interface FormatMontageProps {
    pos: Pick<ICostPosition, 'montage'>|null
};
export const FormatMontage: React.FC<FormatMontageProps> = ({ pos }) => {
    if (!pos) return <></>;
    if (pos.montage === null || pos.montage === undefined) return <></>;

    return <>{pos.montage.toFixed(2)} h</>;
}

export type ICCostCalculationListProps = {
    calculateCostsList: ICosts;
    specialRemarks?: string[];
    settings: ISettings
};

export const CostCalculationList: React.FC<ICCostCalculationListProps> = props => {
    const {
        calculateCostsList,
        specialRemarks,
        settings
    } = props;

    const { localize, substitute } = useLocalization();
    const productLanguage = useProductLanguage();

    const costs = calculateCostsList;
    const selectedAIOBasicSet: any = costs.aioBasicSet != null;

    const totalCablingCosts = aggregateCostPositions([
        costs.cabling4m,
        costs.cabling6m,
        costs.cabling12m,
        costs.cablingComponents,
    ]);

    const totalLogisticCosts = {
        ...costs.logisticCosts,
    }

    const totalComponentsCosts = aggregateCostPositions([
        costs.blueKitSystemControlComponents,
        costs.smokeDetectionComponents,
        costs.nonStandardComponents,
        costs.ventilationComponents,
        costs.assemblyComponents,
        costs.aioBasicSet,
        totalCablingCosts,
    ]);

    const totalCostsPositions = [
        totalComponentsCosts,
        totalLogisticCosts,
    ];
    if (costs.isMontagePossible && costs.isMontageCalculated) {
        totalCostsPositions.push(costs.montageCosts.costs, costs.montageLocationCosts.costs);
    }
    const totalCosts = aggregateCostPositions(totalCostsPositions);

    function getRowClassName(counter: number) {
        return counter % 2 === 0 ? 'row-odd' : 'row-even';
    };

    let counter = 0;

    return <>
        <Table celled className="cost-table">
            <Table.Header>
                <Table.Row className="boldRow midBlueRow">
                    <Table.HeaderCell colSpan="3">{localize('costCalculation.table.column.category')}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right" className="price-col" collapsing>
                    {localize('costCalculation.table.column.price')}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right" collapsing>
                        {localize('costCalculation.table.column.montageTime')}
                        <Popup content={localize('info.montageTimeEstimate')} trigger={
                            <Button className="info-button info-button-small" icon="info"/>
                        }/>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right" collapsing>
                        {localize('costCalculation.table.column.info')}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    <>
                        {
                            selectedAIOBasicSet &&
                            <TableRowAccordion
                                visible={false}
                                title={costs.aioBasicSet.products[0].description[productLanguage]}
                                costs={costs.aioBasicSet}
                                montage={costs.aioBasicSet?.montage}
                            />
                        }
                        {
                            !selectedAIOBasicSet &&
                            <TableRowAccordion
                                visible={false}
                                className={getRowClassName(counter++)}
                                title={localize('costCalculation.category.blueKitSystemControlComponents')}
                                costs={costs.blueKitSystemControlComponents}
                                montage={costs.blueKitSystemControlComponents.montage}
                            />
                        }
                        {
                            !selectedAIOBasicSet &&
                            <TableRowAccordion
                                visible={false}
                                className={getRowClassName(counter++)}
                                title={localize('costCalculation.category.smokeDetectionComponents')}
                                costs={costs.smokeDetectionComponents}
                                montage={costs.smokeDetectionComponents.montage}
                            />
                        }
                        {
                            costs.ventilationComponents.products.length > 0 &&
                            <TableRowAccordion
                                visible={false}
                                className={getRowClassName(counter++)}
                                title={localize('costCalculation.category.ventilationComponents')}
                                costs={costs.ventilationComponents}
                                montage={costs.ventilationComponents.montage}
                            />
                        }
                        {
                            costs.nonStandardComponents.products.length > 0 && !selectedAIOBasicSet &&
                            <TableRowAccordion
                                visible={false}
                                className={getRowClassName(counter++)}
                                title={localize('costCalculation.category.nonStandardComponents')}
                                costs={costs.nonStandardComponents}
                                montage={costs.nonStandardComponents.montage}
                            />
                        }
                        <TableRowAccordion
                            visible={false}
                            className={getRowClassName(counter++)}
                            title={localize('costCalculation.category.cablingComponents')}
                            costs={totalCablingCosts}
                            montage={totalCablingCosts.montage}
                            cabling
                        />
                        {
                            !selectedAIOBasicSet &&
                            <TableRowAccordion
                                visible={false}
                                className={getRowClassName(counter++)}
                                title={localize('costCalculation.category.assemblyComponents')}
                                costs={costs.assemblyComponents}
                                montage={costs.assemblyComponents.montage}
                            />
                        }
                    </>
                }
            </Table.Body>

            <Table.Footer>
                <Table.Row className="darkBlueRow boldRow">
                    <Table.Cell colSpan="3">{localize('costCalculation.category.sumOfComponents')}</Table.Cell>
                    <Table.Cell textAlign="right" verticalAlign="top">
                        <FormatPrice pos={totalComponentsCosts} />
                    </Table.Cell>
                    <Table.Cell textAlign="right" verticalAlign="top">
                        <FormatMontage pos={totalComponentsCosts} />
                    </Table.Cell>
                    <Table.Cell/>
                </Table.Row>

                <TableRowAccordion
                    visible={false}
                    title={localize('costCalculation.category.logisticCosts')}
                    costs={totalLogisticCosts}
                />
                {
                    costs.isMontagePossible && costs.isMontageCalculated &&
                    <TableRowAccordion
                        visible={false}
                        title={substitute(costs.montageCosts.costs.title, {})}
                        costs={costs.montageCosts.costs}
                    />
                }
                {
                    costs.isMontagePossible && costs.isMontageCalculated &&
                    <TableRowAccordion
                        visible={false}
                        title={substitute(costs.montageLocationCosts.costs.title, {})}
                        costs={costs.montageLocationCosts.costs}
                    />
                }

                <Table.Row className="midBlueRow boldRow">
                    <Table.Cell colSpan="3">{localize('costCalculation.category.total')}</Table.Cell>
                    <Table.Cell textAlign="right" verticalAlign="top">
                        <FormatPrice pos={totalCosts} />
                    </Table.Cell>
                    <Table.Cell textAlign="right" verticalAlign="top"></Table.Cell>
                    <Table.Cell/>
                </Table.Row>
            </Table.Footer>
        </Table>

        <p>
            <span className="bluekit-icon icon-checkmark"/>{' '}
            {localize('costCalculation.deliveryTime')}:{' '}
            <span className="midBlue">{localize("n_business_days", { value: settings.deliveryWorkdays })}</span>
        </p>
        <p>
            <span className="bluekit-icon icon-checkmark"/>{' '}
            {localize('costCalculation.offerValidity')}:{' '}
            <span className="midBlue">{localize("n_weeks", { value: settings.offerValidityWeeks })}</span>
        </p>
        {!!specialRemarks?.length && specialRemarks.map((remark, i) => (
            <p key={`special-${i}`} className="indentend-text">
                {nl2br(remark)}
            </p>
        ))}
        {settings.generalRemarks &&
            <p className="indentend-text">
                {nl2br(substitute(settings.generalRemarks, []))}
            </p>
        }
    </>
}
