import React from "react";
import { FieldState } from "redux-form/lib/reducer";

import { useLocalization } from "../../localization/hook";

interface IReduxFormFieldStateDict {
    [name: string]: FieldState;
}


interface IFormError {
    key: string;
    message: string;
}

export type IFormErrorList = IFormError[];

export interface IFormErrorDict {
    [key: string]: any;
}

export function syncedErrorsToList(syncedErrors: IFormErrorDict): IFormErrorList {
    return Object.keys(syncedErrors).map(key => ({key, message: syncedErrors[key]}));
}

export function errorToList(e: Error|null, key: string = '*') {
    if (!e) {
        return null;
    }
    return syncedErrorsToList({ [key]: e.message });
}

export interface FormErrorListProps {
    errorList?: IFormErrorList;
    fieldDict?: IReduxFormFieldStateDict;
    displayMode?: null | 'plain' | 'single';
}
export const FormErrorList: React.FC<FormErrorListProps> = ({ errorList, displayMode, fieldDict }: FormErrorListProps) => {

    const { localize } = useLocalization();

    errorList = (errorList || []).filter((error) => fieldDict == null || fieldDict[error.key]?.touched);

    switch (displayMode) {
        case 'plain': return (
            <span>
                {errorList.map(({key, message}) => <span key={key}>{localize(key) + ' ' + localize(message)}</span>)}
            </span>
        );
        case 'single': return (
            <div
                className="ui error message"
                style={errorList.length > 0 ? {display: 'block'}:{display: 'none'}}
            >
                {errorList.map(({key, message}) => <div data-cy="validation-error" key={key}>{localize(message)}</div>)}
            </div>
        );
        default: return (
            <div
                className="ui error message"
                style={errorList.length > 0 ? {display: 'block'}:{display: 'none'}}
            >
                <ul className="list">
                    {errorList.map(({key, message}) => <li data-cy="validation-error" key={key}>{localize(key) + ' ' + localize(message)}</li>)}
                </ul>
            </div>
        );
    }
}
