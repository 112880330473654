import { PRODUCT_GROUP } from "../../product/lookup";
import { IProduct } from "../../product/types";
import { getProductById } from "../CalculationService";
import { COMPONENT_GROUP_IDS_WITH_EINBAUWINKEL, getQuantitiesFromVentilationComponentsFormModel } from "../CalculationService/VentilationComponents";
import { RuleKey, RootBuilderRule } from "./types";


/**
 * Rules for adding user-selected ventilation and weather protection components
 */
const ventCompFlow: Record<RuleKey, RootBuilderRule> = {
    // Includes NRWG, ACCESSORIES, WEATHERPROTECTION
    'ventcomp-form-quantites': {
        stage: 'vent',
        exec: ({ addProductById, productData: { products } }, { ventilationComponentsValues }) => {
            getQuantitiesFromVentilationComponentsFormModel(ventilationComponentsValues)
                .filter(([_, quantity]) => quantity > 0)
                .map<[IProduct, number]>(([id, quantity]) => {
                    const product = getProductById(products, id);
                    if (!product) {
                        console.debug(`Encountered missing product with id ${id}`);
                        return null;
                    }
                    return [product, quantity];
                })
                .filter(p => p != null)
                .forEach(([product, quantity]) => addProductById(product.id, quantity));
        },
    },
    [PRODUCT_GROUP.BRACKET]: {
        stage: 'post',
        when: { ctx: 'ventilationComponentsValues', key: 'includeEinbauwinkel' },
        exec: ({ addProductById, productData: { products } }, _, { selectedProducts }) => {
            selectedProducts
                .filter(p => p.quantity > 0)
                .filter(p => COMPONENT_GROUP_IDS_WITH_EINBAUWINKEL.includes(p.productGroupId))
                .map<[IProduct, number]>(({ id, quantity }) => {
                    const product = getProductById(products, id);
                    if (!product) {
                        console.debug(`Encountered missing product with id ${id}`);
                        return null;
                    }
                    return [product, quantity];
                })
                .filter(x => x != null)
                .map<[IProduct, number]>(([ventProduct, quantity]) => {
                    const bracketProduct = products.find(p => p.bxH === ventProduct.bxH && p.productGroupId === PRODUCT_GROUP.BRACKET);
                    if (!bracketProduct) {
                        console.debug(`Failed to find a mounting bracket for product ${ventProduct.id}`);
                        return null;
                    }
                    return [bracketProduct, quantity];
                })
                .filter(x => x != null)
                .forEach(([product, quantity]) => addProductById(product.id, quantity));
        },
    },
};

export default ventCompFlow;
