import { COUNTRY, LIFT_AREA_PERCENTAGE, PROJECT_TYPE } from "../../project/lookup";
import { PRODUCT_CATEGORY } from "../../product/lookup";

import { getLiftAreaMaxValue, LIFT_AREA_STEP } from '../constant';
import { ICalculationFormsState } from "../CalculationModels";
import { filterProductsByGroup, getProductById } from "../CalculationService";
import { BasicInputsFormModel } from "../models/FormModels";

import { getAvailableVentilationComponents, getQuantitiesFromVentilationComponentsFormModel } from "./VentilationComponents";
import { IProductData } from "../../product/types";


/** German LBOs specify a minimum smoke ventilation area of 0.1m^2 == 100_000 mm^2 */
const MINIMUM_VENTILATION_AREA_DE_LBO = 100_000;
const VENTILATION_AREA_PERCENTAGE_DE_LBO = {
    [LIFT_AREA_PERCENTAGE._2_5_PERCENT]: 2.5,
    [LIFT_AREA_PERCENTAGE._1_0_PERCENT]: 1.0,
};

/**
 * Returns minimum required ventilation area according to `liftAreaPercentage`.
 * @returns The minimum ventilation area in mm^2, or `null` if no minimum is applicable
 */
function calculateMinimumLiftArea(values: Pick<BasicInputsFormModel, 'liftAreaPercentage' | 'shaftWidth' | 'shaftDepth'>): number | null {

    const { liftAreaPercentage, shaftWidth, shaftDepth } = values;

    switch (liftAreaPercentage) {
        case LIFT_AREA_PERCENTAGE._2_5_PERCENT: /* fallthrough */
        case LIFT_AREA_PERCENTAGE._1_0_PERCENT: {
            if (isNaN(shaftDepth) || isNaN(shaftWidth)) {
                return null;
            }

            const liftFootprint = shaftDepth * shaftWidth;
            const ventilationArea = Math.ceil(liftFootprint * VENTILATION_AREA_PERCENTAGE_DE_LBO[liftAreaPercentage] / 100);

            return Math.max(MINIMUM_VENTILATION_AREA_DE_LBO, ventilationArea);
        }

        case LIFT_AREA_PERCENTAGE.MANUAL:
        default:
            return null;
    }
}

export function getAvailableLiftAreaPercentages({ country, projectType }: Partial<Pick<BasicInputsFormModel, 'country' | 'projectType'>>): LIFT_AREA_PERCENTAGE[] {
    switch (country) {
    case COUNTRY.DE: return [
        LIFT_AREA_PERCENTAGE._2_5_PERCENT,
        ...(projectType === PROJECT_TYPE.MODERNIZED ? [LIFT_AREA_PERCENTAGE.MANUAL] : []),
    ];
    case COUNTRY.AT: return [
        LIFT_AREA_PERCENTAGE._2_5_PERCENT,
        LIFT_AREA_PERCENTAGE._1_0_PERCENT,
        ...(projectType === PROJECT_TYPE.MODERNIZED ? [LIFT_AREA_PERCENTAGE.MANUAL] : []),
    ];
    default: return [
        LIFT_AREA_PERCENTAGE.MANUAL,
        // LIFT_AREA_PERCENTAGE._2_5_PERCENT,
        // LIFT_AREA_PERCENTAGE._1_0_PERCENT,
    ];
    }
}

// For 03-VentilationComponents
export function getTotalLiftArea(
    basicValues: (Pick<BasicInputsFormModel, 'country' | 'projectType' | 'calculatedVentilationArea' | 'liftAreaPercentage'> & Parameters<typeof calculateMinimumLiftArea>[0]) | undefined
) {
    if (!basicValues) {
        return null;
    }

    const {
        country,
        projectType,
        calculatedVentilationArea,
        liftAreaPercentage,
    } = basicValues;

    if (
        liftAreaPercentage === LIFT_AREA_PERCENTAGE.MANUAL &&
        getAvailableLiftAreaPercentages({ country, projectType }).includes(LIFT_AREA_PERCENTAGE.MANUAL)
    ) {
        // Ventilation area has been entered manually and this is permitted
        return calculatedVentilationArea;
    }

    return calculateMinimumLiftArea(basicValues);
}

export function getTotalLiftAreaMax(formValues: ICalculationFormsState, productData: IProductData) {
    const basicValues = formValues?.basicInputsForm?.values;
    const configValues = formValues?.configurationInputsForm?.values;
    const totalLiftArea = getTotalLiftArea(basicValues);

    // Min area = calculated lift area based on from values (shaftWidth, ...)
    const minArea = totalLiftArea;
    // Max area start value = min area + 50.000 (random value just so we have a range on the slider)
    let maxArea = totalLiftArea + 2 * LIFT_AREA_STEP;

    let ventilationComponentsGroups = productData.productGroups.filter(g => g.productCategoryId === PRODUCT_CATEGORY.VENTCOMP);

    const liftAreaMaxValue = getLiftAreaMaxValue(minArea);

    const maxVentilationComponentDict = getAvailableVentilationComponents(totalLiftArea, minArea, liftAreaMaxValue, basicValues, configValues, productData);
    const maxVentilationComponents = [...maxVentilationComponentDict.ventComponents, ...maxVentilationComponentDict.weatherProtectionComponents];

    const groupsThatShouldHaveAtLeastOneEntry = ventilationComponentsGroups.filter(group => filterProductsByGroup(group, maxVentilationComponents).length > 0);

    while (maxArea <= liftAreaMaxValue) {
        const ventilationComponentDict = getAvailableVentilationComponents(totalLiftArea, minArea, maxArea, basicValues, configValues, productData);
        const ventilationComponents = [...ventilationComponentDict.ventComponents, ...ventilationComponentDict.weatherProtectionComponents];

        const filledGroups = groupsThatShouldHaveAtLeastOneEntry.filter(group => filterProductsByGroup(group, ventilationComponents).length > 0);
        if (filledGroups.length === groupsThatShouldHaveAtLeastOneEntry.length) {
            break;
        }

        maxArea += LIFT_AREA_STEP;
    }

    // If user has selected ventilation components that exceed this value increase it
    const selectedVentilationComponentIds = getQuantitiesFromVentilationComponentsFormModel(formValues.ventilationComponentsForm?.values);
    const selectedVentilationComponentsMaxArea = Math.max(...selectedVentilationComponentIds.map(([id, _]) => getProductById(productData.products, id)?.ventilationArea).filter(x => !!x));
    maxArea = Math.max(selectedVentilationComponentsMaxArea, maxArea);

    // Round to next bigger slider step
    maxArea = Math.ceil(maxArea / LIFT_AREA_STEP) * LIFT_AREA_STEP;

    // If user has selected greater area with slider take his value
    maxArea = Math.max(formValues.ventilationComponentsForm?.values?.ventilationArea ?? 0, maxArea);

    return maxArea;
}

export const formatVentilationArea = (value: number) => value.toLocaleString().replace(/,/g, '.');
