import * as React from 'react';
import { Field } from 'redux-form';
import { Header, Icon, Label, Table, TableProps } from 'semantic-ui-react';

import { configProvider } from '../../../../configProvider';
import { renderCheckbox, renderNumeric as RenderNumeric } from '../../../../shared/components/FormControls';
import ImageModal from "../../../../shared/components/ImageModal";
import { useLocalization, useProductLanguage } from '../../../../localization/hook';

import { CHARACTERISTIC_KEY, PRODUCT_GROUP } from '../../../product/lookup';
import { IProduct, IProductData, IProductGroup } from "../../../product/types";
import { ProductSelector } from '../../CalculationService/VentilationComponents';

import "./_ProductsTable.scss";

const COLUMNS = ['quantity', 'name', 'dhNo', 'ventilationArea', 'BxH', 'recess', 'recommendation'] as const;

export type ProductsTableProps = TableProps & {
    selector?: Pick<ProductSelector, 'id'|'isRecommended'>[];
    productData: IProductData;
    columns?: (typeof COLUMNS[number])[];
    showBracketOptionOnGroups?: boolean;
};
export const ProductsTable: React.FC<ProductsTableProps> = ({
    selector,
    productData,
    columns,
    className,
    showBracketOptionOnGroups,
    ...tableProps
}) => {
    const { localize } = useLocalization();

    const columnsToShow = React.useMemo(() => {
        let result = [...COLUMNS];
        if (columns) {
            result = result.filter(c => columns.includes(c));
        }
        return result;
    }, [columns]);

    const availableProducts: ProductSelector[] = React.useMemo(() => {
        if (!selector) {
            return productData.products;
        }

        return productData.products.map(p => {
            const sel = selector.find(s => s.id === p.id);
            if (!sel) {
                return null;
            }

            return {
                ...p,
                isRecommended: sel.isRecommended
            };
        }).filter(p => !!p);
    }, [productData.products, selector]);

    const groups = React.useMemo(() => productData.productGroups
        .map(g => ({
            ...g,
            products: availableProducts.filter(p => p.productGroupId === g.id),
            characteristics: productData.characteristics
                .filter(c => c.productGroupId === g.id)
                .map(c => c.characteristicKey),
        }))
        .filter(g => g.products.length > 0),
        [productData, availableProducts]
    );


    return (
        <Table {...{ ...tableProps, className: (!!className ? `${className} ` : '') + 'ProductsTable' }}>
            <thead>
                <tr>
                    {columnsToShow.map(key => (
                        <th key={key} className={key}>{localize('ventComponents.table.column.' + key)}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {groups.map(group => <ProductsTableGroupRows key={group.id}
                    columns={columnsToShow}
                    group={group}
                    products={group.products}
                    characteristics={group.characteristics}
                    showBracketOption={showBracketOptionOnGroups}
                />)}
            </tbody>
        </Table>
    );
};

interface ProductsTableGroupRowsProps {
    columns: (typeof COLUMNS[number])[];
    group: IProductGroup;
    products: IProduct[];
    characteristics: CHARACTERISTIC_KEY[];
    showBracketOption?: boolean;
}
const ProductsTableGroupRows: React.FC<ProductsTableGroupRowsProps> = ({
    columns,
    group,
    products,
    characteristics,
    showBracketOption,
}) => {
    const { localize } = useLocalization();
    const language = useProductLanguage();

    const planningFileURL = React.useMemo(() => getPlanningFileLinkURL(group), [group]);

    return (<React.Fragment key={`product-group-${group.id}`}>
        <tr key={`product-group-heading-${group.id}`} className="row-group">
            <td className="image">
                {group.imageUrl && (
                    <ImageModal title={group.description[language]}
                        showThumbnail={true}
                        thumbnailClassName="thumbnail"
                        height={48} width={48}
                        imageUrl={'/product-group/' + group.imageUrl}
                    />
                )}
            </td>
            <td colSpan={2}>
                <Header as="h4">{group.description[language]}</Header>
            </td>
            <td colSpan={3} className="info">
                <div>
                {[CHARACTERISTIC_KEY.FLUSH_MOUNTING, CHARACTERISTIC_KEY.SURFACE_MOUNTING].map(mounting => (
                    characteristics.find(c => c === mounting) &&
                    <span key={mounting}>{localize('characteristic.' + mounting)}</span>
                ))}
                {planningFileURL && (
                    <a href={planningFileURL} target="_blank" rel="noopener noreferrer">
                        {localize("link.planningfile")} <Icon name="external" size="small" />
                    </a>
                )}
                </div>
            </td>
            <td colSpan={Math.max(7, columns.length) - 1 - 2 - 3}/>
        </tr>
        {products.map(product => (
            <tr key={`product-group-product-${group.id}-${product.id}`} className="row-group-product">
                <td className="quantity">
                    <Field min={0} max={20} name={`quantity-${product.id}`} component={RenderNumeric} />
                </td>
                <td className="description">{product.description[language]}</td>
                <td className="dhNo">{product.dhNo}</td>
                <td className="ventilationArea">
                    {product.ventilationArea?.toLocaleString().replace(/,/g, '.').concat(' mm²')}
                </td>
                <td className="BxH">{product.bxH}</td>
                <td className="recess">{product.recess}</td>
                <td className="recommendation">
                    {product.isRecommended && (
                        <Label className="recRibbon" ribbon="right">{localize('info.recommendedForYou')}</Label>
                    )}
                </td>
            </tr>
        ))}
        {showBracketOption && PRODUCT_GROUPS_WITH_BRACKET.includes(group.id) && (
            <tr key={`product-group-bracket-${group.id}`} className="row-group-bracket">
                <td className="quantity">
                    <Field component={renderCheckbox} name="includeEinbauwinkel"/>
                </td>
                <td colSpan={6} className="info">
                    {localize('field.includeEinbauwinkel')}
                </td>
            </tr>
        )}
    </React.Fragment>);
}

function getPlanningFileLinkURL(group: Pick<IProductGroup, 'id'>): string|null {
    const ventcompPlanningFiles = configProvider.ventcompPlanningFileDownloads;

    if (!Object.prototype.hasOwnProperty.call(ventcompPlanningFiles, group.id)) {
        return null;
    }
    const params = ventcompPlanningFiles[group.id];
    const qsParams: [string, string][] = [];
    if (!!params.dTypes) qsParams.push(["dTypes", params.dTypes.join(",")]);
    if (!!params.pLines) qsParams.push(["pLines", params.pLines.join(",")]);

    const qs = qsParams.map(pair => pair.map(encodeURIComponent).join("=")).join("&");

    return configProvider.bkDownloadsUrl + (qs.length ? "?" + qs : "");
};

const PRODUCT_GROUPS_WITH_BRACKET = [PRODUCT_GROUP.JK190, PRODUCT_GROUP.JK190DB];
