import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { configProvider } from '../../configProvider';
import { useFetchOnce } from '../../shared/useFetchOnce.hook';
import { errorToList } from '../../shared/components/error-list';
import { useDispatchCallback } from '../../store/useDispatchCallback';

import {
    projectApi,
    projectApiActionCreator,
} from "./ProjectApi.redux";
import { jwtToken } from '../auth/AuthService';

import { CalculationApiV1Client } from './CalculationApiV1Client';
import { ICalculationInput } from './CalculationModels';


export function useProjectV1() {
    const dispatch = useDispatch();

    const productDataList = useSelector(projectApi.makeSelectBase('productData'));
    const project = useSelector(projectApi.makeSelectBase('entity'));
    const projectIsUpdating = useSelector(projectApi.makeSelectBase('isUpdating'));

    const getProject = useDispatchCallback(dispatch, projectApiActionCreator.getRequest);
    const makeProject = useDispatchCallback(dispatch, projectApiActionCreator.makeProject);
    const updateProject = useDispatchCallback(dispatch, projectApiActionCreator.updateRequest);

    return React.useMemo(() => ({
        productDataList,
        project,
        projectIsUpdating,
        getProject,
        makeProject,
        updateProject,
    }), [
        productDataList,
        project,
        projectIsUpdating,
        getProject,
        makeProject,
        updateProject,
    ]);
}

export function useCalculateCosts(calculationInput: ICalculationInput) {
    const client = React.useMemo(() => new CalculationApiV1Client(configProvider.apiUrl['calculation-v1'], jwtToken), []);

    const calculationGetter = React.useCallback(async () => {
        if (calculationInput == null) return null;
        return client.calculateCosts(calculationInput);
    }, [client, calculationInput]);

    const {
        value: costs,
        error: costsError,
        loading: costsLoading,
        fetch: fetchCosts,
        reset: resetCosts,
    } = useFetchOnce(calculationGetter);

    return React.useMemo(() => ({
        costs,
        costsErrors: errorToList(costsError),
        costsLoading,
        fetchCosts,
        resetCosts,
    }), [
        costs,
        costsError,
        costsLoading,
        fetchCosts,
        resetCosts,
    ]);
}
