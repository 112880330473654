import * as React from "react";

export interface DebugTableCol {
    key: string | number;
    header?: string;
}

export interface DebugTableRow {
    key: string | number;
    cols: (string | JSX.Element)[];
};

export interface DebugTableProps {
    title: string;
    header?: boolean;
    columns: DebugTableCol[];
    rows: DebugTableRow[];
    bottomRow?: DebugTableRow;
};

export const DebugTable: React.FC<DebugTableProps> = (props) =>{
    const { title, header, columns, rows, bottomRow } = {
        header: true,
        ...props
    };
    const colKeys = columns.map(col => col.key)

    const makeRow = (extraProps: Partial<JSX.IntrinsicElements["tr"]> = {}) => (row: DebugTableRow) => (
        <tr key={row.key} {...extraProps}>
            {row.cols.map((col, idx) => (
                <td key={colKeys[idx]}>{col}</td>
            ))}
        </tr>
    );
    const makeCommonRow = makeRow();
    const makeBottomRow = makeRow({ className: "midBlueRow boldRow" });

    return (
        <div style={{ marginTop: '50px' }}>
            <h3>{title}</h3>
            <div className="debug-section">
                <table className="ui celled table">
                    {!!header && (
                        <thead>
                            <tr>
                                {columns.map(col => (
                                    <th key={col.key}>{col.header}</th>
                                ))}
                            </tr>
                        </thead>
                    )}
                    <tbody>
                        {rows.map(makeCommonRow)}
                        {!!bottomRow && makeBottomRow(bottomRow)}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DebugTable;
