import { JsonRESTApiClient } from "../api/JsonRESTApiClient";
import { isObjectWithProperties } from "../api/simple-validators";

import { ISettings } from "./SettingsModels";


/** @see `DH.BlueKitConnect.Backend.Features.Settings.SettingsController` */
export class SettingsApiV1Client extends JsonRESTApiClient {

    /// API Endpoints

    public async getSettings(): Promise<ISettings> {
        return await this._fetchJson(
            this._getUrl(),
            {
                method: 'GET',
                headers: await this._getHeaders(),
            },
            SettingsApiV1Client.ValidateISettings
        );
    }

    public static ValidateISettings(v: unknown): v is ISettings {
        return isObjectWithProperties('generalRemarks', 'offerValidityWeeks', 'deliveryWorkdays')(v) &&
            typeof v.generalRemarks === 'string' &&
            typeof v.offerValidityWeeks === 'number' &&
            typeof v.deliveryWorkdays === 'number';
    }
}
