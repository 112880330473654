import * as React from "react";
import { Message } from "semantic-ui-react";

import { nl2br } from "./LocalizationService";
import { useCurrency, useLocalization } from "./hook";

import "./RestrictedCurrencyMessage.scss";

export const RestrictedCurrencyMessage: React.FC<{ className?: string }> = ({ className = "" }) => {
    const { localize } = useLocalization();
    const currency = "EUR";
    const selected = useCurrency();

    return currency !== selected && <Message
        className={'restrict-currency-message ' + className}
        info color='blue' size='tiny'
        content={nl2br(localize('info.currency_restricted', {
            currency,
            selected,
        }))}
    />;
};
