import { IProductData } from "../product/types";

import { ICalculationFormsState, INonStandardComponent } from './CalculationModels';


export enum CalculationActionTypes {
    REDUX_FORM_CHANGE = '@@redux-form/CHANGE',
    REDUX_FORM_BLUR = '@@redux-form/BLUR',

    SWITCH_TAB = 'SWITCH_TAB',
    SWITCH_TAB_INDEX = 'SWITCH_TAB_INDEX',
    REQUEST_SWITCH_TAB = 'REQUEST_SWITCH_TAB',
    REQUEST_SWITCH_TAB_INDEX = 'REQUEST_SWITCH_TAB_INDEX',

    INITIALIZE_FORM = 'INITIALIZE_FORM',

    PROCESS_FORM_VALUES = 'PROCESS_FORM_VALUES',

    SET_NON_STANDARD_COMPONENT_LIST = 'SET_NON_STANDARD_COMPONENT_LIST',
    ADD_NON_STANDARD_COMPONENT = 'ADD_NON_STANDARD_COMPONENT',
    REMOVE_NON_STANDARD_COMPONENT = 'REMOVE_NON_STANDARD_COMPONENT',

    IGNORE_AIO_BASIC_SETS = 'IGNORE_AIO_BASIC_SETS',
    SET_AIO_BASIC_SET_MODAL_VISIBILITY = 'SET_AIO_BASIC_SET_MODAL_VISIBILITY',
}


export const calculationActionCreator = {

    processFormValues(formValues: ICalculationFormsState, productData: IProductData) {
        return <const>{
            type: CalculationActionTypes.PROCESS_FORM_VALUES,
            payload: formValues,
            extraProductData: productData,
        };
    },

    initializeForm(formValues: ICalculationFormsState, productData: IProductData) {
        return <const>{
            type: CalculationActionTypes.INITIALIZE_FORM,
            formValues: formValues,
            productData: productData,
        };
    },

    switchTab(direction: string) {
        return <const>{
            type: CalculationActionTypes.SWITCH_TAB,
            payload: direction
        };
    },

    requestSwitchTab(direction: string) {
        return <const>{
            type: CalculationActionTypes.REQUEST_SWITCH_TAB,
            payload: direction
        };
    },

    switchTabIndex(index: number) {
        return <const>{
            type: CalculationActionTypes.SWITCH_TAB_INDEX,
            payload: index
        };
    },

    requestSwitchTabIndex(index: number) {
        return <const>{
            type: CalculationActionTypes.REQUEST_SWITCH_TAB_INDEX,
            payload: index
        };
    },

    ignoreAIOBasicSets(ignore: boolean) {
        return <const>{
            type: CalculationActionTypes.IGNORE_AIO_BASIC_SETS,
            payload: ignore
        };
    },

    setAIOBasicSetModalVisibility(isOpen: boolean) {
        return <const>{
            type: CalculationActionTypes.SET_AIO_BASIC_SET_MODAL_VISIBILITY,
            payload: isOpen
        };
    },

    /// Non-Standard Component List

    /** Replace the entire non-standard component list */
    setNonStandardComponentList(components: INonStandardComponent[]) {
        return <const>{
            type: CalculationActionTypes.SET_NON_STANDARD_COMPONENT_LIST,
            payload: components,
        };
    },

    /** Add a non-standard component to the list */
    addNonStandardComponent(description: string, price: number, quantity: number) {
        return <const>{
            type: CalculationActionTypes.ADD_NON_STANDARD_COMPONENT,
            payload: {
                description,
                price,
                quantity,
            }
        };
    },


    /** Remove a non-standard component from the list by its unique `productId` */
    removeNonStandardComponent(productId: string) {
        return <const>{
            type: CalculationActionTypes.REMOVE_NON_STANDARD_COMPONENT,
            payload: productId
        };
    },
}

type ValueOf<T> = T[keyof T];
export type KnownCalculationActions = ReturnType<ValueOf<typeof calculationActionCreator>>;
