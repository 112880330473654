import { projectApi } from '../features/calculation/ProjectApi.redux';
import { reducer as calculationReducer } from '../features/calculation/CalculationReducer';
import { customFormReducer } from "../features/form/FormReducer";


export const reducers = {
    form: customFormReducer,
    calculationState: calculationReducer,
    project: projectApi.reducer,
};
