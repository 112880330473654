import * as React from 'react';
import {Field} from 'redux-form';
import {Form, FormProps} from 'semantic-ui-react';

import ImageModal from "../../../../shared/components/ImageModal";
import {nl2br} from "../../../../localization/LocalizationService";
import { useLocalization } from '../../../../localization/hook';

import { LIFT_STATUS_DETECTION, SMOKE_DETECTION } from '../../../project/lookup';

import { useFormUtility } from '../../FormUtility';
import { BasicInputsFormModel, ConfigurationInputsFormModel } from "../../models/FormModels";
import { getApplicableSmokeDetectionMethods } from "../../CalculationService/SmokeDetection";
import { getApplicatableLiftStatusDetectionMethods } from '../../CalculationService/LiftStatusDetection';
import { getAvailableSystems } from "../../CalculationService/System";


export type ConfigurationFormProps = FormProps & Pick<ReturnType<typeof useFormUtility>, 'fieldProps'|'FieldDisabledMessage'> & {
    basicInputFormValues: BasicInputsFormModel,
    configurationInputFormValues: ConfigurationInputsFormModel,
};
export const ConfigurationForm: React.FC<ConfigurationFormProps> = ({
    fieldProps,
    FieldDisabledMessage,
    basicInputFormValues,
    configurationInputFormValues,
    className,
    ...formProps
}) => {
    const { localize, localizeEnum, enumToLookup } = useLocalization();

    const systemOptions = enumToLookup(getAvailableSystems(basicInputFormValues), 'SYSTEM');
    const smokeDetectionOptions = enumToLookup(getApplicableSmokeDetectionMethods(
        basicInputFormValues,
        configurationInputFormValues
    ), 'SMOKE_DETECTION');
    const liftStatusDetectionOptions = enumToLookup(getApplicatableLiftStatusDetectionMethods(
        basicInputFormValues,
        configurationInputFormValues
    ), 'LIFT_STATUS_DETECTION');

    return (
        <Form {...{ ...formProps, className: (!!className ? `${className} ` : '') + 'ConfigurationForm' }}>
            <Form.Group widths="equal">
                <Field {...fieldProps("system", { component: "select", required: true })}
                    options={systemOptions} />
                {
                    !!configurationInputFormValues.system &&
                    <ImageModal imageUrl={`/product/system-${configurationInputFormValues.system.toLowerCase()}.png`}
                                title={localizeEnum(configurationInputFormValues.system, 'SYSTEM')}
                                thumbnailClassName={'image-button image-button-dropdown'}/>
                }
                <FieldDisabledMessage name="system"/>
            </Form.Group>
            <Form.Group widths="equal">
                <Field {...fieldProps("smokeDetection", { component: "select", required: true })}
                    options={smokeDetectionOptions} />
                {
                    !!configurationInputFormValues.smokeDetection && configurationInputFormValues.smokeDetection !== SMOKE_DETECTION.NONE &&
                    <ImageModal imageUrl={'/product/smoke-detection-' + configurationInputFormValues.smokeDetection.toLowerCase() + '.png'}
                                title={localizeEnum(configurationInputFormValues.smokeDetection, 'SMOKE_DETECTION')}
                                thumbnailClassName={'image-button image-button-dropdown'}/>
                }
                <FieldDisabledMessage name="smokeDetection"/>
            </Form.Group>
            <Form.Group widths="equal">
                <Field {...fieldProps("liftStatusDetection", { component: 'select', required: true })}
                    options={liftStatusDetectionOptions} infoText={nl2br(localize('info.liftStatusDetection'))} />
                {
                    configurationInputFormValues.liftStatusDetection === LIFT_STATUS_DETECTION.LST_CO2_V3 &&
                    <ImageModal imageUrl={'/product/lst-co2-v3.png'}
                                title={localizeEnum(LIFT_STATUS_DETECTION.LST_CO2_V3, 'LIFT_STATUS_DETECTION')}
                                thumbnailClassName={'image-button image-button-dropdown'}/>
                }
                <FieldDisabledMessage name="liftStatusDetection"/>
            </Form.Group>
        </Form>
    );
};
