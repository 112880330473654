import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Button, ButtonGroup, ButtonProps, Icon, Label, Modal, Popup, PopupProps, Table, TableProps } from 'semantic-ui-react';

import { configProvider } from '../../configProvider';
import { useLocalization } from '../../localization/hook';

import { jwtToken } from '../auth/AuthService';
import { useUser } from '../auth/User.hook';

import { GetProjectsView, IProjectSynopsis, ProjectApiV1Client } from './ProjectApiV1Client';

import './_ProjectTable.scss';


export type ProjectTableProps = TableProps & {
    view: GetProjectsView;
    query?: string|null;
};
export const ProjectTable: React.FC<ProjectTableProps> = ({ view, query, className, ...tableProps }) => {
    const { localize, localizeEnum } = useLocalization();
    const history = useHistory();

    const ITEMS_PER_PAGE = 100;
    const [page, setPage] = React.useState(0);
    React.useEffect(() => {
        // reset page when the view changes
        setPage?.(0);
    }, [view, setPage]);

    const {
        projectList,
        projectListHasMore,
        projectListError,
        isLoading,
        requestDelete,
    } = useProjectListApiV1Client(view, query, page * ITEMS_PER_PAGE, ITEMS_PER_PAGE);

    const onCreateProjectClick = React.useCallback(() => {
        history.push('/calculation/new/basic-input');
    }, [history]);

    const onDeleteProjectConfirm = requestDelete;

    const onAnchorClick = React.useCallback((event: React.UIEvent, data: ButtonProps) => {
        event.preventDefault();

        const target = data.href;
        if (!target) return;

        history.push(target);
    }, [history]);

    const paginationElement = React.useMemo(() => (
        <Table.Cell colSpan={5} textAlign='center'>
            <ButtonGroup>
                <Button compact size='tiny'
                    icon='angle double left'
                    disabled={page === 0 || isLoading}
                    onClick={() => setPage(0)}
                />
                <Button compact size='tiny'
                    icon='angle left'
                    disabled={page === 0 || isLoading}
                    onClick={() => setPage(page - 1)}
                />
                <Button compact size='tiny' content={page + 1} disabled />
                <Button compact size='tiny'
                    icon='angle right'
                    disabled={!projectList?.length || !projectListHasMore || isLoading}
                    onClick={() => setPage(page + 1)}
                />
            </ButtonGroup>
        </Table.Cell>
    ), [projectList?.length, projectListHasMore, isLoading, page, setPage]);


    return (
        <Table {...{ ...tableProps, className: (!!className ? `${className} ` : '') + 'ProjectTable' }}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell className='col-name-id'>
                        {localize('field.name')}<br/>
                        <span>{localize('field.id')}</span>
                    </Table.HeaderCell>
                    <Table.HeaderCell>{localize('field.createdAt')}</Table.HeaderCell>
                    <Table.HeaderCell>{localize('field.createdBy')}</Table.HeaderCell>
                    <Table.HeaderCell>{localize('projectState')}</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row key='create' className='row-create'>
                    <Table.Cell colSpan={5} textAlign='center'>
                        <Button primary size='massive' icon labelPosition='left'
                            onClick={onCreateProjectClick}>
                            <Icon name='plus circle'/>
                            {localize('projectList.create.label')}
                        </Button>
                    </Table.Cell>
                </Table.Row>
                {!projectListError && (
                    <Table.Row key='pagination-top'>
                        {paginationElement}
                    </Table.Row>
                )}
                {isLoading && (<>
                    <Table.Row key='loader' className='row-loader'>
                        <Table.Cell colSpan={5} textAlign='center'>
                            <Icon loading name='circle notch'/>
                            {localize('loading...')}
                        </Table.Cell>
                    </Table.Row>
                </>)}
                {!!projectListError && !isLoading && (
                    <Table.Row key='error' error className='row-error'>
                        <Table.Cell colSpan={5} textAlign='center'>
                            Error<br/>
                            {String(projectListError)}
                        </Table.Cell>
                    </Table.Row>
                )}
                {!projectListError && !projectList?.length && !isLoading && (
                    <Table.Row key='empty' disabled className='row-empty'>
                        <Table.Cell colSpan={5} textAlign='center'>
                            {localize(page === 0 ? 'projectList.message.noProjects' : 'projectList.message.noMoreProjects')}
                        </Table.Cell>
                    </Table.Row>
                )}
                {!projectListError && projectList?.map(project => (
                    <Table.Row key={`project-${project.id}`} disabled={isLoading} className='row-project'>
                        <Table.Cell className='col-name-id'>
                            {project.name}<br/>
                            <span>{project.id}</span>
                        </Table.Cell>
                        <Table.Cell>
                            {moment(project.createdAt).format('DD.MM.YYYY HH:mm')}
                        </Table.Cell>
                        <Table.Cell>
                            {project.createdBy.firstName} {project.createdBy.lastName}<br/>
                            {project.owningCompany?.name ?? ''}
                        </Table.Cell>
                        <Table.Cell>
                            {localizeEnum(project.state, 'projectState')}
                        </Table.Cell>
                        <Table.Cell textAlign='right' className='col-actions'>
                            <ButtonWithPopup popupContent={localize('projectList.button.as-template')}
                                size='small' icon='copy' as='a' href={`/calculation/${project.id}/as-template`} onClick={onAnchorClick} />
                            <ButtonWithPopup popupContent={localize('projectList.button.edit')}
                                size='small' icon='edit' as='a' href={`/calculation/${project.id}`} onClick={onAnchorClick} />
                            <DeleteProjectModal project={project} onConfirm={() => onDeleteProjectConfirm(project.id)}/>
                        </Table.Cell>
                    </Table.Row>
                ))}
                {!projectListError && (
                    <Table.Row key='pagination-bottom'>
                        {paginationElement}
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );
};


interface ButtonWithPopupProps extends ButtonProps {
    popupContent: PopupProps['content'];
}
const ButtonWithPopup: React.FC<ButtonWithPopupProps> = ({ popupContent, ...buttonProps }) => (
    <Popup content={popupContent} trigger={
        <Button {...buttonProps}/>
    }/>
);

interface DeleteProjectModalProps {
    project: IProjectSynopsis,
    onConfirm?: () => unknown,
};
const DeleteProjectModal: React.FC<DeleteProjectModalProps> = ({ project, onConfirm }) => {
    const ctrlKeyDisabled = configProvider.disableCtrlKeyFastDelete;

    const { localize, localizeEnum } = useLocalization();
    const [open, setOpen] = React.useState(false);

    const onCancelClick = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const onConfirmClick = React.useCallback(() => {
        setOpen(false);
        onConfirm && onConfirm();
    }, [onConfirm, setOpen]);

    const onTriggerClick = React.useCallback((event: React.MouseEvent) => {
        if (!ctrlKeyDisabled && event.ctrlKey) {
            onConfirmClick();
            return;
        }

        setOpen(true);
    }, [onConfirmClick, setOpen]);

    const skipThisDialogWithCtrlKeyHint = React.useMemo(() => {
        if (ctrlKeyDisabled || !open) {
            return null;
        }

        const separator = '#!KEY!#';

        const localizedKeyName = localize('key.Ctrl');
        const localizedHint = localize('uihint.skipThisDialogWithKey', { key: separator })

        const parts: JSX.Element[] = [];
        localizedHint.split(separator).forEach((part, idx) => {
            if (idx > 0) {
                parts.push(<Label key={2 * idx - 1} as='span' content={localizedKeyName}/>);
            }
            parts.push(<span key={2 * idx}>{part}</span>)
        });

        return (
            <p style={{ textAlign: 'center' }}>{parts}</p>
        );
    }, [ctrlKeyDisabled, open, localize]);

    return (
        <Modal dimmer className='ProjectExplorerPage--ProjectTable--DeleteProjectModal'
            style={{ 'width': '25%' }} /* Override LayoutV1 */
            openOnTriggerClick={false} /* Manually open in onTriggerClick to handle Ctrl-click */
            closeOnDimmerClick={false}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            trigger={
                <ButtonWithPopup size='small' icon='delete'
                    popupContent={localize('projectList.button.delete')}
                    onClick={onTriggerClick}/>
            }>
            <Modal.Header>
                <Icon name='remove circle'/>
                {localize('projectList.button.delete')}?
            </Modal.Header>
            <Modal.Content>
                <Table basic="very" compact="very" columns={5} fixed className='project-info'>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>{localize('field.id')}</Table.Cell>
                            <Table.Cell>{project.id}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{localize('field.projectName')}</Table.Cell>
                            <Table.Cell>{project.name}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{localize('field.createdBy')}</Table.Cell>
                            <Table.Cell>
                                {project.createdBy.firstName} {project.createdBy.lastName}<br/>
                                {project.createdBy.email}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell></Table.Cell>
                            <Table.Cell>
                                {project.owningCompany?.name}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{localize('field.createdAt')}</Table.Cell>
                            <Table.Cell>
                                {moment(project.createdAt).format('DD.MM.YYYY HH:mm')}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{localize('projectState')}</Table.Cell>
                            <Table.Cell>
                                {localizeEnum(project.state, 'projectState')}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                {skipThisDialogWithCtrlKeyHint}
            </Modal.Content>
            <Modal.Actions>
                <Button basic secondary onClick={onCancelClick}>{localize('No')}</Button>
                <Button color='red' onClick={onConfirmClick}>{localize('projectList.button.delete')}</Button>
            </Modal.Actions>
        </Modal>
    );
};


function useProjectListApiV1Client(...params: Parameters<ProjectApiV1Client['getProjects']>) {
    const { user } = useUser();
    const client = React.useMemo(
        () => !user ? null : new ProjectApiV1Client(configProvider.apiUrl['project-v1'], jwtToken),
        [user]
    );

    const [
        [projectList, projectListHasMore, projectListError],
        setProjectListAndError
    ] = React.useState<[IProjectSynopsis[]|null, boolean|null, any|null]>([null, null, null]);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setIsLoading(true);

        if (!client) return;

        client.getProjects(...params).then(([projectList, hasMore]) => {
            setProjectListAndError([projectList, hasMore, null]);
        }).catch(e => {
            setProjectListAndError([null, null, e]);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [client, ...params]);

    const requestDelete: (id: string) => unknown = React.useCallback(async (id: string) => {
        if (!client) return;

        try {
            setIsLoading(true);
            await client.deleteProjectById(id);

            setProjectListAndError(([pl, more, e]) => [
                pl ? [...pl.filter(p => p.id !== id)] : null,
                more,
                e,
            ]);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }, [client]);

    return React.useMemo(() => ({
        projectList,
        projectListHasMore,
        projectListError,
        isLoading,
        requestDelete,
    }), [
        projectList,
        projectListHasMore,
        projectListError,
        isLoading,
        requestDelete,
    ]);
}
