import * as React from 'react';
import * as classNames from 'classnames';
import { SemanticShorthandItem, Tab, TabPaneProps, TabProps } from 'semantic-ui-react';

import { useLocalization } from '../../../localization/hook';
import { ISelectedProduct } from "../CalculationModels";
import { Tabs } from '../CalculationReducer';

import BasicInputsForm from './01-BasicInputs/BasicInputsForm';
import ConfigurationInputsForm from './02-ConfigurationInputs/ConfigurationInputsForm';
import VentilationComponentsForm from './03-VentilationComponents/VentilationComponentsForm';
import CostCalculationForm from './04-CostCalculation/CostCalculationForm';

import './CalculationTabs.scss';


interface CalculationTabsProps {
    activeTabIndex: number;
    selectedAIOBasicSet: ISelectedProduct;
    canNavigateToConfigurationForm: boolean;
    canNavigateToVentilationForm: boolean;
    canNavigateToCostCalculationForm: boolean;

    requestSwitchTabIndex: (index: number) => unknown;
    setAIOBasicSetModalVisibility: (isOpen: boolean) => unknown;
};
export const CalculationTabs: React.FC<CalculationTabsProps> = ({
    activeTabIndex,
    selectedAIOBasicSet,
    canNavigateToConfigurationForm,
    canNavigateToVentilationForm,
    canNavigateToCostCalculationForm,
    requestSwitchTabIndex,
    setAIOBasicSetModalVisibility,
}) => {
    const { localize } = useLocalization();

    const onTabChanged = (event: React.MouseEvent<HTMLDivElement>, data: TabProps) => {
        switch (data.activeIndex) {
            case Tabs.BasicInput:
                requestSwitchTabIndex(Tabs.BasicInput);
                if (selectedAIOBasicSet) {
                    setAIOBasicSetModalVisibility(true);
                }
                break;
            case Tabs.Configuration:
                if (!canNavigateToConfigurationForm) return;
                requestSwitchTabIndex(Tabs.Configuration);
                break;
            case Tabs.VentilationComponents:
                if (!canNavigateToVentilationForm) return;
                requestSwitchTabIndex(Tabs.VentilationComponents);
                break;
            case Tabs.CostCalculation:
                if (!canNavigateToCostCalculationForm) return;
                requestSwitchTabIndex(Tabs.CostCalculation);
                break;
        }
    };

    const panes: ({ menuItem?: any;} & (
        | { pane: SemanticShorthandItem<TabPaneProps>; }
        | { render: () => React.ReactNode; }
    ))[] = [
        {
            menuItem: {
                key: 'basicInputs',
                content: (
                    <div
                        data-cy="tab-header-basic-input"
                        className={classNames(
                            'menu-item-header',
                            {active: activeTabIndex === 0},
                        )}
                    >
                        <span className="ui circular label">1</span>
                        <span className="title">{localize('page.title.basicInputs')}</span>
                    </div>
                )
            },
            pane: {
                key: 'basicInputs',
                content: <BasicInputsForm/>
            }
        },
        {
            menuItem: {
                key: 'configurationInputs',
                content: (
                    <div
                        data-cy="tab-header-configuration"
                        className={classNames(
                            'menu-item-header',
                            {active: activeTabIndex === 1},
                            {disabled: !canNavigateToConfigurationForm},
                        )}
                    >
                        <span className="ui circular label">2</span>
                        <span className="title">{localize('page.title.configurationInputs')}</span>
                    </div>
                )
            },
            pane: {
                key: 'configurationInputs',
                content: <ConfigurationInputsForm/>
            }
        },
        {
            menuItem: {
                key: 'ventilationComponents',
                content: (
                    <div
                        data-cy="tab-header-ventilation-components"
                        className={classNames(
                            'menu-item-header',
                            {active: activeTabIndex === 2},
                            {disabled: !canNavigateToVentilationForm},
                        )}
                    >
                        <span className="ui circular label">3</span>
                        <span className="title">{localize('page.title.ventilationComponents')}</span>
                    </div>
                )
            },
            pane: {
                key: 'ventilationComponents',
                content: <VentilationComponentsForm/>
            }
        },
        {
            menuItem: {
                key: 'costCalculation',
                content: (
                    <div
                        data-cy="tab-header-cost-calculation"
                        className={classNames(
                            'menu-item-header',
                            {active: activeTabIndex === 3},
                            {disabled: !canNavigateToCostCalculationForm},
                        )}
                    >
                        <span className="ui circular label">4</span>
                        <span className="title">{localize('page.title.costCalculation')}</span>
                    </div>
                )
            },
            pane: {
                key: 'costCalculation',
                content: <CostCalculationForm isActive={activeTabIndex === 3}/>
            }
        }
    ];

    return (
        <div className='CalculationTabs'>
            <Tab
                panes={panes}
                menu={{tabular: true, stackable: true, widths: panes.length}}
                renderActiveOnly={false}
                activeIndex={activeTabIndex}
                onTabChange={onTabChanged}
            />
        </div>
    );
}
