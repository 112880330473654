import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import { Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { Form, Message } from 'semantic-ui-react';

import { ReduxFormKeys } from '../../../form/ReduxFormKeys';
import { IApplicationState } from '../../../../shared/ApplicationState';
import { TabSection } from '../../../../shared/components/TabSection';
import { renderHidden, RenderSlider } from '../../../../shared/components/FormControls';
import { FormErrorList, syncedErrorsToList } from "../../../../shared/components/error-list";
import { nl2br } from '../../../../localization/LocalizationService';
import { useLocalization } from '../../../../localization/hook';

import { projectApi } from '../../ProjectApi.redux';

import { getLiftAreaMaxValue, LIFT_AREA_STEP } from '../../constant';
import { getProductById } from "../../CalculationService";
import { isAllFormReady } from "../../calculation.selector";
import { PRODUCT_GROUP, PRODUCT_TYPE } from '../../../product/lookup';
import { INSTALLATION_POSITION, PROJECT_TYPE } from '../../../project/lookup';
import { ISelectedProduct } from "../../CalculationModels";
import { VentilationComponentsFormModel } from '../../models/FormModels';
import { BASIC_INPUTS_FORM_VALUES_SELECTOR } from "../02-ConfigurationInputs/ConfigurationInputsForm";

import { ProductsTable } from './_ProductsTable';


const VentilationComponentsForm: React.FC<Props> = props => {
    const {
        isAllFormReady,
        syncedErrors,
        basicInputFormValues,
        totalLiftArea,
        productDataList,
        filteredVentilationComponents,
        filteredWeatherProtectionComponents,
        selectedAIOBasicSet,
    } = props;

    const { localize } = useLocalization();

    if (!isAllFormReady) return <></>;

    if (basicInputFormValues.nrwgDelivered) {
        return <TabSection><p>{localize('info.weathershelter_preinstalled')}</p></TabSection>;
    }

    const errorList = syncedErrorsToList(syncedErrors).map(({ key, message }) => ({ key: 'field.' + key, message }));

    const showModernizedWeathershelterInfo = basicInputFormValues.projectType === PROJECT_TYPE.MODERNIZED &&
        basicInputFormValues.installationPosition === INSTALLATION_POSITION.VERTICAL &&
        basicInputFormValues.weathershelter;

    return (
        <>
            {
                !selectedAIOBasicSet &&
                <Form>
                    <Field
                        component={renderHidden}
                        name="validationTrigger"
                    />
                    <Field component={RenderSlider}
                            min={totalLiftArea}
                            max={getLiftAreaMaxValue(totalLiftArea)}
                            step={LIFT_AREA_STEP}
                            label={localize('ventComponents.ventilationArea')}
                            name="ventilationArea"/>
                    <p className="info-centered">{localize('info.ventilation_area_slider.text')}</p>
                    {basicInputFormValues.projectType === PROJECT_TYPE.MODERNIZED && (
                        <Message color="blue" icon="info circle" content={
                            <p className="info-centered">
                                {nl2br(localize("info.modernized_check_opening"))}
                            </p>
                        }/>
                    )}
                </Form>
            }

            <ProductsTable compact
                selector={filteredVentilationComponents}
                productData={productDataList}
                showBracketOptionOnGroups/>

            <div style={{ padding: '2em 0 1em 0' }}>
                {showModernizedWeathershelterInfo && (
                    <Message color="blue" icon="info circle" content={
                        <p className="info-centered">
                            {nl2br(localize("info.modernized_weathershelter"))}
                        </p>
                    }/>
                )}
            </div>

            {filteredWeatherProtectionComponents?.length > 0 && <>
                <h4 style={{marginTop: '40px', marginLeft: '30px'}}>{nl2br(localize('info.weathershelter_required_selected'))}</h4>
                <ProductsTable
                    selector={filteredWeatherProtectionComponents}
                    productData={productDataList}/>
            </>}

            <FormErrorList errorList={errorList}/>
        </>
    );
};

function validate(_values: VentilationComponentsFormModel, props: Props) {
    const errors: { selectedProducts?: string } = {};

    if (!props.basicInputFormValues) return;

    const nrwgProductList = props.selectedProducts
        .filter(p => p.quantity && p.productTypeId === PRODUCT_TYPE.NRWG && p.productGroupId !== PRODUCT_GROUP.BRACKET);

    if (!props.basicInputFormValues.nrwgDelivered && !props.basicInputFormValues.aioBasicSet) {
        if (nrwgProductList.length === 0) {
            errors.selectedProducts = 'ventComponents.error.noVentComponentSelected';
        }
    }

    // In this case skip validation because the user should talk to bluekit about weather protection components
    const skipWeathershelterValidation = props.basicInputFormValues.projectType === 'MODERNIZED' && props.basicInputFormValues.installationPosition === 'V';

    // The user should select one weather protection component for each ventilation component
    if (props.basicInputFormValues.weathershelter && !skipWeathershelterValidation) {
        let nrwgProductsWithoutWeatherProtectionCount: number;
        let nrwgProductsWithoutWeatherProtection: ISelectedProduct[];
        if (props.basicInputFormValues.aioBasicSet) {
            nrwgProductsWithoutWeatherProtectionCount = 1;
            nrwgProductsWithoutWeatherProtection = []; // We dont need a list of concrete products here for ct sets, because only fitting weather protection components are shown for them
        } else {
            // Remove THERMOFLAP & SLIDEFLAP products as they already have weather protection built-in
            const groupsWithIncludedWeatherProtection = [PRODUCT_GROUP.THERMOFLAP, PRODUCT_GROUP.SLIDEFLAP];
            nrwgProductsWithoutWeatherProtection = nrwgProductList.filter(p => !groupsWithIncludedWeatherProtection.includes(p.productGroupId));
            nrwgProductsWithoutWeatherProtectionCount = nrwgProductsWithoutWeatherProtection.map(p => p.quantity).reduce((a, b) => a + b, 0);
        }

        const weatherProtectionComponents = props.selectedProducts.filter(p => p.quantity && p.productTypeId === PRODUCT_TYPE.WEATHERPROTECTION);
        const weatherProtectionCount = weatherProtectionComponents.map(p => p.quantity).reduce((a, b) => a + b, 0);

        if (nrwgProductsWithoutWeatherProtectionCount !== weatherProtectionCount) {
            errors.selectedProducts = 'ventComponents.error.incompatibleCombination';
        } else if (
            nrwgProductsWithoutWeatherProtectionCount === 1 && weatherProtectionCount === 1
            && nrwgProductsWithoutWeatherProtection.length === 1 && weatherProtectionComponents.length === 1
        ) {
            const nrwgSelectedProduct = nrwgProductsWithoutWeatherProtection[0];
            const weatherProtectionSelectedProduct = weatherProtectionComponents[0];

            const nrwgProduct = getProductById(props.productDataList.products, nrwgSelectedProduct.id);
            if (!nrwgProduct) {
                console.debug(`Encountered missing product with id ${nrwgSelectedProduct.id}`);
                return;
            }

            const weatherProtectionProduct = getProductById(props.productDataList.products, weatherProtectionSelectedProduct.id);
            if (!weatherProtectionProduct) {
                console.debug(`Encountered missing product with id ${weatherProtectionSelectedProduct.id}`);
                return;
            }

            if (weatherProtectionProduct.width < nrwgProduct.width || weatherProtectionProduct.height < nrwgProduct.height)
            {
                errors.selectedProducts = 'ventComponents.error.weathershelterTooSmall';
            }
        }
    }

    return errors;
}

const mapStateToProps = createStructuredSelector({
    isAllFormReady: isAllFormReady,
    syncedErrors: getFormSyncErrors(ReduxFormKeys.ventilationComponentsForm),

    basicInputFormValues: getFormValues(ReduxFormKeys.basicInputsForm) as BASIC_INPUTS_FORM_VALUES_SELECTOR,

    selectedProducts: (state: IApplicationState) => state.calculationState.selectedProducts,
    totalLiftArea: (state: IApplicationState) => state.calculationState.totalLiftArea,
    filteredVentilationComponents: (state: IApplicationState) => state.calculationState.filteredVentilationComponents,
    filteredWeatherProtectionComponents: (state: IApplicationState) => state.calculationState.filteredWeatherProtectionComponents,
    selectedAIOBasicSet: (state: IApplicationState) => state.calculationState.selectedAIOBasicSet,

    productDataList: projectApi.makeSelectBase('productData'),
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: ReduxFormKeys.ventilationComponentsForm,
        validate,
        touchOnChange: true,
        touchOnBlur: false,
        keepDirtyOnReinitialize: true,
        enableReinitialize: true,
    })(VentilationComponentsForm)
);


type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type Props = StateProps & DispatchProps & InjectedFormProps;
