import * as React from "react";

import { configProvider } from "../../configProvider";
import { useFetchOnce } from "../../shared/useFetchOnce.hook";
import { UserApiV1Client } from "./UserApiV1Client";
import { IUser } from "./IUser";
import { jwtToken } from "./AuthService";


interface UserContextType {
    user: IUser|null;
    userLoading: boolean;
    userError: Error|null,
    fetchUser: () => Promise<void>;
    resetUser: () => void;
}
const UserContext = React.createContext<UserContextType>(null);

export const UserContextProvider: React.FC<{}> = ({ children }) => {
    const client = React.useMemo(() => new UserApiV1Client(configProvider.apiUrl['user-v1'], jwtToken), []);
    const userGetter = React.useCallback(() => client.current(), [client]);

    const {
        value: user,
        loading: userLoading,
        error: userError,
        fetch: fetchUser,
        reset: resetUser,
    } = useFetchOnce(userGetter);

    const value = React.useMemo(() => ({
        user,
        userLoading,
        userError,
        fetchUser,
        resetUser,
    }), [
        user,
        userLoading,
        userError,
        fetchUser,
        resetUser,
    ]);

    return React.createElement(UserContext.Provider, { value }, children);
};

export function useUser() {
    return React.useContext(UserContext);
}
