import * as React from 'react';
import {Image, Icon, Select, DropdownProps} from 'semantic-ui-react';
import * as classNames from 'classnames';

import {useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from "react-router-dom";

import { configProvider } from '../configProvider';
import { IApplicationState } from '../shared/ApplicationState';
import {projectApi} from '../features/calculation/ProjectApi.redux';
import { useAuthenticated } from '../features/auth/Auth.hook';
import { useUser } from '../features/auth/User.hook';
import {canAccessConnect} from "../features/auth/PermissionService";

import { useCurrency, useLanguage, useLocalization, useSetCurrency, useSetLanguage } from '../localization/hook';
import { CurrencyKey, forLanguage, LanguageKey } from '../localization/LocalizationService';

import logoImage from '../assets/BlueKit-logo.png';
import './Layout.scss';


export const Layout: React.FC<{}> = props => {
    const [phoneVisible, setPhoneVisible] = React.useState(false);
    const { reset: resetAuthentication } = useAuthenticated();
    const { user } = useUser();

    // Routing
    const rootMatch = useRouteMatch('/');
    const calculatorMatch = useRouteMatch('/calculation');
    const simulatorMatch = useRouteMatch('/simulator');
    const { push: historyPush } = useHistory();

    const isCalculatorPage = rootMatch?.isExact || calculatorMatch;
    const isSimulatorPage = simulatorMatch;

    // Localization
    const { localize } = useLocalization();

    const currentLanguage = useLanguage();
    const setLanguage = useSetLanguage();
    const languageOptions = React.useMemo(() => LanguageKey.map(key => ({
        key,
        value: key,
        text: `(${key.toLocaleUpperCase()}) ${forLanguage(key).localize('language.' + key, {}, key)}`
    })), []);

    const currentCurrency = useCurrency();
    const setCurrency = useSetCurrency();
    const currencyOptions = React.useMemo(() => CurrencyKey.map(key => ({ key, value: key, text: key })), []);

    // Contact information
    const togglePhone = () => {
        setPhoneVisible(!phoneVisible);
    };

    const activeProjectId = useActiveProjectId();

    const mailto = React.useMemo(() => 'mailto:kundencenter@dh-partner.com' + (
        !!activeProjectId ? `?subject=${encodeURIComponent(activeProjectId)}` : ''
    ), [activeProjectId]);

    const agbUrl = React.useMemo(() => countryIsAustria(user?.country)
        ? 'https://www.bluekit.at/fileadmin/Docs/AT/2018_AGB_DH_Oesterreich_GmbH.pdf'
        : 'https://www.bluekit.de/fileadmin/Docs/DE/AGB_DH_Deutschland_GmbH.PDF',
        [user?.country]
    );

    const contactUrl = React.useMemo(() => countryIsAustria(user?.country)
        ? 'https://www.bluekit.at/kontakt/'
        : 'https://www.bluekit.de/kontakt/',
        [user?.country]
    );

    const contactPhone = '+49 40 530039-0';

    const version = process.env.BUILD_SOURCEVERSION || '';

    // Events
    function onLanguageChange(_event, dp: DropdownProps) {
        if (!LanguageKey.includes(dp.value as any)) {
            return;
        }
        const newLang = dp.value as LanguageKey;
        setLanguage(newLang);
    }

    function onCurrencyChange(_event, dp: DropdownProps) {
        if (!currencyOptions.find(c => c.key === dp.value as any)) {
            return;
        }
        const newCurr = dp.value as string;
        setCurrency(newCurr);
    }

    function goto(path: string) {
        return (e: React.MouseEvent) => {
            e.preventDefault();
            historyPush(path);
        };
    }

    const onLogoutClick = React.useCallback(() => {
        resetAuthentication();
    }, [resetAuthentication]);


    return (
        <div className="layout-container layout-v1">
            <div className="wrapper">
                <div className="header menu flex-container flex-row flex-vertical-center">
                    <div className="logo">
                        <Image src={logoImage} height="45" alt="BlueKit - Logo"/>
                    </div>
                    <div className="title flex-grow">
                        <h1>{localize('header.title')}</h1>
                    </div>
                    {
                        canAccessConnect(user) && <>
                            <a href={"/"} className={classNames({active: isCalculatorPage})} onClick={goto('/')}>
                                <Icon name="calculator"/><br/>
                                {localize('header.menu.calc')}
                            </a>
                            <a href={"/simulator"} className={classNames({active: isSimulatorPage})} onClick={goto('/simulator')}>
                                <Icon name="world"/><br/>
                                {localize('header.menu.sim')}
                            </a>
                        </>
                    }
                    {
                        !!user && <>
                            <a onClick={onLogoutClick}>
                                <Icon name="log out"/><br/>
                                {localize('header.menu.logout')}
                            </a>
                        </>
                    }
                    <Select basic options={languageOptions} defaultValue={currentLanguage} onChange={onLanguageChange} className="language-select" />
                    {configProvider.enableCurrencySelect && (
                        <Select basic options={currencyOptions} defaultValue={currentCurrency} onChange={onCurrencyChange} className="currency-select" />
                    )}
                </div>

                <div className="ui main site-content">
                    {props.children}

                    <div className="contact-container">
                        <div className="contact-nav">
                            <a className="item" href={mailto} target="_blank" rel="noopener noreferrer">
                                <Icon name="envelope"/>
                            </a>
                            <span className="item" onClick={togglePhone}>
                                <span className={classNames('info', {active: phoneVisible})}>
                                    <span>{contactPhone}</span>
                                </span>
                                <Icon name="phone"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer">
                <div className="ui center aligned container">
                    <div className="ui horizontal inverted small divided link list">
                        <a href={contactUrl} className="item" target="_blank" rel="noopener noreferrer">
                            {localize('link.contact')}
                        </a>
                        <a href={agbUrl} className="item" target="_blank" rel="noopener noreferrer">
                            {localize('link.termsofservice')}
                        </a>
                        {version ? <div className="item">Version: {version.slice(0, 6)}</div> : null}
                    </div>
                </div>
            </div>

        </div>
    );
}


function useActiveProjectId(): string|null {
    return useSelector<IApplicationState, string>(s => projectApi.makeSelectBase('entity')(s)?.id);
}

function countryIsAustria(country: string): boolean {
    if (typeof country !== 'string' || !country.length) return false;

    country = country.toLowerCase();
    return country === 'at' || country === 'österreich';
}
