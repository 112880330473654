import { COUNTRY } from "../../project/lookup";
import { ICosts } from "../CalculationModels";
import { BasicInputsFormModel, CostCalculationFormModel } from "../models/FormModels";


const MONTAGE_UNAVAILABLE_DE_PREFIXES = ['00', '05', '43', '62'];

function isAvailableMontageLocation(country: string, montageBuildingLocation: string): boolean | null {
    switch (country?.toLowerCase()) {
        case COUNTRY.DE:
            if (!montageBuildingLocation) {
                return null;
            }
            return !MONTAGE_UNAVAILABLE_DE_PREFIXES.some(prefix => montageBuildingLocation.startsWith(prefix));
        default:
            return null;
    }
    
};

export function isMontagePossible(
    { country, projectType, shaftHeight }: Pick<BasicInputsFormModel, 'country' | 'projectType' | 'shaftHeight'>,
    { montageBuildingLocation }: Pick<CostCalculationFormModel, 'montageBuildingLocation'>,
    { montageCosts }: Pick<ICosts, 'montageCosts'>,
) {
    const montagePrice = montageCosts?.costs?.price;

    // Disqualified: MODERNIZED, shaft higher than 50m, montage cost higher than 2000€, montage not available at location
    return projectType !== 'MODERNIZED'
        && shaftHeight <= 50_000
        && montagePrice <= 2_000
        && isAvailableMontageLocation(country, montageBuildingLocation);
}
