import { LIFT_STATUS_DETECTION, MACHINE_ROOM, SYSTEM } from "../../project/lookup";
import { BasicInputsFormModel, ConfigurationInputsFormModel } from "../models/FormModels";


export function getApplicatableLiftStatusDetectionMethods(
    { shaftHeight, machineRoom }: Pick<BasicInputsFormModel, 'shaftHeight' | 'machineRoom'>,
    { system }: Pick<ConfigurationInputsFormModel, 'system'>
): LIFT_STATUS_DETECTION[] {
    let values = Object.values(LIFT_STATUS_DETECTION);

    if (system === SYSTEM.AIO_BASIC && getLSTRepeaterCount({ shaftHeight, machineRoom }) > 0) {
        // LST Repeaters currently not supported by AIO Basic/CPL-B; BLU-326
        values = values.filter(lst => lst !== LIFT_STATUS_DETECTION.LST_CO2_V3);
    }

    return values;
}

export function getLSTRepeaterCount(
    { shaftHeight, machineRoom }: Pick<BasicInputsFormModel, 'shaftHeight' | 'machineRoom'>
): number {
    const BASE_RANGE = 70_000; // LST's range to base/repeater is 70m
    const REPEATER_RANGE = 36_000; // Repeater's range to base/repeater is 36m
    const MR_CEILING_DISTANCE = 5_000; // If base is in machine room, a repeater must be no more than 5m below the ceiling

    const machineRoomPresent = machineRoom && machineRoom !== MACHINE_ROOM.NO;

    let effectiveHeight = shaftHeight - BASE_RANGE;
    let repeaterCount = 0;

    if (machineRoomPresent) {
        // If the base is in a machine room, one repeater must be in the shaft
        // no more than MR_CEILING_DISTANCE from the ceiling
        //
        // Add one repeater, ignore top MR_CEILING_DISTANCE of shaft height.
        effectiveHeight -= MR_CEILING_DISTANCE;
        repeaterCount += 1;
    }

    // One repeater each REPEATER_RANGE distance, round up, prevent negative value
    repeaterCount += Math.max(Math.ceil(effectiveHeight / REPEATER_RANGE), 0);

    return repeaterCount;
}
