import {createSelector} from "reselect";
import {IApplicationState} from "../../shared/ApplicationState";
import {syncedErrorsToList} from "../../shared/components/error-list";
import { INSTALLATION_POSITION } from "../project/lookup";

export const getCalculationState = (state: IApplicationState) => state.calculationState;
export const getActiveTabIndex = (state: IApplicationState) => getCalculationState(state)?.activeTabIndex;
export const getProductData = (state: IApplicationState) => state.project.productData;
export const getForm = (state: IApplicationState) => state.form;

// --------------------------------
export const isAllFormReady = createSelector(
    getForm,
    (forms) => Boolean(
        forms.basicInputsForm &&
        forms.configurationInputsForm &&
        forms.ventilationComponentsForm &&
        forms.costCalculationForm
    )
);

// -------------------------------
// Helpers
const selectedAIOBasicSet = (state: IApplicationState) => state.calculationState?.selectedAIOBasicSet;
const isWeatherProtectionNeeded = (state: IApplicationState) => state.form.basicInputsForm?.values.weathershelter;
const installationPosition = (state: IApplicationState) => state.form.basicInputsForm?.values.installationPosition;

// --------------------------------
// Is ... form valid
const isBasicInputsFormValid = (state: IApplicationState) => !state.form.basicInputsForm?.syncErrors;

const isConfigurationInputsFormValid = (state: IApplicationState) => {
    return !state.form?.configurationInputsForm?.syncErrors ||
        syncedErrorsToList(state.form.configurationInputsForm.syncErrors).length === 0;
};

const isVentilationComponentsFormValid = (state: IApplicationState) => !state.form.ventilationComponentsForm?.syncErrors;

export const isCostCalculationFormValid = (state: IApplicationState) => !state.form.costCalculationForm?.syncErrors;
// --------------------------------
// Can navigate to ...
export const canNavigateToConfigurationForm = createSelector(
    isBasicInputsFormValid,
    selectedAIOBasicSet,
    (isBasicInputsFormValid, selectedAIOBasicSet) => isBasicInputsFormValid && !selectedAIOBasicSet,
);

// Show ventilation form when:
// - No AIOBasicSet selected
// - AIOBasicSet selected and weather protection needed
//   - Except for vertical weather protection because there is only one option
export const canNavigateToVentilationForm = createSelector(
    isBasicInputsFormValid,
    selectedAIOBasicSet,
    isWeatherProtectionNeeded,
    installationPosition,
    (isBasicInputsFormValid, selectedAIOBasicSet, isWeatherProtectionNeeded, installationPosition) => (
        isBasicInputsFormValid && (
            !selectedAIOBasicSet ||
            (isWeatherProtectionNeeded && installationPosition !== INSTALLATION_POSITION.VERTICAL)
        )
    ),
);

export const canNavigateToCostCalculationForm = createSelector(
    isBasicInputsFormValid,
    isConfigurationInputsFormValid,
    isVentilationComponentsFormValid,
    selectedAIOBasicSet,
    (isBasicInputsFormValid, isConfigurationInputsFormValid, isVentilationComponentsFormValid, selectedAIOBasicSet) => (
        (isBasicInputsFormValid && isVentilationComponentsFormValid) &&
        // Manual configuration or AIO Basic Set
        (isConfigurationInputsFormValid || selectedAIOBasicSet != null)
    ),
);
