import { BUILDING_TYPE, COUNTRY, INSTALLATION_POSITION, LIFT_AREA_PERCENTAGE, LIFT_STATUS_DETECTION, MACHINE_ROOM, PROJECT_TYPE, SMOKE_DETECTION, SYSTEM } from '../../project/lookup';
import { PROJECT_STATE } from '../../project/ProjectModels';


export class BasicInputsFormModel {
    // AIO-Basic-Set
    /** AIO-Basic-Set: The selected set */
    aioBasicSet: string = null;
    /** AIO-Basic-Set Only: Number of 8-pin interface adapter selected */
    noOfInterfaceAdapters: number = null

    // Project & Building Information
    /**
     * Name of the project
     * NOTE: informational only, not used in business logic
     */
    projectName: string = null;
    /** Country of the building */
    country: COUNTRY | string = null;
    /**
     * Location of the building (post code)
     * NOTE: informational only, not used in business logic (except as default value for montageBuildingLocation)
     */
    buildingLocation: string = null;
    /** Usage type of the building */
    buildingType: BUILDING_TYPE = null;
    /** Project type (new construction or modernization) */
    projectType: PROJECT_TYPE = null;
    /** Planned date of project implementation */
    plannedImplementationDate: string = null;

    // Requirements
    /** Required installation position of vent (horizontal or vertical) */
    installationPosition: INSTALLATION_POSITION = null;
    /** Whether an NRWG is already installed */
    nrwgDelivered: boolean = false;
    /** Wheter a weather shelter element is required */
    weathershelter: boolean = false;
    /** Whether low energy consumption is required */
    lowEnergyStandard: boolean = false;
    /** Wheter abZ certification is required */
    abzRequired: boolean = false;
    /** Whether a quiet operation is required */
    quiet: boolean = false;
    /** Whether integration with a building or fire management system is required */
    systemIntegration: boolean = false;
    /** Whether an additional smoke detector at the shaft head is required */
    additionalSmokeDetector: boolean = false;
    /** Whether a smoke detector on the main evacuation level is required */
    mainLevelSmokeDetector: boolean = false;

    // Elevator Details
    /** Presence and position of machine room */
    machineRoom: MACHINE_ROOM = null;
    /** Number of elevators in the shaft */
    noOfElevators: number = null;
    /** Elevator shaft height in mm (integer) */
    shaftHeight: number = null;
    /** Elevator shaft width in mm (integer) */
    shaftWidth: number = null;
    /** Elevator shaft depth in mm (integer) */
    shaftDepth: number = null;
    /**
     * Elevator's maximum payload in kg
     * NOTE: informational only, not used in business logic
     */
    payload: number = null;
    /**
     * Elevator's serial number
     * NOTE: informational only, not used in business logic
     */
    liftNumber: string = null;

    // Ventilation Area

    /** The required ventilation area in mm^2 (integer)
     * @note either manually entered or calculated
     * @see `CalculationService/LiftArea:getTotalLiftArea()`
     */
    calculatedVentilationArea: number = null;
    /** The calculation mode for lift area */
    liftAreaPercentage: LIFT_AREA_PERCENTAGE = null;
    /** @deprecated @TODO: BLU-279: Remove */
    manualVentilationArea: boolean = false;
}

export class ConfigurationInputsFormModel {
    // Core Components
    /** The selected smoke detection method */
    smokeDetection: SMOKE_DETECTION = null;
    /** The selected system */
    system: SYSTEM = null;
    /** The selected lift status detection method */
    liftStatusDetection: LIFT_STATUS_DETECTION = null;

    // Calipers
    /** The number of required RT-45L-RJ (caliper with ventilation buttons) */
    'rt-45l-rj': number = 0;
    /** The number of required RT-45-RJ (caliper) */
    'rt-45-rj': number = 0;
    /** The number of required RJ-45 Splitters */
    'rj-45-splitter': number = 0;

    // Add-on Accessories
    /** The number of required vent control key switches */
    keySwitchLED: number = 0;
    /** Whether a timer module is required */
    ventilationTimer: boolean = false;
    /** Whether a thermostat module is required */
    additionalThermostat: boolean = false;
    /** Whether a humidity sensor is required */
    humiditySensor: boolean = false;
    /** Whether a siren is required */
    sirenWithKeySwitch: boolean = false;
    /** Whether an extra 8-pin interface adapter set is required */
    eightPinInterfaceAdapterSet: boolean = false;
}

export class VentilationComponentsFormModel {
    ventilationArea: number = 0;
    includeEinbauwinkel: boolean = false;
}

export class CostCalculationFormModel {
    // Montage / Installation
    /** Whether montage should be included */
    montageIncluded: boolean = false;
    /** The location of montage */
    montageBuildingLocation: string = null;

    // Custom Cable Quantities
    'rj-45-chf-a-4m-custom': number = undefined;
    'rj-45-chf-a-6m-custom': number = undefined;
    'rj-45-chf-a-12m-custom': number = undefined;
    'fm-2m-custom': number = undefined;

    // Communication
    requestType: string = null;
    remarks: string = null;
    projectState: string = PROJECT_STATE.NEW;
    orderNumber: string = null;
    offerNumber: string = null;
}

export const defaultFormValues = {
    basicInputsForm: {
        values: new BasicInputsFormModel(),
        hasErrors: false
    },
    configurationInputsForm: {
        values: new ConfigurationInputsFormModel(),
        hasErrors: false
    },
    ventilationComponentsForm: {
        values: new VentilationComponentsFormModel(),
        hasErrors: false
    },
    costCalculationForm: {
        values: new CostCalculationFormModel(),
        hasErrors: false
    }
};
