import { intersectionBy, uniq } from "lodash-es";

import { CHARACTERISTIC_KEY, PRODUCT_CATEGORY, PRODUCT_GROUP, PRODUCT_TYPE } from '../../product/lookup';
import { ICharacteristic, IProduct, IProductData } from "../../product/types";
import { INSTALLATION_POSITION, SYSTEM } from '../../project/lookup';

import { BasicInputsFormModel, VentilationComponentsFormModel } from "../models/FormModels";
import { getAllProductsByCategories, getRecommendedProductId } from "../CalculationService";

import { getAIOBasicSetVent } from "./AIOBasicSet";
import { getSystem } from "./System";


/** Get all product group IDs for which all characteristic `keys` are assigned */
function getGroupsByAllCharacteristics(characteristicsData: ICharacteristic[], keys: CHARACTERISTIC_KEY[]): PRODUCT_GROUP[] {
    if (!keys || !keys.length) {
        return [];
    }

    const [startKey, ...otherKeys] = keys;

    // Get candidates by querying them with the first CHARACTERISTIC_KEY
    const candidateGroupIds = characteristicsData
        .filter(c => c.characteristicKey === startKey)
        .map(c => c.productGroupId);

    // Only retain those candidates where every `otherKeys` is included in the candidate's characteristics
    return candidateGroupIds.filter(groupId => {
        const allGroupChars = characteristicsData.filter(c => c.productGroupId === groupId).map(c => c.characteristicKey);
        return otherKeys.every(k => allGroupChars.includes(k));
    });
}

function filterComponentsByAllCharacteristics(characteristicsData: ICharacteristic[], components: IProduct[], keys: CHARACTERISTIC_KEY[]): IProduct[] {
    const productGroupsIds = getGroupsByAllCharacteristics(characteristicsData, keys);
    return components.filter(comp => productGroupsIds.includes(comp.productGroupId));
}

export function getAvailableVentilationComponentsForAIOBasicSet(projectType: string, totalLiftArea: number, installationPosition: string, productData: IProductData): IAvailableVentilationComponents {
    const vent = getAIOBasicSetVent(projectType, totalLiftArea);
    let weatherProtectionComponents = [];

    if (vent) {
        const aioBasicSetVentilationArea = vent.ventilationArea;
        const potentialWPComponents = productData.products.filter(p => p.productTypeId === PRODUCT_TYPE.WEATHERPROTECTION && p.ventilationArea === aioBasicSetVentilationArea);

        if (installationPosition === INSTALLATION_POSITION.VERTICAL) {
            weatherProtectionComponents = potentialWPComponents.filter(product => product.productGroupId === PRODUCT_GROUP.ALAS);
        } else {
            weatherProtectionComponents = potentialWPComponents.filter(product => product.productGroupId !== PRODUCT_GROUP.ALAS);
        }
    }

    return {
        ventComponents: [],
        weatherProtectionComponents,
    };
}

export type ProductSelector = IProduct & { isRecommended?: boolean };

interface IAvailableVentilationComponents {
    ventComponents: ProductSelector[];
    weatherProtectionComponents: ProductSelector[];
}

function getAvailableVentilationComponentsForManualConfig(
    minArea: number, maxArea: number,
    basicInputFormValues: Pick<BasicInputsFormModel, 'projectType' | 'installationPosition' | 'abzRequired' | 'lowEnergyStandard' | 'quiet' | 'weathershelter'>,
    system: SYSTEM,
    productData: IProductData): IAvailableVentilationComponents {
    const { projectType, installationPosition, abzRequired, lowEnergyStandard, quiet, weathershelter } = basicInputFormValues;

    let ventComponents = getAllProductsByCategories([PRODUCT_CATEGORY.VENTCOMP], productData.products, productData.productGroups);

    // Filter by ventilation area
    ventComponents = ventComponents.filter(
        product => product.ventilationArea >= minArea && product.ventilationArea < maxArea
    );



    // Filter by installation position 'H'==horizontal or 'V'==vertikal
    const installationPositionKey = installationPosition === INSTALLATION_POSITION.HORIZONTAL
        ? CHARACTERISTIC_KEY.HORIZONTAL_INSTALLATION
        : CHARACTERISTIC_KEY.VERTICAL_INSTALLATION;

    const filterCharacteristics: CHARACTERISTIC_KEY[] = [installationPositionKey];

    // "System mit abZ ist verlangt" selected
    if (abzRequired) {
        filterCharacteristics.push(CHARACTERISTIC_KEY.abZ);
    }

    // "Niedrigenergiestandard" selected
    if (lowEnergyStandard) {
        filterCharacteristics.push(CHARACTERISTIC_KEY.LOWENERGY);
    }

    // "Geräuscharmer Antrieb" selected
    if (quiet) {
        filterCharacteristics.push(CHARACTERISTIC_KEY.QUIET);
    }

    // AIO Basic system does not support battery packs; motors need a builtin self-open spring
    if (system === SYSTEM.AIO_BASIC) {
        filterCharacteristics.push(CHARACTERISTIC_KEY.WITH_SPRING_RETURN);
    }

    // Filter by project type "MODERNIZED" or "NEWBUILDING"
    const projectTypeKey = (projectType + '_INSTALLATION') as CHARACTERISTIC_KEY;

    filterCharacteristics.push(projectTypeKey);


    ventComponents = filterComponentsByAllCharacteristics(productData.characteristics, ventComponents, filterCharacteristics);

    // when "Wetterschutz" selected and installation position 'Vertikal'
    if (weathershelter && installationPosition === INSTALLATION_POSITION.VERTICAL) {
        // don't show S9, Tairmo
        ventComponents = ventComponents.filter(component => ![PRODUCT_GROUP.THERMOS9, PRODUCT_GROUP.THERMOTAIRMO].includes(component.productGroupId));
    }

    // check if vent components are compatible with weather protection
    const groupsOfVentComponents = uniq(ventComponents.map(p => p.productGroupId));
    const weatherProtectionCompatibleGroups = getGroupsByAllCharacteristics(productData.characteristics, [CHARACTERISTIC_KEY.WEATHERPROTECTION_COMPATIBLE]);
    const hasCompatibleGroups = intersectionBy(groupsOfVentComponents, weatherProtectionCompatibleGroups).length > 0;

    let weatherProtectionComponents: ProductSelector[] = [];

    // Wetterschutzhauben hinzufügen, wenn 'Wetterschutz' aktiv und horizontale Einbaulage (vertikal geht nicht)
    if (weathershelter && installationPosition === INSTALLATION_POSITION.HORIZONTAL && hasCompatibleGroups) {
        weatherProtectionComponents = productData.products.filter(product => (
            product.productTypeId === PRODUCT_TYPE.WEATHERPROTECTION &&
            product.productGroupId !== PRODUCT_GROUP.ALAS &&
            product.ventilationArea >= minArea &&
            product.ventilationArea < maxArea
        ));
    }

    const recommendedProductId = getRecommendedProductId(ventComponents, productData.productGroups);
    ventComponents.forEach(c => {
        c.isRecommended = recommendedProductId && c.id === recommendedProductId;
    });

    return {
        ventComponents,
        weatherProtectionComponents
    };
}

export function getAvailableVentilationComponents(
    totalLiftArea: number,
    minArea: number,
    maxArea: number,
    basicValues:
        Parameters<typeof getSystem>[0] &
        Parameters<typeof getAvailableVentilationComponentsForManualConfig>[2] &
        Pick<BasicInputsFormModel, 'aioBasicSet' | 'projectType' | 'installationPosition'>,
    configValues: Parameters<typeof getSystem>[1],
    productData: IProductData
): IAvailableVentilationComponents {

    if (basicValues.aioBasicSet) {
        const { projectType, installationPosition } = basicValues;
        return getAvailableVentilationComponentsForAIOBasicSet(projectType, totalLiftArea, installationPosition, productData);
    } // else {

    const system = getSystem(basicValues, configValues);

    return getAvailableVentilationComponentsForManualConfig(minArea, maxArea, basicValues, system, productData);
}

// -------

export const COMPONENT_GROUP_IDS_WITH_EINBAUWINKEL = [PRODUCT_GROUP.JK190DB, PRODUCT_GROUP.JK190];

export function getQuantitiesFromVentilationComponentsFormModel(ventilationComponentsValues: VentilationComponentsFormModel): [string, number][] {
    if (!ventilationComponentsValues) {
        return [];
    }

    const PRODUCT_QUANTITY_PREFIX = "quantity-";
    return Object.keys(ventilationComponentsValues)
        .filter(x => x.startsWith(PRODUCT_QUANTITY_PREFIX))
        .map<[string, number]>(key => [key.substring(PRODUCT_QUANTITY_PREFIX.length), Number(ventilationComponentsValues[key])]);
}
