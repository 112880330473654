import { AnyAction, Reducer } from 'redux';
import { FormState, reducer as formReducer } from 'redux-form'
import update from "immutability-helper";

import { ICalculationFormsState } from '../calculation/CalculationModels';

import { ReduxFormKeys } from "./ReduxFormKeys";
import { FormActionTypes, KnownFormActions } from "./FormActions";


function customFormReducerForForm(form: ReduxFormKeys): Reducer<FormState, KnownFormActions> {
    return (state, action) => {
        if (action.payload?.form !== form) return state;

        switch (action.type) {
            case FormActionTypes.RESET_FIELDS:
                return update(state, {
                    values: { $unset: action.payload.fields },
                });
            default: return state
        }
    }
}

export const customFormReducer = formReducer.plugin({
    [ReduxFormKeys.basicInputsForm]: customFormReducerForForm(ReduxFormKeys.basicInputsForm),
    [ReduxFormKeys.configurationInputsForm]: customFormReducerForForm(ReduxFormKeys.configurationInputsForm),
    [ReduxFormKeys.ventilationComponentsForm]: customFormReducerForForm(ReduxFormKeys.ventilationComponentsForm),
    [ReduxFormKeys.costCalculationForm]: customFormReducerForForm(ReduxFormKeys.costCalculationForm),
}) as Reducer<Record<ReduxFormKeys, FormState>, AnyAction> as Reducer<ICalculationFormsState, AnyAction>;
