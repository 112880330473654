import { PRODUCT_GROUP } from "./features/product/lookup";
import { CurrencyKey, LanguageKey } from "./localization/LocalizationService";

interface VentcompPlanningFileDownload {
    pLines?: number[];
    dTypes?: number[];
}

function getBackendURL() {
    // Frontend is served by Backend, so the Backend is at the same origin
    return window.location.origin;
}

// @TODO: Fetch these from the backend
const simDomains = {
    ".stage.mchst.net": "https://bksim.stage.mchst.net",
    ".prod1.mchst.net": "https://bksim.prod1.mchst.net",
    "localhost": "http://localhost:3002",
};
function getSimulatorURL() {
    const hostname = Object.entries(simDomains).find(([k, _]) => window.location.hostname.endsWith(k))?.[1] ?? "https://simulator.bluekit.de";
    return `${hostname}/assets/framed.html?t=${Date.now()}`;
}

const ssoDomains = {
    // ".stage.mchst.net": "https://sso.stage.mchst.net/auth/",
    ".prod1.mchst.net": "https://sso.prod1.mchst.net/auth/",
    "localhost": "http://localhost:8180/auth/",
};
function getKeycloakURL() {
    return Object.entries(ssoDomains).find(([k, _]) => window.location.hostname.endsWith(k))?.[1] ?? "https://sso.dh-partner.com/auth/";
}

class ConfigProvider {
    // Localization
    public defaultLanguage: LanguageKey = 'de';
    public manuallySetLanguageLocalStorageKey = 'bk.connect.manuallySetLanguage';
    public manuallySetLanguageLocalStorageTTL = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
    public productDataLanguages: LanguageKey[] = ['de', 'en'];

    public defaultCurrency: CurrencyKey = 'EUR';
    public enableCurrencySelect = false;
    public manuallySetCurrencyLocalStorageKey = 'bk.connect.manuallySetCurrency';
    public manuallySetCurrencyLocalStorageTTL = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

    // Feature Flags
    public disableKeyCloak: boolean = false;
    public enableE2EToken: boolean = false;

    public disableCtrlKeyFastDelete: boolean = true;

    // Endpoint URLs
    private backendUrl: string = getBackendURL();
    public apiUrl = {
        'settings-v1':       this.backendUrl + '/api/settings',
        'user-v1':           this.backendUrl + '/api/user',
        'product-v1':        this.backendUrl + '/api/product',
        'project-v1':        this.backendUrl + '/api/project-v1',
        'project-export-v1': this.backendUrl + '/api/project/export',
        'file-v1':           this.backendUrl + '/api/file',
        'calculation-v1':    this.backendUrl + '/api/calculation-v1',
        'reporting-v1':      this.backendUrl + '/api/reporting-v1',
    };

    // External Links
    public keycloakUrl: string = getKeycloakURL();
    public bkSimulatorUrl: string = getSimulatorURL();
    public bkDownloadsUrl: string = "https://downloads.bluekit.eu/";

    private readonly ventcompPlanningFileCommon = { dTypes: [90159] };
    public readonly ventcompPlanningFileDownloads: Partial<Record<PRODUCT_GROUP, VentcompPlanningFileDownload>> = {
        [PRODUCT_GROUP.JK180]:        { ...this.ventcompPlanningFileCommon, pLines: [90207] },
        [PRODUCT_GROUP.JK180DB]:      { ...this.ventcompPlanningFileCommon, pLines: [90207] },
        [PRODUCT_GROUP.JK190]:        { ...this.ventcompPlanningFileCommon, pLines: [90207] },
        [PRODUCT_GROUP.JK190DB]:      { ...this.ventcompPlanningFileCommon, pLines: [90207] },
        [PRODUCT_GROUP.NKSL]:      { ...this.ventcompPlanningFileCommon, pLines: [101827] },
        [PRODUCT_GROUP.SLIDEFLAP]:    { ...this.ventcompPlanningFileCommon, pLines: [90209] },
        [PRODUCT_GROUP.THERMOFLAP]:   { ...this.ventcompPlanningFileCommon, pLines: [90211, 90213] },
        [PRODUCT_GROUP.THERMOS9]:     { ...this.ventcompPlanningFileCommon, pLines: [90211] },
        [PRODUCT_GROUP.THERMOTAIRMO]: { ...this.ventcompPlanningFileCommon, pLines: [90211] },
        [PRODUCT_GROUP.DACHHAU]:      { ...this.ventcompPlanningFileCommon, pLines: [90203] }, // HVC
        [PRODUCT_GROUP.LAMHAU]:       { ...this.ventcompPlanningFileCommon, pLines: [90205] }, // HVL
        [PRODUCT_GROUP.ALAS]:         { ...this.ventcompPlanningFileCommon, pLines: [90201] },
    };
}

export const configProvider = new ConfigProvider();
