import * as React from 'react';

import { configProvider } from '../../configProvider';
import { useFetchOnce } from '../../shared/useFetchOnce.hook';
import { jwtToken } from '../auth/AuthService';

import { SettingsApiV1Client } from './SettingsApiV1Client';
import { ISettings } from './SettingsModels';


interface SettingsContextType {
    settings: ISettings;
    fetchSettings: () => Promise<void>;
}
const SettingsContext = React.createContext<SettingsContextType>(null);

export const SettingsContextProvider: React.FC<{}> = ({ children }) => {
    const client = React.useMemo(() => new SettingsApiV1Client(configProvider.apiUrl['settings-v1'], jwtToken), []);
    const settingsGetter = React.useCallback(() => client.getSettings(), [client]);

    const {
        value: settings,
        fetch: fetchSettings,
    } = useFetchOnce(settingsGetter);

    const value = React.useMemo(() => ({
        settings,
        fetchSettings,
    }), [
        settings,
        fetchSettings,
    ]);

    return React.createElement(SettingsContext.Provider, { value }, children);
}

export function useSettings() {
    return React.useContext(SettingsContext);
}
