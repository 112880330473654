import { ICompany } from "../project/ProjectModels";
import { ICharacteristic, IProduct, IProductData, IProductGroup } from "../product/types";

import { JsonRESTApiClient } from "../api/JsonRESTApiClient";
import { isArrayOf, isObjectWithProperties } from "../api/simple-validators";


/** @see `DH.BlueKitConnect.Backend.Features.Product.ProductApiV1Controller` */
export class ProductApiV1Client extends JsonRESTApiClient {

    /// API Endpoints

    public async getProductData(companyId: ICompany['id']): Promise<IProductData> {
        return await this._fetchJson(
            this._getUrl(['product-data']) + `?companyId=${companyId}`,
            {
                method: 'GET',
                headers: await this._getHeaders(),
            },
            ProductApiV1Client.ValidateIProductData
        );
    }

    /// Validators for API types

    private static ValidateIProductData(v: unknown): v is IProductData {
        return isObjectWithProperties('products', 'productGroups', 'characteristics')(v) &&
            isArrayOf(ProductApiV1Client.ValidateIProduct)(v.products) &&
            isArrayOf(ProductApiV1Client.ValidateIProductGroup)(v.productGroups) &&
            isArrayOf(ProductApiV1Client.ValidateICharacteristic)(v.characteristics);
    }

    private static ValidateIProduct(v: unknown): v is IProduct {
        return isObjectWithProperties('id', 'description', 'productGroupId', 'productTypeId')(v) &&
            typeof v.id === 'string' &&
            ProductApiV1Client.ValidateMultilanguageDescription(v.description) &&
            typeof v.productGroupId === 'string' &&
            typeof v.productTypeId === 'string';
    }

    private static ValidateIProductGroup(v: unknown): v is IProductGroup {
        return isObjectWithProperties('id', 'description', 'productCategoryId')(v) &&
            typeof v.id === 'string' &&
            ProductApiV1Client.ValidateMultilanguageDescription(v.description) &&
            typeof v.productCategoryId === 'string';
    }

    private static ValidateICharacteristic(v: unknown): v is ICharacteristic {
        return isObjectWithProperties('characteristicKey', 'productGroupId')(v) &&
            typeof v.characteristicKey === 'string' &&
            typeof v.productGroupId === 'string';
    }

    private static ValidateMultilanguageDescription(v: unknown): v is IProduct['description'] {
        return isObjectWithProperties('de', 'en')(v) &&
            typeof v.de === 'string' &&
            typeof v.en === 'string';
    }
}
