import React, { Fragment, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { getFormValues } from "redux-form";
import { useDispatch, useSelector } from "react-redux";

import { useLocalization } from '../../../../localization/hook';

import { ReduxFormKeys } from '../../../form/ReduxFormKeys';
import { projectApi, projectApiActionCreator } from '../../ProjectApi.redux';
import { PROJECT_STATE } from '../../../project/ProjectModels';
import { isAdmin } from '../../../auth/PermissionService';
import { useUser } from '../../../auth/User.hook';

import { COST_CALCULATION_FORM_VALUES_SELECTOR } from '../02-ConfigurationInputs/ConfigurationInputsForm';


export const SaveProjectModal: React.FC<any> = (props: any) => {
    const { disabled } = props;

    const dispatch = useDispatch();
    const { user } = useUser();
    const project = useSelector(projectApi.makeSelectBase('entity'));
    const projectIsUpdating = useSelector(projectApi.makeSelectBase('isUpdating'));
    const costCalculationFormValues = useSelector(getFormValues(ReduxFormKeys.costCalculationForm) as COST_CALCULATION_FORM_VALUES_SELECTOR);

    const [isOpen, setOpen] = useState(false);

    const { localize } = useLocalization();

    const init = () => {
        const state = costCalculationFormValues.projectState as PROJECT_STATE;
        if (isAdmin(user) && (state === PROJECT_STATE.OFFER_CREATED || state === PROJECT_STATE.ORDER_RECEIVED || state === PROJECT_STATE.MESSAGE_FROM_DH)) {
            setOpen(true);
        } else {
            save();
        }
    }

    const save = () => {
        dispatch(projectApiActionCreator.updateRequest(project.id, costCalculationFormValues.projectState as PROJECT_STATE, false));
        setOpen(false);
    }

    const saveAndInformCustomer = () => {
        dispatch(projectApiActionCreator.updateRequest(project.id, costCalculationFormValues.projectState as PROJECT_STATE, true));
        setOpen(false);
    }

    return (
        <Fragment>
            <Modal
                trigger={
                    <Button secondary fluid disabled={disabled} loading={projectIsUpdating} onClick={init}>
                        {localize('projectManage.button.save')}
                    </Button>
                }
                open={isOpen}
                onClose={() => setOpen(false)}
            >
                <Modal.Header>
                    <div className="title">{localize('projectManage.button.save')}</div>
                    <div className="close-button" onClick={() => setOpen(false)}><i aria-hidden="true" className="close icon"></i></div>
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p>{localize('projectManage.modal.save.emailCustomer?')}</p>
                        <div className="btn-group centered">
                            <Button primary onClick={saveAndInformCustomer}>{localize('Yes')}</Button>
                            <Button secondary onClick={save}>{localize('No')}</Button>
                        </div>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </Fragment>
    );
}
