import React, { Fragment } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

export interface IImageModalProps {
    imageUrl: string;
    title: string;
    thumbnailClassName?: string;
    width?: number;
    height?: number;
    showThumbnail?: boolean;
}

class ImageModal extends React.Component<IImageModalProps, any> {
    state = {open: false};

    open = () => this.setState({open: true});
    close = () => this.setState({open: false});

    render() {
        const {imageUrl, title, thumbnailClassName, width, height, showThumbnail} = this.props;

        return (
            <Modal className="ImageModal"
                trigger={
                    <Button onClick={() => this.open()} className={thumbnailClassName}>
                        {!showThumbnail &&
                        <Icon className={"image outline"}/>
                        }
                        {showThumbnail &&
                        <img src={imageUrl} width={width} height={height}/>
                        }
                    </Button>
                }
                open={this.state.open}
                onClose={() => this.close()}
            >
                <Modal.Header>
                    <div className="title">{title}</div>
                    <div className="close-button" onClick={() => this.close()}><i aria-hidden="true" className="close icon"></i></div>
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <img style={{ maxHeight: '70vh', maxWidth: '80vw'}} src={imageUrl}/>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        );
    }
}

export default ImageModal;
