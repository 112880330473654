import React from 'react';

export const Spinner = ({text = 'wird geladen...'}) => (
    <div className="spinner ui segment">
        <p></p>
        <div className="ui active inverted dimmer">
            <div className="ui big text loader">{text}</div>
        </div>
    </div>
);
