import {PRODUCT_GROUP, SET_PRODUCT_KEY } from '../../product/lookup';
import { IProduct } from "../../product/types";
import { INSTALLATION_POSITION, PROJECT_TYPE, MACHINE_ROOM } from '../../project/lookup';

import { ISelectedProduct } from "../CalculationModels";
import { BasicInputsFormModel } from "../models/FormModels";
import { filterProductsByGroup } from "../CalculationService";

import { aioBasicSystemOrPackageNotAvailable } from "./System";

/** AIO Basic Sets with PD can support machineRoom by extending the shaft line by 12m into the MR; BLU-244 */
const AIO_BASIC_SET_PD_MACHINE_ROOM_LINE_LENGTH = 12_000;

const AIO_BASIC_SET_RECOMMENDATIONS = [
    { key: SET_PRODUCT_KEY.PD_12,    minLineLength:       0, maxLineLength:   12_000, machineRoomSupport: true },
    { key: SET_PRODUCT_KEY.SDLF_50,  minShaftHeight:      0, maxShaftHeight:  50_000 },
    { key: SET_PRODUCT_KEY.SDLF_100, minShaftHeight: 50_000, maxShaftHeight: 100_000 },
    { key: SET_PRODUCT_KEY.PD_24,    minLineLength:  12_000, maxLineLength:   24_000, machineRoomSupport: true },
    { key: SET_PRODUCT_KEY.PD_36,    minLineLength:  24_000, maxLineLength:   36_000, machineRoomSupport: true },
    { key: SET_PRODUCT_KEY.PD_48,    minLineLength:  36_000, maxLineLength:   48_000, machineRoomSupport: true },
];

/** 
 * Get the shaft height limits for an AIO Basic Set.
 * 
 * If a Set has `machineRoomSupport` and a `machineRoom` is present,
 * the limits are reduced by `AIO_BASIC_SET_PD_MACHINE_ROOM_LINE_LENGTH`
 * 
 * @returns [min, max] The minimum and maximum (both inclusive) shaft height limits (in `mm`)
 */
export function getAIOBasicSetShaftHeightLimits(key: SET_PRODUCT_KEY, machineRoom: MACHINE_ROOM): [number, number] {
    const opts = AIO_BASIC_SET_RECOMMENDATIONS.find(o => o.key === key);
    if (!opts) return null;

    if (!opts.machineRoomSupport && machineRoom !== MACHINE_ROOM.NO) return null;

    const minShaftHeight = opts.minShaftHeight ?? 0;
    const maxShaftHeight = opts.maxShaftHeight ?? Infinity;

    let minLineLength = opts.minLineLength ?? 0;
    let maxLineLength = opts.maxLineLength ?? Infinity;

    if (machineRoom !== MACHINE_ROOM.NO) {
        minLineLength -= AIO_BASIC_SET_PD_MACHINE_ROOM_LINE_LENGTH;
        maxLineLength -= AIO_BASIC_SET_PD_MACHINE_ROOM_LINE_LENGTH;
    }

    return [Math.max(minShaftHeight, minLineLength), Math.min(maxShaftHeight, maxLineLength)];
}

/** Get the applicable AIO Basic Sets for the given form values (requirements) */
export function getApplicableAIOBasicSets(
    basicInputFormValues: Pick<BasicInputsFormModel,
        'abzRequired' | 'nrwgDelivered' | 'lowEnergyStandard' |
        'quiet' | 'noOfElevators' | 'additionalSmokeDetector' |
        'installationPosition' | 'mainLevelSmokeDetector'
        | 'shaftHeight' | 'machineRoom'
    >,
    productsData: IProduct[]
) {
    const {
        installationPosition,
        mainLevelSmokeDetector,
        shaftHeight,
        machineRoom,
    } = basicInputFormValues;

    if (mainLevelSmokeDetector || installationPosition == null) {
        return [];
    }

    if (aioBasicSystemOrPackageNotAvailable(basicInputFormValues)) {
        return [];
    }

    const setProducts = filterProductsByGroup(PRODUCT_GROUP.SETAIOB, productsData);

    const avaiableProducts = setProducts.filter(product => {
        const productKey = product.productKey as unknown as SET_PRODUCT_KEY;

        const limits = getAIOBasicSetShaftHeightLimits(productKey, machineRoom);
        if (!limits) return false;
        
        if (shaftHeight <= limits[0]) return false;
        if (shaftHeight >  limits[1]) return false;

        return true;
    });

    return avaiableProducts;
}

/** Return type of `getAIOBasicSetVent()` */
interface IAIOBasicSetVent {
    productKey: string;
    name: string;
    ventilationArea: number;
}

/** Get the auto-selected ventilation component for AIO Basic Sets */
export function getAIOBasicSetVent(projectType: string, totalLiftArea: number): IAIOBasicSetVent | null {
    const byVentilationAreaAsc = (a: IAIOBasicSetVent, b: IAIOBasicSetVent) => a.ventilationArea - b.ventilationArea;

    // Ventilation components for NEWBUILDING and MODERNIZED
    // Ensure these are sorted by ventilationArea ascending
    // so that the first match is the smallest possible component satisfying the given lift area
    const ventComponentsNewbuilding: IAIOBasicSetVent[] = [
        {
            productKey: 'jk-180-360x360',
            name: 'JK-180-HV 360x360',
            ventilationArea: 104000,
        },
        {
            productKey: 'jk-180-470x360',
            name: 'JK-180-HV 470x360',
            ventilationArea: 130000,
        },
        {
            productKey: 'jk-180-530x530',
            name: 'JK-180-HV 530x530',
            ventilationArea: 231000,
        },
        {
            productKey: 'jk-180-700x700',
            name: 'JK-180-HV 700x700',
            ventilationArea: 406000,
        },
        {
            productKey: 'jk-180-860x860',
            name: 'JK-180-HV 860x860',
            ventilationArea: 624000,
        },
        {
            productKey: 'jk-180-1030x1030',
            name: 'JK-180-HV 1030x1030',
            ventilationArea: 896000,
        },
        {
            productKey: 'jk-180-1200x1200',
            name: 'JK-180-HV 1200x1200',
            ventilationArea: 1218000,
        },
    ].sort(byVentilationAreaAsc);
    const ventComponentsModernized: IAIOBasicSetVent[] = [
        {
            productKey: 'jk-190-360x360',
            name: 'JK-190 360x360',
            ventilationArea: 104000,
        },
        {
            productKey: 'jk-190-470x360',
            name: 'JK-190 470x360',
            ventilationArea: 130000,
        },
        {
            productKey: 'jk-190-530x530',
            name: 'JK-190 530x530',
            ventilationArea: 231000,
        },
        {
            productKey: 'jk-190-700x700',
            name: 'JK-190 700x700',
            ventilationArea: 406000,
        },
        {
            productKey: 'jk-190-860x860',
            name: 'JK-190 860x860',
            ventilationArea: 624000,
        },
        {
            productKey: 'jk-190-1030x1030',
            name: 'JK-190 1030x1030',
            ventilationArea: 896000,
        },
        {
            productKey: 'jk-190-1200x1200',
            name: 'JK-190 1200x1200',
            ventilationArea: 1219000,
        },
    ].sort(byVentilationAreaAsc);

    const ventComponents = projectType === PROJECT_TYPE.MODERNIZED ? ventComponentsModernized : ventComponentsNewbuilding;

    // Return first match (smallest component with sufficient ventilation area)
    return ventComponents.find(component => totalLiftArea <= component.ventilationArea) || null;
}

// @TODO: Check the purpose of this condition
export function getAIOBasicSetRequiresVentComponentSelection({ weathershelter, installationPosition }: Pick<BasicInputsFormModel, "weathershelter" | "installationPosition">) {
    return weathershelter && installationPosition !== INSTALLATION_POSITION.VERTICAL;
}

// -----

/** Get the `ISelectedProduct` for a selected AIO Basic Set */
export function getSelectedAIOBasicSet({ aioBasicSet: setId }: Partial<Pick<BasicInputsFormModel, 'aioBasicSet'>>, products: IProduct[]): ISelectedProduct {
    const product = setId != null
        ? products.find(p => p.id === setId)
        : null;

    if (!product) {
        return null;
    }

    return {
        id: setId,
        quantity: 1,
        productKey: product.productKey,
        description: product.description,
        dhNo: product.dhNo,
        productTypeId: product.productTypeId,
        productGroupId: product.productGroupId,
    };
}
