import moment from "moment";

import { JsonRESTApiClient } from "../api/JsonRESTApiClient";
import { getFormValuesDictionaryFromForms, getProjectProductsFromForms } from "../calculation/CalculationService";
import { IProject } from "../project/ProjectModels";
import { ICalculationFormsState } from "../calculation/CalculationModels";
import { ICalculationState } from "../calculation/CalculationReducer";


const PROJECT_EXPORT_FILE_ENDING = {
    'pdf': 'pdf',
    'xlsx': 'xlsx',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
type PROJECT_EXPORT_FILE_ENDING = keyof typeof PROJECT_EXPORT_FILE_ENDING;

/** @see `DH.BlueKitConnect.Backend.Features.Project.Export.ExportController` */
export class ProjectExportApiV1Client extends JsonRESTApiClient {

    /// API Endpoints

    public async download(
        format: PROJECT_EXPORT_FILE_ENDING,
        project: IProject,
        form: ICalculationFormsState,
        calculationState: ICalculationState,
        language: string
    ): Promise<void> {
        const allProducts = getProjectProductsFromForms(form, project.id, calculationState);

        const requestObj = {
            id: project.id,
            products: allProducts,
            formValues: getFormValuesDictionaryFromForms(form),
            selectedProducts: calculationState.selectedProducts,
            selectedAIOBasicSet: calculationState.selectedAIOBasicSet,
            selectedNonStandardComponents: calculationState.nonStandardComponents,
            owningCompanyId: project.owningCompanyId,
        };

        const response = await this._fetch(
            this._getUrl([format], [['lang', language]]), {
                method: 'POST',
                body: JSON.stringify(requestObj),
                headers: await this._getHeaders(),
            },
        );

        const LastReportingAtHeader = 'X-LastReportingAt';
        let projectLastReportingAt: string | null = null
        if (response.headers.has(LastReportingAtHeader)) {
            const header = response.headers.get(LastReportingAtHeader);
            if (header != null && header.length > 0) {
                const date = moment(header);
                if (date.isValid()) {
                    projectLastReportingAt = date.format("DD.MM.YYYY");
                }
            }
        }

        const blob: Blob = await response.blob(); // Get the response body blob
        const lastReportingString = !!projectLastReportingAt ? "_" + projectLastReportingAt : ""
        const filename = `Kostenkalkulation_${project.name}${lastReportingString}.${PROJECT_EXPORT_FILE_ENDING[format]}`;

        downloadBlob(blob, filename); // "Download" the file to the user's device
    }
}

/** Downloads a the `blob` as a file with `fileName`
 * Creates, clicks, then removes an `<a href="blob:..." download="...">` element
 */
function downloadBlob(blob: Blob, fileName: string): void {
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.setAttribute('data-cy', 'file-download');

    document.body.appendChild(link);

    link.click();

    if ((window as any).Cypress) {
        // Do not attempt to actually download the file in test.
        // Just leave the anchor in there. Ensure your code doesn't
        // automatically remove it either.
        console.log("Leave the href here for cypress.");
        return;
    }

    link.parentNode.removeChild(link);
}
