import React, { useEffect, useState } from 'react';
import { Input } from "antd";
import {InputFieldProps} from "./Input.type";


function forceFormat(val: string) {
    // Force only digits
    const invalidChars = /[^\d]/;
    val = val.replace(invalidChars, '');

    return val;
}

export const InputForInteger: React.FC<InputFieldProps> = ({ input, className, label, content }) => {
    const [value, setValue] = useState(input.value);
    const [parsedValue, setParsedValue] = useState(null);

    useEffect(() => {
        if (input.value !== parsedValue) {
            setValue(input.value);
            updateParsedValue(input.value.toString());
        }
    }, [input.value]);

    const updateParsedValue = (val: string) => {
        val = val.replace(',', '.');

        if (val.trim().length === 0) {
            val = null;
        }

        const parsedVal = /^\d+$/.test(val) ? parseInt(val) : val;
        setParsedValue(parsedVal);
    };

    return (
        <div className={["input-for-integer", className].join(" ")}>
            {label && <label>{label}</label>}
            <Input
                className="input-for-integer"
                name={input.name}
                addonAfter={content}
                value={value}

                onFocus={input.onFocus}
                onBlur={() => input.onBlur(parsedValue)}
                onChange={(e) => {
                    const val = forceFormat(e.target.value);
                    setValue(val);
                    updateParsedValue(val);
                    input.onChange(val);
                }}
            />
        </div>
    );
};
