import { all } from 'redux-saga/effects';

import CalculationSaga from '../features/calculation/CalculationSaga';
import { projectApi } from '../features/calculation/ProjectApi.redux';


export default function* rootSaga(dispatch: any) {
    return yield all([
        CalculationSaga(dispatch),
        projectApi.saga(),
    ]);
}
