import * as React from 'react';
import {Field} from 'redux-form';
import {Icon, Popup, Table, TableProps} from 'semantic-ui-react';

import ImageModal from "../../../../shared/components/ImageModal";
import {nl2br} from "../../../../localization/LocalizationService";
import { useLocalization } from '../../../../localization/hook';

import { useFormUtility, FieldName, FieldOptions } from '../../FormUtility';
import {ConfigurationInputsFormModel} from "../../models/FormModels";


export type AccessoriesTableProps = TableProps & Pick<ReturnType<typeof useFormUtility>, 'fieldProps'|'fieldDisabled'|'FieldDisabledMessage'>;
export const AccessoriesTable: React.FC<AccessoriesTableProps> = ({
    fieldProps,
    fieldDisabled,
    FieldDisabledMessage,
    className,
    ...tableProps
}) => {
    const { localize } = useLocalization();

    const items: {
        field: FieldName<ConfigurationInputsFormModel>,
        options?: Partial<FieldOptions>,
        props?: any,
        imageUrl?: string,
        infoText?: JSX.Element,
    }[] = [
        { field: "rt-45-rj", options: { component: 'numeric' }, props: { min: 0, max: 8 }, imageUrl: "/product/rt-45-rj.png" },
        { field: "rt-45l-rj", options: { component: 'numeric' }, props: { min: 0, max: 8 }, imageUrl: "/product/rt-45l-rj.png" },
        { field: "rj-45-splitter", options: { component: 'numeric' }, props: { min: 0, max: 8 }, imageUrl: "/product/rj-weiche.png" },
        { field: "keySwitchLED", options: { component: 'numeric' }, props: { min: 0, max: 2 }, imageUrl: "/product/schluesselschalter-mit-led.png" },
        { field: "ventilationTimer", options: { component: 'checkbox' }, imageUrl: "/product/zeitschaltuhr.png" },
        { field: "additionalThermostat", options: { component: 'checkbox' }, imageUrl: "/product/thermostat.png" },
        { field: "humiditySensor", options: { component: 'checkbox' }, imageUrl: null },
        { field: "sirenWithKeySwitch", options: { component: 'checkbox' }, imageUrl: "/product/sirene.png" },
        { field: "eightPinInterfaceAdapterSet", options: { component: 'checkbox' }, imageUrl: null, infoText: nl2br(localize('info.eightPinInterfaceAdapterSet')) },
    ];

    return (
        <Table {...{ ...tableProps, className: (!!className ? `${className} ` : '') + 'AccessoriesTable' }}>
            <Table.Body>
                {items.map(({ field, options, props, imageUrl, infoText }) => (
                    <Table.Row key={`item-${field}`} disabled={fieldDisabled(field)}>
                        <Table.Cell width={3} textAlign="center">
                            <Field {...fieldProps(field, options)} label={null} {...props} />
                        </Table.Cell>
                        <Table.Cell width={12}>
                            {localize(`field.${field}`)}
                            {fieldDisabled(field) && (<>
                                <br/>
                                <FieldDisabledMessage name={field}/>
                            </>)}
                        </Table.Cell>
                        <Table.Cell width={1} textAlign="center">
                            {!!infoText && (
                                <Popup trigger={<Icon name="info"/>} content={infoText}/>
                            )}
                            {!!imageUrl && (
                                <ImageModal imageUrl={imageUrl} title={localize(`field.${field}`)}
                                    thumbnailClassName={'image-button image-button-small'}/>
                            )}
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};
