import React, { Component, CSSProperties, Fragment } from 'react'
import { GetRailProps } from "react-compound-slider";


const railStyle: CSSProperties = {
    position: 'absolute',
    width: '100%',
    transform: 'translate(0%, -50%)',
    height: 40,
    cursor: 'pointer',
    zIndex: 300,
};

const railCenterStyle: CSSProperties = {
    position: 'absolute',
    width: '100%',
    transform: 'translate(0%, -50%)',
    height: 14,
    borderRadius: 7,
    cursor: 'pointer',
    pointerEvents: 'none',
    backgroundColor: 'rgb(155,155,155)',
};

interface TooltipRailProps {
    activeHandleID: string;
    getRailProps: GetRailProps;
    getEventData: (e: Event) => object;
}

export class TooltipRail extends Component<TooltipRailProps> {
    render() {
        const {getRailProps} = this.props as any;
        return (
            <Fragment>
                <div
                    style={railStyle}
                    {...getRailProps()}
                />
                <div style={railCenterStyle as any}/>
            </Fragment>
        )
    }
}


// *******************************************************
// HANDLE COMPONENT
// *******************************************************

export function roundToStep(value: number, step: number) {
    return step * Math.round(value / step);
}

export function Handle({
                           domain: [min, max],
                           handle: {id, value, percent},
                           step,
                           disabled,
                           getHandleProps,
                           renderTooltip,
                       }) {
    return (
        <Fragment>
            {!!renderTooltip &&
                <div style={{
                    textAlign: 'center',
                    width: '100%',
                    position: 'absolute',
                    marginLeft: '-11px',
                    marginTop: '-45px',
                }}><div className="tooltip">
                    <span className="tooltiptext">{renderTooltip(min, roundToStep(value, step))}</span>
                </div></div>
            }

            {/* Rail from beginning of slider to current value */}
            <div
                style={{
                    position: 'absolute',
                    transform: 'translate(0%, -50%)',
                    height: 14,
                    zIndex: 1,
                    backgroundColor: disabled ? '#999':'#0092d3',
                    borderRadius: 7,
                    cursor: 'inherit',
                    left: `0%`,
                    width: `${percent}%`,

                }}
            />

            {/* Disabled Handle at beginning of slider */}
            <div
                role="slider"
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value}
                style={{
                    left: `0%`,
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2,
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
                    backgroundColor: '#666',
                }}
            />

            {/* Handle for current value */}
            <div
                style={{
                    left: `${percent}%`,
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                    zIndex: 5,
                    width: 28,
                    height: 42,
                    cursor: disabled ? 'inherit':'pointer',
                    backgroundColor: 'none',
                }}
                {...(disabled ? {}:getHandleProps(id))}
            />
            <div
                role="slider"
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value}
                style={{
                    left: `${percent}%`,
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2,
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
                    backgroundColor: disabled ? '#666':'#CCC',
                }}
            />
        </Fragment>
    )
}


// *******************************************************
// KEYBOARD HANDLE COMPONENT
// Uses a button to allow keyboard events
// *******************************************************
export function KeyboardHandle({
                                   domain: [min, max],
                                   handle: {id, value, percent},
                                   disabled,
                                   getHandleProps,
                               }) {
    return (
        <button
            role="slider"
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={value}
            style={{
                left: `${percent}%`,
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                zIndex: 2,
                width: 24,
                height: 24,
                borderRadius: '50%',
                boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
                backgroundColor: disabled ? '#666':'#ffc400',
            }}
            {...getHandleProps(id)}
        />
    )
}

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({source, target, getTrackProps, disabled}) {
    return (
        <div
            style={{
                position: 'absolute',
                transform: 'translate(0%, -50%)',
                height: 14,
                zIndex: 1,
                backgroundColor: disabled ? '#999':'#0092d3',
                borderRadius: 7,
                cursor: 'inherit',
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,

            }}
        />
    )
}


// {
// ...getTrackProps()
//
// }
// onMouseDown={() => null}

// *******************************************************
// TICK COMPONENT
// *******************************************************
export function Tick({tick, count, format}) {
    return (
        <div>
            <div
                style={{
                    position: 'absolute',
                    marginTop: 14,
                    width: 1,
                    height: 5,
                    backgroundColor: 'rgb(200,200,200)',
                    left: `${tick.percent}%`,
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    marginTop: 22,
                    fontSize: 10,
                    textAlign: 'center',
                    marginLeft: `${-(100 / count) / 2}%`,
                    width: `${100 / count}%`,
                    left: `${tick.percent}%`,
                }}
            >
                {format(tick.value)}
            </div>
        </div>
    )
}

