import * as React from "react";
import { Message } from "semantic-ui-react";

import { nl2br } from "./LocalizationService";
import { useLanguage, useLocalization, useSelectedLanguage } from "./hook";

import "./RestrictedLanguageMessage.scss";

export const RestrictedLanguageMessage: React.FC<{ className?: string }> = ({ className = "" }) => {
    const { localize } = useLocalization();
    const language = useLanguage();
    const selectedLanguage = useSelectedLanguage();

    return language !== selectedLanguage && <Message
        className={'restrict-language-message ' + className}
        info color='blue' size='tiny'
        content={nl2br(localize('info.language_restricted', {
            language: localize('language.' + language),
            selected: localize('language.' + selectedLanguage),
        }))}
    />;
};
