import * as React from 'react';
import { Action, Dispatch } from 'redux';


/**
 * Create a function that creates and dispatches a Redux action in one go
 * @param dispatch A Redux action `dispatch()` function
 * @param creator A function returning a Redux action
 * @returns A function that calls the `creator`, passing all parameters,
 *          and passes the resulting action to the `dispatch` function.
 */
export function useDispatchCallback<Args extends any[], A extends Action<any>>(
    dispatch: Dispatch<A>,
    creator: (...args: Args) => A
): typeof creator {
    const dispatchCallback = (...args: Args) => dispatch(creator(...args));
    return React.useCallback(dispatchCallback, [dispatch, creator]);
}
