import { IUser } from "./IUser";


export enum UserGroup {
    CUSTOMER = 'Customer',
    ADMIN    = 'Admin',
}

function hasAnyOfGroups(groups: UserGroup[], user: IUser|null): boolean {
    if (!user) {
        return false;
    }

    for (const g of groups) {
        if (user.groups.includes(g)) {
            return true;
        }
    }

    return false;
}

export function isCustomer(user: IUser|null) {
    return hasAnyOfGroups([UserGroup.CUSTOMER], user);
}

export function isAdmin(user: IUser|null) {
    return hasAnyOfGroups([UserGroup.ADMIN], user);
}

export function canAccessConnect(user: IUser|null) {
    return hasAnyOfGroups([UserGroup.CUSTOMER, UserGroup.ADMIN], user);
}
