import * as React from 'react';
import * as ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router'
import {createBrowserHistory} from 'history';

import {configProvider} from "./configProvider";
import { configureStore, ApplicationStore } from './store/store';

import { LocalizationContextProvider } from './localization/hook';
import { AuthContextProvider } from './features/auth/Auth.hook';
import { UserContextProvider } from './features/auth/User.hook';
import { SettingsContextProvider } from './features/settings/Settings.hook';

import App from './App';

import 'antd/dist/antd.min.css';
import 'semantic-ui-less/semantic.less';
import './global.scss';


if (process.env.NODE_ENV === 'development') {
    // @DEV: Development environment overrides
    configProvider.disableKeyCloak = true;
    configProvider.enableE2EToken = true;
    console.debug('=== Development Environment ===');
}

/** Wraps `children` in Redux store provider and all global React context providers */
const EverythingProvider: React.FC<React.PropsWithChildren<{ store: ApplicationStore; }>> = ({ children, store }) => {
    return (
        <Provider store={store}>
        <LocalizationContextProvider>
        <AuthContextProvider>
        <UserContextProvider>
        <SettingsContextProvider>
            {children}
        </SettingsContextProvider>
        </UserContextProvider>
        </AuthContextProvider>
        </LocalizationContextProvider>
        </Provider>
    );
};

function renderApp(appElement: HTMLElement, loaderElement?: HTMLElement) {

    const history = createBrowserHistory({basename: '/'});
    const store = window.store = configureStore(history);

    const root = (
        <EverythingProvider store={store}>
            <ConnectedRouter history={history}>
                <App/>
            </ConnectedRouter>
        </EverythingProvider>
    );

    const onRendered = () => {
        if (loaderElement) {
            loaderElement.style.display = 'none';
        }
        appElement.style.display = 'block';
    }

    ReactDOM.render(root, appElement, onRendered);
}

renderApp(
    document.getElementById('react-app'),
    document.getElementById('loader')
);
