import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { throttle } from "lodash-es";
import { Button, Checkbox, DropdownItemProps, DropdownProps, Form, Input, InputProps, Popup, Select, SemanticShorthandContent, SemanticSIZES } from 'semantic-ui-react';
import { Handles, Rail, Slider, Ticks, Tracks } from 'react-compound-slider'

import { Handle, roundToStep, Tick, TooltipRail, Track } from './slider'

import { formatVentilationArea } from "../../features/calculation/CalculationService/LiftArea";
import { useLocalization } from '../../localization/hook';

export const renderHidden = field => (
    <div>
        <Form.Field>
            <Input
                name={field.input.name}
                value={field.input.value}
                type="hidden"
            />
        </Form.Field>
    </div>
);

export type InputFieldProps = WrappedFieldProps & {
    label?: string;
    infoText?: SemanticShorthandContent;
    disabled?: boolean;
    hideError?: boolean;
} & Pick<InputProps, 'size'|'fluid'|'type'|'placeholder'|'action'>;
export const renderInput: React.FC<InputFieldProps> = (field: InputFieldProps) => (
    <Form.Field>
        {field.label &&
        <label>
            {field.label}
            {field.infoText && <Popup content={field.infoText} trigger={<Button className="info-button" icon="info"/>}/>}
        </label>}
        <Input
            size={field.size}
            fluid={field.fluid}
            value={field.input.value}
            type={field.type}
            error={!!(field.meta.touched && (field.meta.error || field.meta.warning))}
            name={field.input.name}
            placeholder={field.placeholder}
            disabled={field.disabled}
            onChange={(e, {value}) => field.input.onChange(value)}
            action={field.action}
            onBlur={field.input.onBlur}
        />
        <div style={{position: 'relative'}}>
            {!field.hideError && field.meta.touched && ((field.meta.error && <div className="ui basic red pointing prompt label transition visible error-message">{field.meta.error}</div>) || (field.meta.warning &&
                <span>{field.meta.warning}</span>))}
        </div>
    </Form.Field>
);

export const renderCheckbox = field => (
    <Form.Field style={field.style}>
        <Checkbox
            style={field.inputStyle}
            data-cy={field.input.name}
            checked={!!field.input.value}
            name={field.input.name}
            disabled={field.disabled}
            onChange={(e, {checked}) => field.input.onChange(checked)}
            label={field.label}
        />
        {field.infoText && <Popup content={field.infoText} trigger={<Button className="info-button" icon="info"/>}/>}
    </Form.Field>
);

export type RadioFieldProps = WrappedFieldProps & {
    radioValue: string;
    disabled: boolean;
    label: string;
};

export const renderRadio: React.FC<RadioFieldProps> = ({ input, radioValue, disabled, label }) => {
    const radioProps = {
        disabled,
        label,
        name: input.name,
        checked: input.value === radioValue,
        "data-cy": `${input.name}-${radioValue}`,
        onChange: () => {
            input.onChange(radioValue);
        },
    };

    return <Form.Radio {...radioProps}/>;
};

export type SelectFieldProps = WrappedFieldProps & {
    options: DropdownItemProps[];
    defaultValue?: any;
    placeholder?: string;
    disabled?: boolean;
    infoText?: SemanticShorthandContent;
    label: string;
};

export const renderSelect: React.FC<SelectFieldProps> = ({ input, options, placeholder, disabled, defaultValue, infoText, label, meta }) => {
    const selectProps = {
        options,
        placeholder,
        disabled,
        value: input.value || defaultValue,
        onBlur: (_event: unknown, { value }: DropdownProps) => input.onBlur(value),
        onChange: (_event: unknown, { value }: DropdownProps) => input.onChange(value),
    };

    return (
        <Form.Field>
            <label>
                {label}
                {infoText && <Popup content={infoText} trigger={<Button className="info-button" icon="info"/>}/>}
            </label>
            <Select {...selectProps}/>
            <div style={{position: 'relative'}}>
                {meta.touched && <>
                    {meta.error && (
                        <div className="ui basic red pointing prompt label transition visible error-message">
                            {meta.error}
                        </div>
                    )}
                    {!meta.error && meta.warning && (
                        <span>{meta.warning}</span>
                    )}
                </>}
            </div>
        </Form.Field>
    );
};

export const renderSearchableSelect = (field) => (
    <Form.Field>
        <label>
            {field.label}
            {field.infoText && <Popup content={field.infoText} trigger={<Button className="info-button" icon="info"/>}/>}
        </label>
        <Select
            {...field.input}
            name={field.input.name}
            onChange={(e, {value}) => field.input.onChange(value)}
            onBlur={(e, {value}) => field.input.onBlur(value)}
            options={field.options}
            placeholder={field.placeholder}
            value={field.input.value || field.defaultValue}
            search
        />
        <div style={{position: 'relative'}}>
            {field.meta.touched && ((field.meta.error && <div className="ui basic red pointing prompt label transition visible error-message">{field.meta.error}</div>) || (field.meta.warning &&
                <span>{field.meta.warning}</span>))}
        </div>
    </Form.Field>
);

export type TextAreaInputProps = WrappedFieldProps & {
    label?: string;
    placeholder?: string;
    rows?: string | number;
    style: any;
};

export const renderTextArea: React.FC<TextAreaInputProps> = ({ input, label, placeholder, rows, style }) => (
    <Form.TextArea
        {...input}
        label={label}
        placeholder={placeholder}
        value={input.value}
        rows={rows}
        style={style}
    />
);


export const RenderSlider = field => {
    const { localize } = useLocalization();
    
    if (field.min > field.max) return <></>;

    const valueRoundedToStep = roundToStep(field.input.value, field.step);
    const domain: [number, number] = [field.min, Math.max(field.min + 1, field.max)];
    const defaultValues = [valueRoundedToStep];

    const sliderStyle: any = {
        position: 'relative',
        width: '100%',
        height: '50px',
        padding: '68px 0 58px 0',
        touchAction: 'none',
    };

    let lastValue = valueRoundedToStep;

    const doChange = throttle(
        (v) => {
            field.input.onChange(v);
        },
        250,
        {
            leading: false,
            trailing: true,
        }
    );

    const onChange = (values: number[]) => {
        // console.log("=> SLIDER TRY CHANGE", values);

        const rounded = roundToStep(values[0], field.step);

        if (lastValue === rounded) return;
        lastValue = rounded;

        // console.log("=> SLIDER CHANGE", rounded, ' last =', lastValue);

        doChange(rounded);
    };

    return (
        <Slider
            rootStyle={sliderStyle}
            domain={domain}
            values={defaultValues}
            // step={field.step}
            onUpdate={onChange}
        >
            <Rail>{railProps => <TooltipRail {...railProps} />}</Rail>
            <Handles>
                {({handles, getHandleProps}) => (
                    <div className="slider-handles">
                        {handles.map((handle, i) => (
                            <Handle
                                key={handle.id}
                                handle={handle}
                                domain={domain}
                                disabled={false}
                                step={field.step}
                                getHandleProps={getHandleProps}
                                renderTooltip={(min, max) => localize('info.ventilation_area_slider.tooltip', { min, max })}
                            />
                        ))}
                    </div>
                )}
            </Handles>
            <Tracks left={false} right={false}>
                {({tracks, getTrackProps}) => (
                    <div className="slider-tracks">
                        {tracks.map(({id, source, target}) => (
                            <Track
                                key={id}
                                source={source}
                                target={target}
                                disabled={false}
                                getTrackProps={getTrackProps}
                            />
                        ))}
                    </div>
                )}
            </Tracks>
            <Ticks count={10}>
                {({ticks}) => (
                    <div className="slider-ticks">
                        {ticks.map(tick => (
                            <Tick key={tick.id} tick={tick} count={ticks.length} format={formatVentilationArea}/>
                        ))}
                    </div>
                )}
            </Ticks>
        </Slider>
    );
};


export type NumericInputProps = WrappedFieldProps & {
    label?: string;
    labelPosition?: 'start' | 'end';
    infoText?: SemanticShorthandContent;
    disabled?: boolean; // @TODO
    min: number;
    max: number;
};

export const renderNumeric: React.FC<NumericInputProps> = ({ label, labelPosition, infoText, input, disabled, min, max }) => {
    const valueFromAction = parseInt(input.value ? input.value : min);
    const minIsDisabled = !isNaN(min) && min === valueFromAction;
    const maxIsDisabled = !isNaN(max) && max === valueFromAction;

    const onMinusClick = () => input.onChange(valueFromAction - 1);
    const onPlusClick  = () => input.onChange(valueFromAction + 1);

    return (
        <div
            data-cy={input.name}
            className="numeric-component field"
        >
            {label && (!labelPosition || labelPosition === 'start') && (
                <label>
                    {label}
                    {infoText && (
                        <Popup content={infoText} openOnTriggerClick={false} trigger={<Button className="info-button" icon="info"/>}/>
                    )}
                </label>
            )}
            <Button
                type="button"
                data-cy="minus"
                className="numeric-button"
                icon="minus"
                disabled={disabled || minIsDisabled}
                onClick={onMinusClick}
            />
            <span>{valueFromAction}</span>
            <Button
                type="button"
                data-cy="plus"
                className="numeric-button"
                icon="plus"
                disabled={disabled || maxIsDisabled}
                onClick={onPlusClick}
            />
            {label && (labelPosition === 'end') && (
                <label className="position-end">
                    {label}
                    {infoText && (
                        <Popup content={infoText} trigger={<Button className="info-button" icon="info"/>}/>
                    )}
                </label>
            )}
        </div>
    );
};
