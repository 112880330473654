// Select Lookups
export enum COUNTRY {
    DE = 'de',
    AT = 'at',
};

export enum PROJECT_TYPE {
    NEWBUILDING = 'NEWBUILDING',
    MODERNIZED = 'MODERNIZED',
}

export enum BUILDING_TYPE {
    RESIDENTIAL = 'RESIDENTAL', // @TODO: Fix this type EVERYWHERE(!)
    INDUSTRY = 'INDUSTRY',
    HEALTHCARE = 'HEALTHCARE',
    OFFICE = 'OFFICE',
};

export enum INSTALLATION_POSITION {
    VERTICAL = 'V',
    HORIZONTAL = 'H',
}

export enum MACHINE_ROOM {
    UP = 'up',
    DOWN = 'down',
    NO = 'no',
};

export enum SYSTEM {
    L_AIO = "L-AIO",
    AIO_BASIC = "AIO-Basic"
}

export enum SMOKE_DETECTION {
    NONE = 'NONE',
    POINTDETECTOR = 'POINTDETECTOR',
    SDLF1 = 'SDLF1',
    RAS = 'RAS'
};

export enum LIFT_STATUS_DETECTION {
    LST_CO2_V3 = 'LST-CO2-V3',
    FAULTALARMCONTACT = 'FAULTALARMCONTACT',
};

/*
// This enum was present in the backend's BaseData, but unused
export enum LIFT_FAULT {
    EMERGENCYBUTTON = 'EMERGENCYBUTTON', // Notruftaste
    PIRCABIN = 'PIRCABIN', // PIR Kabine
    TOBEDETERMINED = 'TOBEDETERMINED', // noch festzulegen
};
*/

export enum REQUEST_TYPE {
    QUESTION = 'QUESTION',
    ORDER = 'ORDER',
    OFFER = 'OFFER',
    OTHER = 'OTHER',
}

export enum LIFT_AREA_PERCENTAGE {
    _2_5_PERCENT = '2_5_PERCENT',
    _1_0_PERCENT = '1_0_PERCENT',
    MANUAL = 'MANUAL',
}
