import * as React from 'react';
import {connect, useDispatch} from 'react-redux';
import {createStructuredSelector} from "reselect";
import {getFormMeta, getFormSyncErrors, getFormSyncWarnings, getFormValues, InjectedFormProps, reduxForm} from 'redux-form';
import {Divider, Grid, Header} from 'semantic-ui-react';

import {ReduxFormKeys} from '../../../form/ReduxFormKeys';
import {IApplicationState} from '../../../../shared/ApplicationState';
import {TabSection} from '../../../../shared/components/TabSection';
import {FormErrorList, syncedErrorsToList} from "../../../../shared/components/error-list";
import { useDispatchCallback } from '../../../../store/useDispatchCallback';
import { useLocalization } from '../../../../localization/hook';

import {isAdmin} from '../../../auth/PermissionService';
import { useUser } from '../../../auth/User.hook';

import { FieldDisabledEntry, useFormUtility, mutuallyExclusive as baseMutuallyExclusive } from '../../FormUtility';
import {isAllFormReady} from "../../calculation.selector";
import {calculationActionCreator} from "../../CalculationActions";
import { getApplicableSmokeDetectionMethods } from "../../CalculationService/SmokeDetection";
import { getApplicatableLiftStatusDetectionMethods } from '../../CalculationService/LiftStatusDetection';
import { getAvailableSystems } from "../../CalculationService/System";
import { BasicInputsFormModel, ConfigurationInputsFormModel, VentilationComponentsFormModel, CostCalculationFormModel } from "../../models/FormModels";
import { ConfigurationForm } from './_ConfigurationForm';
import { AccessoriesTable } from './_AccessoriesTable';
import { NonStandardComponentsTable } from './_NonStandardComponentsTable';


const ConfigurationInputsForm = (props: Props) => {
    const {
        configurationInputFormValues,
        isAllFormReady,
        fieldDict
    } = props;

    const { localize } = useLocalization();
    const { user } = useUser();

    const fieldDisabledMappings: FieldDisabledEntry<ConfigurationInputsFormModel>[] = React.useMemo(() => {
        const mutuallyExclusive = baseMutuallyExclusive<typeof configurationInputFormValues>();

        // Mapping of inputs' `disabled` prop
        return [
            ...mutuallyExclusive('additionalThermostat', 'rt-45l-rj', { bTruthy: rt45lrj => rt45lrj > 0 }),
        ];
    }, []);

    const { fieldProps, fieldDisabled, FieldDisabledMessage } = useFormUtility(configurationInputFormValues, fieldDisabledMappings);

    const dispatch = useDispatch();
    const onAddNonStandardComponent = useDispatchCallback(dispatch, calculationActionCreator.addNonStandardComponent);
    const onRemoveNonStandardComponent = useDispatchCallback(dispatch, calculationActionCreator.removeNonStandardComponent);

    if (!isAllFormReady) return <></>;

    const errorList = syncedErrorsToList(props.syncedErrors).map(({ key, message }) => ({ key: 'field.' + key, message }));

    return (
        <TabSection>
        <Grid columns={2}>
            <Grid.Row stretched divided>
                <Grid.Column width={5}>
                    <Header as="h2">{localize('configurationInputs.section.system')}</Header>
                    <ConfigurationForm
                        fieldProps={fieldProps}
                        FieldDisabledMessage={FieldDisabledMessage}
                        basicInputFormValues={props.basicInputFormValues}
                        configurationInputFormValues={props.configurationInputFormValues}/>
                </Grid.Column>
                <Grid.Column width={11}>
                    <Header as="h2">{localize('configurationInputs.section.accessories')}</Header>
                    <AccessoriesTable basic size="small"
                        fieldProps={fieldProps}
                        fieldDisabled={fieldDisabled}
                        FieldDisabledMessage={FieldDisabledMessage}/>
                </Grid.Column>
            </Grid.Row>
            {(isAdmin(user) || props.nonStandardComponents?.length > 0) && (<>
                <Divider/>
                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <Header as="h2">{localize('configurationInputs.section.nonStandardComponents')}</Header>
                            <NonStandardComponentsTable basic size="small"
                                items={props.nonStandardComponents}
                                onAddItem={onAddNonStandardComponent}
                                onRemoveItem={onRemoveNonStandardComponent}/>
                    </Grid.Column>
                </Grid.Row>
            </>)}
            <Grid.Row columns={1}>
                <Grid.Column width={16}>
                    {errorList?.length > 0 && (
                        <FormErrorList errorList={errorList} fieldDict={fieldDict}/>
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
        </TabSection>
    );
};

// @TODO: Make sure this function actually does something
function validate(values: ConfigurationInputsFormModel, props: Props) {
    const errors: Partial<Record<keyof ConfigurationInputsFormModel, any>> = {};

    if (!props.basicInputFormValues) return;

    if (!getAvailableSystems(props.basicInputFormValues).includes(values.system)) {
        errors.system = 'invalidSystem'
    }
    if (!getApplicableSmokeDetectionMethods(props.basicInputFormValues, values).includes(values.smokeDetection)) {
        errors.smokeDetection = 'invalidSmokeDetection';
    }
    if (!getApplicatableLiftStatusDetectionMethods(props.basicInputFormValues, values).includes(values.liftStatusDetection)) {
        errors.liftStatusDetection = 'invalidLiftStatusDetection';
    }
    /* @TODO: Check if this is actually required
    if (values['rt-45-rj'] + values['rt-45l-rj'] < 1) {
        errors['rt-45-rj'] = 'atLeastOneCaliperRequired';
    }
    */

    if (values.additionalThermostat && values['rt-45l-rj'] > 0) {
        errors['rt-45l-rj'] = 'incompatibleWithThermostat'
    }

    return errors;
}

export type CONFIGURATION_INPUTS_FORM_VALUES_SELECTOR = (state: IApplicationState) => ConfigurationInputsFormModel;
export type BASIC_INPUTS_FORM_VALUES_SELECTOR = (state: IApplicationState) => BasicInputsFormModel;
export type VENTILATION_COMPONENTS_FORM_VALUES_SELECTOR = (state: IApplicationState) => VentilationComponentsFormModel;
export type COST_CALCULATION_FORM_VALUES_SELECTOR = (state: IApplicationState) => CostCalculationFormModel;

const mapStateToProps = createStructuredSelector({
    isAllFormReady: isAllFormReady,
    syncedErrors: getFormSyncErrors(ReduxFormKeys.configurationInputsForm),
    syncedWarnings: getFormSyncWarnings(ReduxFormKeys.configurationInputsForm),
    fieldDict: getFormMeta(ReduxFormKeys.configurationInputsForm),
    configurationInputFormValues: getFormValues(ReduxFormKeys.configurationInputsForm) as CONFIGURATION_INPUTS_FORM_VALUES_SELECTOR,
    basicInputFormValues: getFormValues(ReduxFormKeys.basicInputsForm) as BASIC_INPUTS_FORM_VALUES_SELECTOR,

    nonStandardComponents: (state: IApplicationState) => state.calculationState.nonStandardComponents,
});

export default connect(mapStateToProps)(
    reduxForm({
        form: ReduxFormKeys.configurationInputsForm,
        validate,
        touchOnChange: true,
        touchOnBlur: true,
        keepDirtyOnReinitialize: true,
        enableReinitialize: true,
    })(ConfigurationInputsForm)
)

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps & InjectedFormProps;
