import { BasicInputsFormModel } from "../models/FormModels";

import { getSystem } from "./System";
import { COUNTRY, LIFT_AREA_PERCENTAGE, MACHINE_ROOM, SMOKE_DETECTION, SYSTEM } from '../../project/lookup';

function hasSmokeDetectionMethodNone({ country, liftAreaPercentage, systemIntegration }: Pick<BasicInputsFormModel, 'country' | 'liftAreaPercentage' | 'systemIntegration'>) {
    // Not available in Germany
    if (country === COUNTRY.DE) {
        return false;
    }

    // Only available if either relaxed ventilation rules, or integrated with building control system
    return systemIntegration || [
        LIFT_AREA_PERCENTAGE._1_0_PERCENT,
        LIFT_AREA_PERCENTAGE.MANUAL,
    ].includes(liftAreaPercentage as any);
}

/** Get the smoke detection methods for a given shaft height; ordered by preference (descending order). */
function getSmokeDetectionMethodsForShaftHeight(shaftHeight: number): SMOKE_DETECTION[] {
    if (shaftHeight <= 12_000) {
        return [
            SMOKE_DETECTION.NONE,
            SMOKE_DETECTION.POINTDETECTOR,
            SMOKE_DETECTION.SDLF1,
            SMOKE_DETECTION.RAS,
        ];
    } else if (shaftHeight <= 75_000) {
        return [
            SMOKE_DETECTION.NONE,
            SMOKE_DETECTION.SDLF1,
            SMOKE_DETECTION.POINTDETECTOR,
            SMOKE_DETECTION.RAS,
        ];
    } else {
        // BLU-240: RAS can not be configured in Calculator for shaftHeight > 75m
        return [
            SMOKE_DETECTION.NONE,
            SMOKE_DETECTION.SDLF1,
            SMOKE_DETECTION.POINTDETECTOR,
        ];
    }
}

export function getApplicableSmokeDetectionMethods(
    basicInputFormValues: Pick<BasicInputsFormModel, 'shaftHeight'|'noOfElevators'|'machineRoom'>
                            & Parameters<typeof getSystem>[0]
                            & Parameters<typeof hasSmokeDetectionMethodNone>[0],
    configurationInputsFormValues: Parameters<typeof getSystem>[1],
): SMOKE_DETECTION[] {
    const { shaftHeight, noOfElevators, machineRoom } = basicInputFormValues;
    const system = getSystem(basicInputFormValues, configurationInputsFormValues);

    const removeMethod = (remove: SMOKE_DETECTION) => (m: SMOKE_DETECTION) => m !== remove;

    let methods = getSmokeDetectionMethodsForShaftHeight(shaftHeight);

    if (!hasSmokeDetectionMethodNone(basicInputFormValues)) {
        methods = methods.filter(removeMethod(SMOKE_DETECTION.NONE));
    }

    // No POINTDETECTOR in shaft with more than two elevators
    if (noOfElevators > 2) {
        methods = methods.filter(removeMethod(SMOKE_DETECTION.POINTDETECTOR));
    }

    // Only POINTDETECTOR for AIO Basic with machine room; BLU-244
    if (machineRoom !== MACHINE_ROOM.NO && system === SYSTEM.AIO_BASIC) {
        methods = methods.includes(SMOKE_DETECTION.POINTDETECTOR) ? [SMOKE_DETECTION.POINTDETECTOR] : [];
    }

    return methods;
}
