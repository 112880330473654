export enum FormActionTypes {
    RESET_FIELDS = 'RESET_FIELDS',
}

export const formActionCreator = {
    resetFields(form: string, fields: string[]) {
        return <const>{
            type: FormActionTypes.RESET_FIELDS,
            payload: {
                form,
                fields,
            }
        };
    },
};

type ValueOf<T> = T[keyof T];
export type KnownFormActions = ReturnType<ValueOf<typeof formActionCreator>>;
