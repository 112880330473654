import { SYSTEM } from '../../project/lookup';
import { BasicInputsFormModel, ConfigurationInputsFormModel } from '../models/FormModels';


/** Check whether AIO-Basic (as a system, or as a set) is applicable */
export function aioBasicSystemOrPackageNotAvailable(
    basicInputFormValues: Pick<BasicInputsFormModel,
        'abzRequired' | 'nrwgDelivered' | 'lowEnergyStandard' | 'quiet' | 'noOfElevators' | 'additionalSmokeDetector'
    > | undefined
): boolean {
    return basicInputFormValues?.nrwgDelivered
    || basicInputFormValues?.lowEnergyStandard
    || basicInputFormValues?.abzRequired
    || basicInputFormValues?.quiet
    || basicInputFormValues?.noOfElevators > 2
    || basicInputFormValues?.additionalSmokeDetector;
}

/** Get a list of systems that are applicable */
export function getAvailableSystems(basicInputFormValues: Parameters<typeof aioBasicSystemOrPackageNotAvailable>[0]): SYSTEM[] {
    if (aioBasicSystemOrPackageNotAvailable(basicInputFormValues)) {
        return [SYSTEM.L_AIO];
    } else {
        return [SYSTEM.L_AIO, SYSTEM.AIO_BASIC];
    }
}

/** Get the system from user selection, fall back to the first available system */
export function getSystem(
    basicValues: Parameters<typeof aioBasicSystemOrPackageNotAvailable>[0],
    configValues: Pick<ConfigurationInputsFormModel, 'system'> | undefined
): SYSTEM {
    return configValues?.system || getAvailableSystems(basicValues)[0];
}
