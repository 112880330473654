import { applyMiddleware, combineReducers, compose, createStore, ReducersMapObject } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { reducer as formReducers } from 'redux-form';
import { createLogger } from "redux-logger";

import { History } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router'

import { IApplicationState } from "../shared/ApplicationState";

import rootSaga from './saga';
import { reducers } from "./reducer";


const actionsDenylist = [
    "@@redux-form/UPDATE_SYNC_ERRORS",
    "@@redux-form/REGISTER_FIELD",
    "@@redux-form/UNREGISTER_FIELD",
];

/** Get the compose() factory from Redux DevTools extension */
function getDevtoolsCompose() {
    return typeof window === "object" && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function"
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : null;
}

/** Get the compose() function, either the default `import { compose } from 'redux', or the DevTools-created one if present.` */
function getCompose(): typeof compose {
    const devtoolsCompose = getDevtoolsCompose();
    if (devtoolsCompose) {
        return devtoolsCompose({
            actionsDenylist,
            trace: typeof window === "object" && !!window.__REDUX_TRACE__,
        });
    }

    return compose;
}

/** Build the root reducer by combining `allReducers` with reducers from 'connected-react-router' and 'redux-form' */
function buildRootReducer(allReducers: ReducersMapObject<Omit<IApplicationState, 'router'>>, history: History) {
    const router = connectRouter(history);
    return combineReducers<IApplicationState>({ ...allReducers, router, ...formReducers });
}

export function configureStore(history: History) {

    const sagaMiddleware = createSagaMiddleware();

    const logger = createLogger({
        level: 'debug',
        predicate: (getState, action) => actionsDenylist.indexOf(action.type) === -1,
        collapsed: () => true
    });

    const enhancer = getCompose()(
        applyMiddleware(
            sagaMiddleware,
            routerMiddleware(history),
            logger
        ),
    );

    const allReducers = buildRootReducer(reducers, history);
    const store = createStore(allReducers, enhancer);

    sagaMiddleware.run(rootSaga, store.dispatch);

    return store;
}

export type ApplicationStore = ReturnType<typeof configureStore>;
