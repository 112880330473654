import React from "react";


export const LanguageKey = ['de', 'en', 'pl', 'fr', 'nl'] as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LanguageKey = typeof LanguageKey[number];

export type LocalizationKey = string;
export type LocalizationValues = {
    [key: string]: any;
}

export const CurrencyKey = ['EUR', 'CHF', 'GBP', 'USD'] as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CurrencyKey = typeof CurrencyKey[number];

export const fallbackLanguages: Record<LanguageKey, LanguageKey|null> = {
    'de': null,
    'en': 'de',
    'pl': 'en',
    'fr': 'en',
    'nl': 'en',
} as const;

export function forLanguage(language: LanguageKey, enableFallback: boolean = true) {
    function localize(key: LocalizationKey, values: LocalizationValues | "off" = {}, defaultString: string = key) {
        // Get the plain localization string
        let localeString = getLocaleString(language, key);

        if (enableFallback) {
            if (!localeString && fallbackLanguages[language]) {
                // Try finding a string in the fallback language
                localeString = getLocaleString(fallbackLanguages[language], key);
                if (localeString) {
                    console.debug(`[localize] [${language}] Using fallback language [${fallbackLanguages[language]}] for key '${key}'`);
                }
            }
        }

        if (!localeString) {
            // Not even a fallback is available
            console.info(`[localize] [${language}] No locale string for key '${key}'!`);
            return defaultString;
        }

        return values !== "off" ? substitute(localeString, values) : localeString;
    }

    function localizeEnum<T extends string>(value: T, prefix: string) {
        return localize(`${prefix}.${value}`, null, null);
    }

    function enumToLookup<T extends string>(values: T[], prefix: string): { key: string; value: string; text: string; }[] {
        return values.map(value => ({ key: value, value, text: localizeEnum(value, prefix) }));
    }

    function localizeCountry(key: string): string {
        const searchFn: (c: CountryEntry) => boolean = (
            key.length === 2 ? c => c.alpha2 === key :
            key.length === 3 ? c => c.alpha3 === key :
            null
        );

        const result = searchFn ? world_countries[language].find(searchFn)?.name : null;

        return result == null ? '(' + key + ')' : result;
    }

    function countriesToLookup(): { key: string; value: string; text: string; }[] {
        const countries = getCountriesAlpha2();
        return countries.map(key => ({
            key,
            value: key,
            text: localizeCountry(key),
        }));
    }

    function substitute(localeString: string, values: LocalizationValues): string {
        const subRegex  = /\{([^}]+)\}/;
    
        let sourceString = localeString;
        let resultString = '';
    
        while (sourceString.length > 0 && localeString.includes("{")) { // .includes() is presumably faster than .match()
            const match = sourceString.match(subRegex);
            if (match === null || match.length < 2) break; // No substitution pattern found
    
            const expr = match[1];
    
            let value = `{${expr}}`;
            if (expr.startsWith('loc:')) {
                value = localize(expr.substring(4) as LocalizationKey, "off"); // No nested substitution to protect against infinite recursion
    
            } else if (Object.prototype.hasOwnProperty.call(values, expr)) {
                value = values[expr];
            }
    
            // Put prefix and substitution into resultString
            resultString += sourceString.substring(0, match.index) + value;
    
            // Remove prefix and match from sourceString
            sourceString = sourceString.slice(match.index + match[0].length);
        }
    
        resultString += sourceString; // Append remainder
    
        return resultString;
    }

    return {
        /**
        * Localize a string based on a `key`.  
        * Allows value substitution: `Hello {name}!`  
        * Allows nested localization: `{loc:hello} Alice!`
        * @param key           The key to lookup a localization string.
        * @param values        Passed to `substitute` alongside the localization string.
        * @param defaultString If no localization for the `key` is available and this value is set, return this string.
        *                      If not set, the `key` is returned instead.
        * @see `substitute`
        */
        localize,
        /** Localize an enum value with key `prefix.value` */
        localizeEnum,
        /** Map enum values to `ILookup[]` with localized `text` */
        enumToLookup,
        /** Localize a country name by country code (alpha2 or alpha3) */
        localizeCountry,
        /** Create `ILookup[]` of all countries with alpha2 code and localized names */
        countriesToLookup,
        /**
         * Substitute patterns in a string  
         * Allows value substitution: `Hello {name}!`  
         * Allows nested localization: `{loc:hello} Alice!`  
         * Used by `localize`
         */
        substitute,
        language,
    };
};


export function nl2br(text: string): JSX.Element {
    const lines = text.split("\n");
    if (lines.length < 1) return null;

    const children = [];
    lines.forEach((line, idx) => {
        if (idx > 0) children.push(React.createElement("br", { key: `${idx}-br` }));
        children.push(line);
    })

    return React.createElement(React.Fragment, undefined, children);
}

function getLocaleString(locale: keyof typeof localization, key: LocalizationKey): string | null {
    if (!localization[locale]) {
        return null;
    }
    return localization[locale][key] ?? null;
}

const localization: Partial<Record<LanguageKey, Record<LocalizationKey, string>>> = {
    'de': require('./languages/de.json') as Record<LocalizationKey, string>,
    'en': require('./languages/en.json') as Record<LocalizationKey, string>,
    'pl': require('./languages/pl.json') as Record<LocalizationKey, string>,
};

interface CountryEntry {
    /** ISO 3166-1 numeric country code */
    id: number;
    /** ISO 3166-1 alpha-2 country code (lower case) */
    alpha2: string;
    /** ISO 3166-1 alpha-3 country code (lower case) */
    alpha3: string;
    /** ISO 3166-1 short country name */
    name: string;
};

const world_countries: Partial<Record<LanguageKey, CountryEntry[]>> = {
    // Source: https://github.com/stefangabos/world_countries/tree/master/data/countries
    'de': require('./world_countries/world.de.json'),
    'en': require('./world_countries/world.en.json'),
    'pl': require('./world_countries/world.pl.json'),
}

/** Get all ISO 3166-1 alpha-2 country codes */
export function getCountriesAlpha2(): string[] {
    return world_countries.de.map(c => c.alpha2);
}
