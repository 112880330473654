import { JsonRESTApiClient } from "../api/JsonRESTApiClient";
import { isArrayOf, isObjectWithProperties } from "../api/simple-validators";
import { ICompany } from "../project/ProjectModels";
import { IUser } from "./IUser";


/** @see `DH.BlueKitConnect.Backend.Features.User.UserController` */
export class UserApiV1Client extends JsonRESTApiClient {

    /// API Endpoints

    public async current(): Promise<IUser> {
        return await this._fetchJson(
            this._getUrl(['current']),
            {
                method: 'GET',
                headers: await this._getHeaders(),
            },
            UserApiV1Client.ValidateIUser
        );
    }

    /// Validators for API types

    public static ValidateIUser(v: unknown): v is IUser {
        const isGroupsArray = isArrayOf((e): e is string => typeof e === 'string');

        return isObjectWithProperties('id', 'firstName', 'lastName', 'email', 'company', 'country', 'groups')(v) &&
            typeof v.id === 'string' &&
            typeof v.firstName === 'string' &&
            typeof v.lastName === 'string' &&
            typeof v.email === 'string' &&
            typeof v.country === 'string' &&
            isGroupsArray(v.groups) &&
            UserApiV1Client.ValidateICompany(v.company);
    }

    public static ValidateICompany(v: unknown): v is ICompany {
        return isObjectWithProperties('id', 'name')(v) &&
            typeof v.id === 'number' &&
            typeof v.name === 'string';
    }
}
