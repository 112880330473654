import * as React from "react";

import { CurrencyKey, LanguageKey, fallbackLanguages, forLanguage } from "./LocalizationService";
import { configProvider } from "../configProvider";

function getDebugLangFromStr(str: string) {
    if (!str || str.length < 12) {
        return null;
    }

    const pattern = /(^|[?#&])debugLang=([a-z]{2})(&|$)/;
    const match = str.match(pattern);
    const lang = match ? match[2]: null;

    if (!lang) {
        return null;
    }
    if (!LanguageKey.includes(lang as any)) {
        return null;
    }

    return lang as LanguageKey;
};

/** The type of `LocalizationContext`'s value */
interface LocalizationContextType {
    /** The language preference manually set by the user */
    manuallySetLang: LanguageKey | null;
    /** Manually set the language */
    setLanguage: (lang: LanguageKey | null) => unknown;

    /** The set of available languages in an area with limited language support; set with `<RestrictLanguages>` */
    restrictedLanguages: LanguageKey[] | null;

    /** The currency preference manually set by the user */
    currency: CurrencyKey;
    /** Manually set the currency */
    setCurrency: (curr: CurrencyKey) => unknown;
};

/**
 * The localization context storing the currently active settings
 * for all things related to localization
 */
const LocalizationContext = React.createContext<LocalizationContextType>(null);

/**
 * Get the selected active language
 * @returns `[string, string]` The active language and the selected language
*/
function selectActiveLanguage(ctx: LocalizationContextType): [LanguageKey, LanguageKey] {
    const debugLang = getDebugLangFromStr(document.location.hash);

    if (debugLang) {
        return [debugLang, debugLang];
    }

    const browserLanguages = !Array.isArray(navigator?.languages) ? [] : navigator.languages
        .map(lang => String(lang).split("-")[0])
        .filter(lang => LanguageKey.includes(lang as any)) as LanguageKey[];
    const browserLanguage = browserLanguages?.length ? browserLanguages[0] : null;

    const selectedLanguage = ctx.manuallySetLang ?? browserLanguage ?? configProvider.defaultLanguage;

    if (ctx.restrictedLanguages && !ctx.restrictedLanguages.includes(selectedLanguage)) {
        if (fallbackLanguages[selectedLanguage] && ctx.restrictedLanguages.includes(fallbackLanguages[selectedLanguage])) {
            return [fallbackLanguages[selectedLanguage], selectedLanguage];
        } else {
            return [configProvider.defaultLanguage, selectedLanguage];
        }
    }

    return [selectedLanguage, selectedLanguage];
}

export const LocalizationContextProvider: React.FC<{}> = ({ children }) => {
    /// Language
    // Use React state as fallback in case window.localStorage is unavailable
    const [innerLanguage, innerSetLanguage] = React.useState<LanguageKey|null>(null);
    function storageSetLanguage(lang: LanguageKey) {
        if (!window?.localStorage?.setItem) {
            return false;
        }
        try {
            window.localStorage.setItem(configProvider.manuallySetLanguageLocalStorageKey, lang);
            const expires = Date.now() + configProvider.manuallySetLanguageLocalStorageTTL;
            window.localStorage.setItem(configProvider.manuallySetLanguageLocalStorageKey + '.expires', expires.toString());
            return true;
        } catch {
            return false;
        }
    }
    function storageGetLanguage() {
        if (!window?.localStorage?.getItem) {
            return null;
        }
        try {
            const expires = parseInt(window.localStorage.getItem(configProvider.manuallySetLanguageLocalStorageKey + '.expires'));
            if (!expires || isNaN(expires)) {
                return null;
            }
            if (expires < Date.now()) {
                return null;
            }

            const value = window.localStorage.getItem(configProvider.manuallySetLanguageLocalStorageKey);
            if (!value || !LanguageKey.includes(value as any)) {
                return null;
            }

            try {
                // Update TTL
                window.localStorage.setItem(configProvider.manuallySetLanguageLocalStorageKey + '.expires', (Date.now() + configProvider.manuallySetLanguageLocalStorageTTL).toString());
            } catch {}

            return value as LanguageKey;
        } catch {
            return null;
        }
    }

    const manuallySetLang = storageGetLanguage() ?? innerLanguage;
    const setLanguage = (lang: LanguageKey|null) => {
        if (lang != null && !LanguageKey.includes(lang as any)) {
            return;
        }
        innerSetLanguage(lang);
        storageSetLanguage(lang);
    };

    /// Currency
    // Use React state as fallback in case window.localStorage is unavailable
    const [innerCurrency, innerSetCurrency] = React.useState<CurrencyKey>(null);
    function storageSetCurrency(curr: CurrencyKey) {
        if (!window?.localStorage?.setItem) {
            return false;
        }
        try {
            window.localStorage.setItem(configProvider.manuallySetCurrencyLocalStorageKey, curr);
            const expires = Date.now() + configProvider.manuallySetCurrencyLocalStorageTTL;
            window.localStorage.setItem(configProvider.manuallySetCurrencyLocalStorageKey + '.expires', expires.toString());
            return true;
        } catch {
            return false;
        }
    }
    function storageGetCurrency(): CurrencyKey {
        if (!window?.localStorage?.getItem) {
            return null;
        }
        try {
            const expires = parseInt(window.localStorage.getItem(configProvider.manuallySetCurrencyLocalStorageKey + '.expires'));
            if (!expires || isNaN(expires)) {
                return null;
            }
            if (expires < Date.now()) {
                return null;
            }

            const value = window.localStorage.getItem(configProvider.manuallySetCurrencyLocalStorageKey);
            if (!value || !CurrencyKey.includes(value as any)) {
                return null;
            }

            try {
                // Update TTL
                window.localStorage.setItem(configProvider.manuallySetCurrencyLocalStorageKey + '.expires', (Date.now() + configProvider.manuallySetCurrencyLocalStorageTTL).toString());
            } catch {}

            return value as CurrencyKey;
        } catch {
            return null;
        }
    }

    const { currency, setCurrency } = configProvider.enableCurrencySelect ? {
        currency: storageGetCurrency() ?? innerCurrency ?? configProvider.defaultCurrency,
        setCurrency: (curr: string) => {
            if (typeof curr !== "string" || !CurrencyKey.includes(curr as any)) {
                return;
            }
            innerSetCurrency(curr as CurrencyKey);
            storageSetCurrency(curr as CurrencyKey);
        }
    } : {
        currency: configProvider.defaultCurrency,
        setCurrency: () => { /* NOOP */ }
    };

    /// Context
    const contextValue: LocalizationContextType = {
        manuallySetLang,
        setLanguage,
        restrictedLanguages: null,
        currency,
        setCurrency,
    };

    return <LocalizationContext.Provider value={contextValue}>
        {children}
    </LocalizationContext.Provider>
};

export interface RestrictLanguagesProps {
    languages: LanguageKey[] | null;
};
/** Restrict the available languages */
export const RestrictLanguages: React.FC<RestrictLanguagesProps> = props => {
    const originalContext = React.useContext(LocalizationContext);

    const contextValue: LocalizationContextType = {
        ...originalContext,
        restrictedLanguages: props.languages,
    };

    return (
        <LocalizationContext.Provider value={contextValue}>
            {props.children}
        </LocalizationContext.Provider>
    );
};

export function useLanguage(): LanguageKey {
    const ctx = React.useContext(LocalizationContext);
    return selectActiveLanguage(ctx)[0];
}

export function useSelectedLanguage(): LanguageKey {
    const ctx = React.useContext(LocalizationContext);
    return selectActiveLanguage(ctx)[1];
}

export function useSetLanguage(): (lang: LanguageKey|null) => unknown {
    const ctx = React.useContext(LocalizationContext);
    return ctx.setLanguage;
}

/** Product data is not available in all languages */
export function useProductLanguage(): LanguageKey {
    const language = useLanguage();
    return React.useMemo(
        () => configProvider.productDataLanguages.includes(language) ? language : 'en',
        [language, configProvider.productDataLanguages]
    );
}

export function useCurrency(): CurrencyKey {
    const ctx = React.useContext(LocalizationContext);
    return ctx.currency;
}

export function useSetCurrency(): (curr: string) => unknown {
    const ctx = React.useContext(LocalizationContext);
    return ctx.setCurrency;
}

export type LocalizationProps = ReturnType<typeof forLanguage>;

export function useLocalization(): LocalizationProps {
    const lang = useLanguage();
    return React.useMemo(() => forLanguage(lang), [lang]);
}
