import { IProductData } from '../../product/types';

import {ISelectedProduct} from '../CalculationModels';
import {
    BasicInputsFormModel,
    ConfigurationInputsFormModel,
    VentilationComponentsFormModel,
    CostCalculationFormModel
} from '../models/FormModels';

import { SelectedProductsBuilder } from '../SelectedProductsBuilder';


/// Utility

/** string, number, null, arrays, plain objects; NOT functions, symbols etc. */
export type SerializableTypes = string | number | null | SerializableTypes[] | { [k in string|number]: SerializableTypes };


/// Flows, Stages, Rules

/** The keys of all flows that can be executed */
export type FlowKey = string;

/** The keys of all stages that can be executed, in this order */
export const StageKey = ['config', 'vent', 'post', 'custom'] as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type StageKey = typeof StageKey[number];

/** The keys of all rules that can be executed */
export type RuleKey = string;


/// Builder Rule Flow

export type BuilderRuleFlow = Record<RuleKey, RootBuilderRule>;


/// Builder Rule Expression

export type BuiltinExpressionName = keyof SelectedProductsBuilder['builtinExpressions'];

/** The context type passed to every BuilderRuleExpression */
export type BuilderRuleExpressionConfigContext = {
    basicValues: BasicInputsFormModel;
    configValues: ConfigurationInputsFormModel;
    totalLiftArea: number;
    productData: IProductData;
};
/** The context type passed to every BuilderRuleExpression in stage vent or later */
export type BuilderRuleExpressionVentContext = {
    ventilationComponentsValues: VentilationComponentsFormModel;
};
/** The context type passed to every BuilderRuleExpression in stage post or later */
export type BuilderRuleExpressionPostContext = {
    costCalculationValues: CostCalculationFormModel;
    selectedProducts: ISelectedProduct[];
};
/** @deprecated Can't be serialized */
export type BuilderRuleNativeExpression<ResultT> = (configCtx: BuilderRuleExpressionConfigContext, ventCtx: BuilderRuleExpressionVentContext, postCtx: BuilderRuleExpressionPostContext) => ResultT;
export type BuilderRuleConstantExpression<ResultT> = {
    constant: ResultT;
};
export type BuilderRuleContextExpression = {
    ctx: keyof BuilderRuleExpressionConfigContext | keyof BuilderRuleExpressionVentContext | keyof BuilderRuleExpressionPostContext,
    key: string;
};

export type BuilderRuleEqualsAnyExpression<ResultT, ValueT = any> = {
    value: BuilderRuleExpression<ValueT>;
    equalsAny: ResultT extends boolean ? BuilderRuleExpression<ValueT>[] : never;
};

type RelationalOperator = '=='|'!='|'>'|'>='|'<'|'<=';
export type BuilderRuleRelationalExpression<ResultT, ValueT = any> = {
    value: BuilderRuleExpression<ValueT>;
} & Partial<{
    [k in RelationalOperator]: ResultT extends boolean ? BuilderRuleExpression<ValueT> : never;
}>;

export type BuilderRuleIsEmptyExpression<ValueT = any> = {
    value: BuilderRuleExpression<ValueT>;
    is: 'empty'|'not empty';
};

/*
type BuiltinRHS<ResultT, ValueT> = Omit<
    BuilderRuleEqualsAnyExpression<ResultT, ValueT> |
    BuilderRuleRelationalExpression<ResultT, ValueT>,
'value'>;
*/

export type BuilderRuleAndExpression<ResultT> = ResultT extends boolean ? {
    and: BuilderRuleExpression<boolean>[];
} : never;
export type BuilderRuleOrExpression<ResultT> = ResultT extends boolean ? {
    or: BuilderRuleExpression<boolean>[];
} : never;

export type BuilderRuleBuiltinExpression = {
    builtin: BuiltinExpressionName | {
        name: BuiltinExpressionName;
        args?: SerializableTypes[];
    };
};

export type BuilderRuleExpression<ResultT> =
| BuilderRuleNativeExpression<ResultT>
| BuilderRuleConstantExpression<ResultT>
| BuilderRuleContextExpression
| BuilderRuleEqualsAnyExpression<ResultT>
| BuilderRuleRelationalExpression<ResultT>
| BuilderRuleIsEmptyExpression
| BuilderRuleAndExpression<ResultT>
| BuilderRuleOrExpression<ResultT>
| BuilderRuleBuiltinExpression;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace BuilderRuleExpression {
    export function getSubtype<T>(e: BuilderRuleExpression<T>) {
        if (typeof e === 'function')  return 'native';
        if ('ctx' in e && 'key' in e) return 'context';
        if ('value' in e) {
            return (['equalsAny', '==', '!=', '>', '>=', '<', '<=', 'is'] as const).find(k => k in e);
        }
        return (['constant', 'and', 'or', 'builtin'] as const).find(k => k in e);
    }
}


/// Builder Rule Execution

export type BuiltinExecutionName = keyof SelectedProductsBuilder['builtinExecutions'];

/** The context type passed to every BuilderRuleExecution */
export type BuilderRuleExecutionConfigContext = BuilderRuleExpressionConfigContext & SelectedProductsBuilder['builtinExecutions'];
/** The context type passed to every BuilderRuleExecution in stage vent or later */
export type BuilderRuleExecutionVentContext = BuilderRuleExpressionVentContext;
/** The context type passed to every BuilderRuleExecution in stage post or later */
export type BuilderRuleExecutionPostContext = BuilderRuleExpressionPostContext;
/**
 * A rule execution using a JS function
 * @deprecated Can't be serialized
 */
export type BuilderRuleNativeExecution = (configCtx: BuilderRuleExecutionConfigContext, ventCtx: BuilderRuleExecutionVentContext, postCtx: BuilderRuleExecutionPostContext) => void;
export type BuilderRuleBuiltinExecution = {
    builtin: {
        name: BuiltinExecutionName;
        args?: SerializableTypes[];
    };
};
export type BuilderRuleExecution =
| BuilderRuleNativeExecution
| BuilderRuleBuiltinExecution;


/// Builder Rule

/** A rule that executes nothing */
export type EmptyBuilderRule = { empty: true; };
/** A rule that executes a build action */
export type ExecBuilderRule = {
    /** One (or multiple, combined with AND) precondition(s) for this rule to execute */
    when?: BuilderRuleExpression<boolean> | BuilderRuleExpression<boolean>[];
    /** A rule execution that adds products */
    exec: BuilderRuleExecution;
};
/** Marks the default option for `SelectOneByBuilderRule` */
export const DEFAULT = Symbol('default');
/** Select one rule by matching against a value */
export type SelectOneByBuilderRule = {
    /** A function that selects the value to select one rule by */
    selectOneBy: BuilderRuleExpression<string|number>;
    /** If true, this rule throws if no matching option is present */
    sealed?: true;
    /** A mapping of match values to rules */
    options: Partial<Record<string|number|typeof DEFAULT, BuilderRule>>;
}
export type BuilderRuleErrorLevel = 'error'|'log';
export type BuilderRule = {
    description?: string;
} & (EmptyBuilderRule| ExecBuilderRule | SelectOneByBuilderRule);
export type RootBuilderRule = BuilderRule & {
    /**
     * - config: Rules that access basic and config form values
     * - vent: Rules that access ventilation component form values
     * - post: Rules that act on products added by default and vent stages
     */
    stage: StageKey;
    /**
     * - error (default):
     *      Exceptions thrown by the rule execution bubble all the way up
     * - log:
     *      Exceptions thrown by the rule execution are logged and
     *      the stage continues with the next rule
     */
    errorLevel?: BuilderRuleErrorLevel;
}
