import { PRODUCT_KEY } from '../product/lookup';
import { IProductData, IProduct } from '../product/types';

import { ISelectedProduct } from './CalculationModels';
import { getProductById, getProductByKey } from './CalculationService';

import { BasicInputsFormModel, ConfigurationInputsFormModel, VentilationComponentsFormModel, CostCalculationFormModel } from './models/FormModels';

import { FlowKey, StageKey, RuleKey, BuilderRule, BuilderRuleExecution, DEFAULT, BuilderRuleErrorLevel, BuilderRuleFlow, BuilderRuleExpression, BuilderRuleNativeExpression, BuilderRuleConstantExpression, BuilderRuleBuiltinExpression, BuilderRuleContextExpression, BuilderRuleAndExpression, BuilderRuleOrExpression, BuilderRuleRelationalExpression, BuilderRuleEqualsAnyExpression, BuilderRuleIsEmptyExpression } from './SelectedProductsBuilder/types';
import defaultFlow from './SelectedProductsBuilder/default.flow';
import completeSetFlow from './SelectedProductsBuilder/complete-set.flow';
import { getSystem } from './CalculationService/System';


export class SelectedProductsBuilder {

    /// Public API

    public static selectProductsFromFlow(flow: FlowKey, ...args: PrivateConstructorParameters<typeof SelectedProductsBuilder>) {
        const builder = new SelectedProductsBuilder(...args);
        builder.runFlow(flow);
        return builder.selectedProducts;
    }

    /// Internal Execution State

    private selectedProducts: ISelectedProduct[] = [];

    private constructor(
        private readonly totalLiftArea: number,
        private readonly basicValues: BasicInputsFormModel,
        private readonly configValues: ConfigurationInputsFormModel,
        private readonly ventilationComponentsValues: VentilationComponentsFormModel,
        private readonly costCalculationValues: CostCalculationFormModel,
        private readonly productData: IProductData,
    ) {}

    /// Rule Engine

    private runFlow(flowKey: FlowKey) {
        this.pushContext(`flow=${flowKey}`);
        try {
            if (!(flowKey in flows)) {
                throw new SelectedProductsBuilderError(this.logContext, `Flow with key "${flowKey}" not found`);
            }
            const flow = flows[flowKey];
            StageKey.forEach(stage => this.runStage(flow, stage));
        } finally {
            this.popContext();
        }
    }

    private runStage(flow: BuilderRuleFlow, stage: StageKey) {
        this.pushContext(`stage=${stage}`);
        try {
            Object.keys(flow).forEach(key => {
                this.runRule(flow, stage, key);
            });
        } finally {
            this.popContext();
        }
    }

    private runRule(flow: BuilderRuleFlow, stage: StageKey, key: RuleKey) {
        this.pushContext(`rule=${key}`);
        try {
            if (!(key in flow)) {
                throw new SelectedProductsBuilderError(this.logContext, `Rule with key "${key}" not found`);
            }
            const rule = flow[key];

            if (rule.stage !== stage) {
                return null;
            }

            // this.log(`def=${JSON.stringify(rule, null, '')}`);

            const execution = this.getRuleExecution(rule, stage);
            if (execution) {
                const errorLevel = 'errorLevel' in rule ? rule.errorLevel : 'error';
                this.executeRule(execution, errorLevel, stage);
            } else {
                this.pushContext('skip');
                this.log();
                this.popContext();
            }
        } finally {
            this.popContext();
        }
    }

    private getRuleExecution(rule: BuilderRule, currentStage: StageKey): BuilderRuleExecution|null {
        this.pushContext(`getRuleExecution`);
        try {
            const configCtx = {
                basicValues: this.basicValues,
                configValues: this.configValues,
                productData: this.productData,
                totalLiftArea: this.totalLiftArea,
            };
            const ventCtx = StageKey.indexOf(currentStage) >= StageKey.indexOf('vent') ? {
                ventilationComponentsValues: this.ventilationComponentsValues,
            } : null;
            const postCtx = StageKey.indexOf(currentStage) >= StageKey.indexOf('post') ? {
                costCalculationValues: this.costCalculationValues,
                selectedProducts: this.selectedProducts,
            } : null;

            if ('empty' in rule && rule.empty) {
                return null;
            } else if ('exec' in rule) {
                if ('when' in rule) {
                    const when = Array.isArray(rule.when) ? rule.when : [rule.when];
                    if (!when.every(condition => this.runExpression<boolean>(condition, configCtx, ventCtx, postCtx))) {
                        return null;
                    }
                }

                return rule.exec;
            } else if ('selectOneBy' in rule && 'options' in rule) {
                const selectorValue = this.runExpression<string|number>(rule.selectOneBy, configCtx, ventCtx, postCtx);
                if (selectorValue in rule.options) {
                    return this.getRuleExecution(rule.options[selectorValue], currentStage);
                } else if (DEFAULT in rule.options) {
                    return this.getRuleExecution(rule.options[DEFAULT], currentStage);
                } else if (rule.sealed) {
                    throw new SelectedProductsBuilderError(this.logContext, `Selector value "${selectorValue}" has no matching case in a sealed selection`);
                }

                return null;
            }

            throw new SelectedProductsBuilderError(this.logContext, 'Illformed rule');
        } finally {
            this.popContext();
        }
    }

    private runExpression<ResultT>(e: BuilderRuleExpression<ResultT>, ...ctxArgs: Parameters<BuilderRuleNativeExpression<ResultT>>) {
        const subtype = BuilderRuleExpression.getSubtype(e);
        switch (subtype) {
            case null:
                throw new SelectedProductsBuilderError(this.logContext, 'Illformed expression');
            case 'native':
                return (e as BuilderRuleNativeExpression<ResultT>)(...ctxArgs);
            case 'constant':
                return (e as BuilderRuleConstantExpression<ResultT>).constant;
            case 'builtin': {
                const exp = e as BuilderRuleBuiltinExpression;
                const name = typeof exp.builtin !== 'string' ? exp.builtin.name : exp.builtin;
                const args = typeof exp.builtin !== 'string' ? exp.builtin.args ?? [] : [];

                if (!(name in this.builtinExpressions)) {
                    throw new SelectedProductsBuilderError(this.logContext, `Unknown builtin "${name}"`);
                }

                const builtin = this.builtinExpressions[name] as ((...args: any[]) => void);
                return builtin(...args);
            }
            case 'and':
                return (e as BuilderRuleAndExpression<boolean>).and
                    .every(e => this.runExpression<boolean>(e, ...ctxArgs));
            case 'or':
                return (e as BuilderRuleOrExpression<boolean>).or
                    .some(e => this.runExpression<boolean>(e, ...ctxArgs));
            case 'context': {
                const { ctx, key } = e as BuilderRuleContextExpression;
                const ctxValue = this[ctx];

                if (typeof ctxValue !== 'object') {
                    throw new SelectedProductsBuilderError(this.logContext, `Illegal property access on non-object: ${ctx}[${key}]`);
                }
                if (!(key in ctxValue)) {
                    return null;
                }

                return ctxValue[key];
            }
            case 'equalsAny': {
                const exp = e as BuilderRuleEqualsAnyExpression<boolean, any>;
                const value = this.runExpression<any>(exp.value, ...ctxArgs);
                const eq = Array.isArray(exp.equalsAny) ? exp.equalsAny : [exp.equalsAny];

                return eq.some(e => value === this.runExpression<typeof value>(e, ...ctxArgs));
            }
            case 'is': { // null or undefined (empty) / not null or undefined (not empty)
                const exp = e as BuilderRuleIsEmptyExpression<any>;
                const value = this.runExpression<any>(exp.value, ...ctxArgs);

                /** Whether to check for empty or not empty */
                const checkForEmpty = (exp.is === 'empty');
                /** Whether the value is empty (null or undefined) */
                const valueIsEmpty = (value === null || value === undefined)

                return checkForEmpty === valueIsEmpty;
            }
            default: { // Relational expressions
                const exp = e as BuilderRuleRelationalExpression<boolean, any>;
                const value = this.runExpression<any>(exp.value, ...ctxArgs);
                const rhsValue = this.runExpression<typeof value>(exp[subtype], ...ctxArgs);

                switch (subtype) {
                    case '==': return value === rhsValue;
                    case '!=': return value !== rhsValue;
                    case '>':  return value > rhsValue;
                    case '<':  return value < rhsValue;
                    case '>=': return value >= rhsValue;
                    case '<=': return value <= rhsValue;
                    default: const _completeCheck: never = subtype;
                }
            }
        }

        throw new SelectedProductsBuilderError(this.logContext, 'Illformed expression');
    }

    private executeRule(ruleExecution: BuilderRuleExecution, errorLevel: BuilderRuleErrorLevel, currentStage: StageKey) {
        this.pushContext(`execute`);
        this.log();
        try {
            if ('builtin' in ruleExecution) {
                if (!(ruleExecution.builtin.name in this.builtinExecutions)) {
                    throw new SelectedProductsBuilderError(this.logContext, `Unknown builtin "${ruleExecution.builtin.name}"`);
                }
                const builtin = this.builtinExecutions[ruleExecution.builtin.name] as ((...args: any[]) => void);
                const args = ruleExecution.builtin.args ?? [];
                builtin(...args);
            } else if (typeof ruleExecution === 'function') {
                const configCtx = {
                    basicValues: this.basicValues,
                    configValues: this.configValues,
                    productData: this.productData,
                    totalLiftArea: this.totalLiftArea,
                    ...this.builtinExecutions,
                };
                const ventCtx = StageKey.indexOf(currentStage) >= StageKey.indexOf('vent') ? {
                    ventilationComponentsValues: this.ventilationComponentsValues,
                } : null;
                const postCtx = StageKey.indexOf(currentStage) >= StageKey.indexOf('post') ? {
                    costCalculationValues: this.costCalculationValues,
                    selectedProducts: this.selectedProducts,
                } : null;

                ruleExecution(configCtx, ventCtx, postCtx);
            }
        } catch (e) {
            switch (errorLevel) {
                case 'log':
                    this.log(`Log`, e);
                    return;
                case 'error':
                default:
                    this.log(`Error`, e);
                    throw e;

            }
        } finally {
            this.popContext();
        }
    }


    /// Builtin Expressions

    private builtinExpressions = {
        'getSystem': this.getSystem.bind(this) as typeof SelectedProductsBuilder.prototype.getSystem,
    };

    private getSystem() {
        return getSystem(this.basicValues, this.configValues);
    }

    /// Builtin Executions

    private builtinExecutions = {
        'addProductByKey': this.addProductByKey.bind(this) as typeof SelectedProductsBuilder.prototype.addProductByKey,
        'addProductById': this.addProductById.bind(this) as typeof SelectedProductsBuilder.prototype.addProductById,
        'topUpProductByKey': this.topUpProductByKey.bind(this) as typeof SelectedProductsBuilder.prototype.topUpProductByKey,
    };

    private addProductByKey(key: PRODUCT_KEY, quantity: number) {
        const product = getProductByKey(this.productData.products, key);
        if (!product) throw new SelectedProductsBuilderError(this.logContext, `no product matching product key "${key}"`);
        this._addProduct(product, quantity);
    }

    private topUpProductByKey(key: PRODUCT_KEY, minimumQuantity: number) {
        const product = getProductByKey(this.productData.products, key);
        if (!product) throw new SelectedProductsBuilderError(this.logContext, `no product matching product key "${key}"`);
        this._topUpProduct(product, minimumQuantity);
    }

    private addProductById(id: string, quantity: number) {
        const product = getProductById(this.productData.products, id);
        if (!product) throw new SelectedProductsBuilderError(this.logContext, `no product matching product id "${id}"`);
        this._addProduct(product, quantity);
    }

    /// Implementation

    private _addProduct(product: IProduct, quantity: number) {
        this.pushContext(`addProduct?product=${product.productKey ?? product.id}&quantity=${quantity}`);
        this.log();
        try {
            if (quantity <= 0) return;
            if (product === undefined) throw new SelectedProductsBuilderError(this.logContext, "product is undefined");

            const existingSelectedProduct = this.selectedProducts.find(p => p.id === product.id);
            if (existingSelectedProduct) {
                existingSelectedProduct.estimatedQuantity += quantity;
                existingSelectedProduct.quantity += quantity;
            } else {
                this.selectedProducts.push({
                    id: product.id,
                    productKey: product.productKey,
                    description: product.description,
                    estimatedQuantity: quantity,
                    quantity: quantity,
                    dhNo: product.dhNo,
                    productTypeId: product.productTypeId,
                    productGroupId: product.productGroupId,
                });
            }
        } finally {
            this.popContext();
        }
    }

    /**
     * Make sure that at least `minimumQuantity` units of `product` are selected
     * @note Only use this is `post` stage rules
     */
    private _topUpProduct(product: IProduct, minimumQuantity: number) {
        this.pushContext(`topUpProduct?product=${product.productKey ?? product.id}?minimumQuantity=${minimumQuantity}`);
        this.log();
        try {
            if (minimumQuantity <= 0) return;
            const existingSelectedProduct = this.selectedProducts.find(p => p.id === product.id);
            if (existingSelectedProduct) {
                existingSelectedProduct.estimatedQuantity = Math.max(existingSelectedProduct.estimatedQuantity, minimumQuantity);
                existingSelectedProduct.quantity = Math.max(existingSelectedProduct.quantity, minimumQuantity);
            } else {
                this.selectedProducts.push({
                    id: product.id,
                    productKey: product.productKey,
                    description: product.description,
                    estimatedQuantity: minimumQuantity,
                    quantity: minimumQuantity,
                    dhNo: product.dhNo,
                    productTypeId: product.productTypeId,
                    productGroupId: product.productGroupId,
                });
            }
        } finally {
            this.popContext();
        }
    }

    /// Logging

    private log(...args: any[]) {
        // console.debug('[SelectedProductsBuilder]', this.logContext.join('/'), ...args);
    }

    private logContext: string[] = [];

    private pushContext(v: string) {
        this.logContext.push(v);
    }

    private popContext() {
        this.logContext.pop();
    }
}

class SelectedProductsBuilderError extends Error {
    constructor(
        public readonly logContext: string[],
        public readonly cause: Error|string,
    ) {
        super(`[${logContext.join('/')}] ${cause}`)
    }
}

const flows: Record<FlowKey, BuilderRuleFlow> = {
    'default': defaultFlow,
    'complete-set': completeSetFlow,
};
