import React, { Fragment, useState } from 'react';
import { change, Field, getFormValues } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Modal } from 'semantic-ui-react';

import { TabSection } from '../../../../shared/components/TabSection';
import { renderNumeric } from "../../../../shared/components/FormControls";
import { useDispatchCallback } from '../../../../store/useDispatchCallback';
import { useLocalization } from '../../../../localization/hook';

import { ReduxFormKeys } from '../../../form/ReduxFormKeys';
import { formActionCreator } from '../../../form/FormActions';

import { COST_CALCULATION_FORM_VALUES_SELECTOR } from '../02-ConfigurationInputs/ConfigurationInputsForm';


export interface ICableModalProps {
    cable4mEstimatedQuantity: number;
    cable6mEstimatedQuantity: number;
    cable12mEstimatedQuantity: number;
    fm2mEstimatedQuantity: number;
}

const CableModal: React.FC<ICableModalProps> = props => {
    const [isOpen, setOpen] = useState(false);
    const [initialValues, setInitialValues] = useState(null);

    const costCalculationFormValues = useSelector(getFormValues(ReduxFormKeys.costCalculationForm) as COST_CALCULATION_FORM_VALUES_SELECTOR);
    const dispatch = useDispatch();

    const {
        cable4mEstimatedQuantity,
        cable6mEstimatedQuantity,
        cable12mEstimatedQuantity,
        fm2mEstimatedQuantity,
    } = props;

    const changeFieldValue = useDispatchCallback(dispatch, (field: string, value: any) => change(ReduxFormKeys.costCalculationForm, field, value))
    const resetFields = useDispatchCallback(dispatch, (fields: string[]) => formActionCreator.resetFields(ReduxFormKeys.costCalculationForm, fields));

    const open = React.useCallback(() => {    
        setOpen(true);

        const initialQuantities = {
            'rj-45-chf-a-4m-custom': costCalculationFormValues['rj-45-chf-a-4m-custom'],
            'rj-45-chf-a-6m-custom':  costCalculationFormValues['rj-45-chf-a-6m-custom'],
            'rj-45-chf-a-12m-custom':  costCalculationFormValues['rj-45-chf-a-12m-custom'],
            'fm-2m-custom':  costCalculationFormValues['fm-2m-custom'],
        };

        setInitialValues(initialQuantities);

        const estimatedQuantities = {
            'rj-45-chf-a-4m-custom': cable4mEstimatedQuantity,
            'rj-45-chf-a-6m-custom': cable6mEstimatedQuantity,
            'rj-45-chf-a-12m-custom': cable12mEstimatedQuantity ,
            'fm-2m-custom': fm2mEstimatedQuantity,
        };

        Object.keys(estimatedQuantities).forEach(key => {
            if (costCalculationFormValues[key] == null) {
                changeFieldValue(key as any, estimatedQuantities[key]);
            }
        });
    }, [
        setOpen, costCalculationFormValues, setInitialValues, changeFieldValue,
        cable4mEstimatedQuantity, cable6mEstimatedQuantity, cable12mEstimatedQuantity, fm2mEstimatedQuantity,
    ]);

    const save = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const cancel = React.useCallback(() => {
        setOpen(false);

        Object.keys(initialValues).forEach(key => {
            changeFieldValue(key, initialValues[key]);
        });
    }, [setOpen, changeFieldValue, initialValues]);

    const reset = React.useCallback(() => {
        setOpen(false);
        resetFields([
            'rj-45-chf-a-4m-custom',
            'rj-45-chf-a-6m-custom',
            'rj-45-chf-a-12m-custom',
            'fm-2m-custom',
        ]);
    }, [setOpen, resetFields]);

    const { localize } = useLocalization();

    const changed = React.useMemo(() => (
        costCalculationFormValues['rj-45-chf-a-4m-custom'] != null ||
        costCalculationFormValues['rj-45-chf-a-6m-custom'] != null ||
        costCalculationFormValues['rj-45-chf-a-12m-custom'] != null ||
        costCalculationFormValues['fm-2m-custom'] != null
        ), [costCalculationFormValues]);

    return (
        <Fragment>
            <Modal
                trigger={<span> - <a onClick={() => open()}>{localize(changed ? 'cableAdjust.button.adjusted' : 'cableAdjust.button.default')}</a></span>}
                open={isOpen}
                onClose={() => cancel()}
            >
                <Modal.Header>
                    <div className="title">{localize('cableAdjust.title')}</div>
                    <div className="close-button" onClick={x => cancel()}><i aria-hidden="true" className="close icon"></i></div>
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <TabSection>
                            <Form>
                                <Form.Group inline>
                                    <span className="cable-modal-field-pre-label">({localize('cableAdjust.calculated')}: {cable4mEstimatedQuantity})</span>
                                    <Field
                                        min={0}
                                        max={100}
                                        label="RJ45-CHF-A-4M"
                                        labelPosition="end"
                                        name="rj-45-chf-a-4m-custom"
                                        component={renderNumeric}
                                    />
                                </Form.Group>
                                <Form.Group inline>
                                    <span className="cable-modal-field-pre-label">({localize('cableAdjust.calculated')}: {cable6mEstimatedQuantity})</span>
                                    <Field
                                        min={0}
                                        max={100}
                                        label="RJ45-CHF-A-6M"
                                        labelPosition="end"
                                        name="rj-45-chf-a-6m-custom"
                                        component={renderNumeric}
                                    />
                                </Form.Group>
                                <Form.Group inline>
                                    <span className="cable-modal-field-pre-label">({localize('cableAdjust.calculated')}: {cable12mEstimatedQuantity})</span>
                                    <Field
                                        min={0}
                                        max={100}
                                        label="RJ45-CHF-A-12M"
                                        labelPosition="end"
                                        name="rj-45-chf-a-12m-custom"
                                        component={renderNumeric}
                                    />
                                </Form.Group>
                                <Form.Group inline className="no-margin-bottom">
                                    <span className="cable-modal-field-pre-label">({localize('cableAdjust.calculated')}: {fm2mEstimatedQuantity})</span>
                                    <Field
                                        min={0}
                                        max={100}
                                        label={localize('Function Module FM-2M')}
                                        labelPosition="end"
                                        name="fm-2m-custom"
                                        component={renderNumeric}
                                    />
                                </Form.Group>
                            </Form>
                        </TabSection>
                        <br/>
                        <div className="btn-group centered">
                            <Button primary onClick={() => save()}>
                                {localize('Apply')}
                            </Button>
                            <Button secondary onClick={() => reset()}>
                                {localize('Reset')}
                            </Button>
                        </div>

                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </Fragment>
    );
};

export default CableModal;
