/** This file is a library of simple validation functions for Javascript values, producing Typescript type hints.
 * 
 * The origin of this library lies in the various API clients based on JsonRESTApiClient.
 * The included functions aid in the creation of functions suitable for
 * the `validator` argument of `protected JsonRESTApiClient._fetchJson()`.
 * 
 * This uses zero dependencies outside the Javascript runtime functions:
 * - `Array.isArray`
 * - `Array.prototype.every`
 * - `Object.prototype.hasOwnProperty`
 */

/** Checks that `value` is an array and that every element passes validation by `elementValidator`
 * @example ```
 * const v: unknown = ...;
 * 
 * if (isArrayOf(isObjectWithProperties('id'))(v)) {
 *     // Typescript hint: v is { id: any; }[]
 * }
 * ```
 */
export function isArrayOf<T>(elementValidator: (e: unknown) => e is T) {
    return (value: unknown): value is T[] => Array.isArray(value) && Array.prototype.every.call(value, elementValidator);
}

/** Checks that `value` is an object and that it has all the properties on keys `keys`
 * @example ```
 * const v: unknown = ...;
 * 
 * if (isObjectWithProperties('id', 'name')(v)) {
 *     // Typescript hint: v is { id: any; name: any; }
 * }
 * ```
 */
export function isObjectWithProperties<KeysT extends PropertyKey>(...keys: KeysT[]) {
    return (value: unknown): value is { [x in KeysT]: any; } => (
        !!value &&
        typeof value === 'object' &&
        keys.every(key => Object.prototype.hasOwnProperty.call(value, key))
    );
}
