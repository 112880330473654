import {cloneDeep, orderBy} from "lodash-es";


import {PRODUCT_CATEGORY, PRODUCT_GROUP} from '../product/lookup';
import { IProduct, IProductGroup } from "../product/types";
import {IFormValue} from "../project/ProjectModels";

import {
    ICalculationFormsState,
    IProjectProduct
} from "./CalculationModels";
import {
    BasicInputsFormModel,
    ConfigurationInputsFormModel, 
    VentilationComponentsFormModel,
    CostCalculationFormModel,
    defaultFormValues
} from "./models/FormModels";
import { ICalculationState } from "./CalculationReducer";


// Product Data Lookups
export function getProductById(productsData: IProduct[], productId: string): IProduct|null {
    return productsData.find(p => p.id === productId);
}

export function getProductByKey(productsData: IProduct[], key: string): IProduct|null {
    return productsData.find(x => x.productKey === key);
}

export function getAllProductsByCategories(categories: PRODUCT_CATEGORY[], productsData: IProduct[], productGroupsData: IProductGroup[]): IProduct[] {
    const groupsOfCategories = productGroupsData.filter(g => !!categories.find(c => c === g.productCategoryId));
    return productsData.filter(
        product => !!groupsOfCategories.find(x => x.id === product.productGroupId)
    );
}

export function filterProductsByGroup(group: IProductGroup | PRODUCT_GROUP, productsData: IProduct[]): IProduct[] {
    const groupId: PRODUCT_GROUP = Object.prototype.hasOwnProperty.call(group, 'id')
        ? (group as IProductGroup).id
        : (group as PRODUCT_GROUP);
    
    return productsData.filter(x => x.productGroupId === groupId);
}

// -----
// FormValues
export function getFormValues(formValues: IFormValue[]): ICalculationFormsState {
    const forms: ICalculationFormsState = cloneDeep(defaultFormValues);

    setFormValuesFromArray(new BasicInputsFormModel(), formValues, forms, forms.basicInputsForm.values);
    setFormValuesFromArray(new ConfigurationInputsFormModel(), formValues, forms, forms.configurationInputsForm.values);
    setFormValuesFromArray(new VentilationComponentsFormModel(), formValues, forms, forms.ventilationComponentsForm.values);
    setFormValuesFromArray(new CostCalculationFormModel(), formValues, forms, forms.costCalculationForm.values);

    return forms;
}

function setFormValuesFromArray(formModel: any, formValues: Array<IFormValue>, forms: ICalculationFormsState, targetFormModel: any) {
    Object.keys(formModel).forEach((key) => {
        const formValue = formValues.find(f => f.key === key);
        if (formValue) {
            targetFormModel[key] = formValue.value;
        }
    });
}

export function getFormValuesDictionaryFromForms(form: ICalculationFormsState): Array<IFormValue> {
    if (!form) {
        return [];
    }

    const formValues = {
        ...form.basicInputsForm?.values,
        ...form.configurationInputsForm?.values,
        ...form.ventilationComponentsForm?.values,
        ...form.costCalculationForm?.values
    };

    let kvps = Object.keys(formValues).map(key => {
        return {
            key,
            value: formValues[key],
        };
    });

    // Filter out undefined values
    kvps = kvps.filter(formValue => formValue.value !== undefined);

    return kvps;
}


// -----
// Recommendation
export function getRecommendedProductId(ventComponents: IProduct[], productGroupsData: IProductGroup[]) {
    const groups = productGroupsData.filter(
        g => !!ventComponents.find(vc => vc.productGroupId === g.id) && g.recommendationOrder2 > 0
    );

    if (groups.length === 0) return null;

    const sortedGroups = orderBy(groups, 'recommendationOrder2', 'asc');

    const recommendedGroupId = sortedGroups[0].id;
    const sortedComponents = orderBy(
        ventComponents.filter(x => x.productGroupId === recommendedGroupId),
        ['width', 'height'],
        ['asc', 'asc']
    );

    if (sortedComponents && sortedComponents.length > 0) {
        return sortedComponents[0].id;
    }
}


// -----

export function getProjectProductsFromForms(form: ICalculationFormsState, loadedProjectId: string, calculationState: ICalculationState): IProjectProduct[] {

    const ventComp = form.ventilationComponentsForm.values;
    const filteredVentilationComponentsKeys = Object.keys(ventComp).filter(x => x.startsWith('quantity-'));

    const ventilationComponents = filteredVentilationComponentsKeys.map((key: string) => (<IProjectProduct>{
        productId: key.replace('quantity-', ''),
        quantity: ventComp[key],
        projectId: loadedProjectId,
        productCategoryId: PRODUCT_CATEGORY.VENTCOMP
    }));

    const nonStandardComponents = calculationState.nonStandardComponents.map(x => (<IProjectProduct>{
        productId: x.productId,
        description: x.description,
        quantity: x.quantity,
        price: x.price,
        projectId: loadedProjectId,
        productCategoryId: PRODUCT_CATEGORY.NONSTANDARDCOMPONENTS
    }));

    return ventilationComponents.concat(nonStandardComponents);
}
