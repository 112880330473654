import * as React from "react";
import { required, Validator } from "redux-form-validators";

import { renderCheckbox, renderInput, renderNumeric, renderSearchableSelect, renderSelect } from "../../shared/components/FormControls";
import { LocalizationKey } from "../../localization/LocalizationService";
import { LocalizationProps, useLocalization } from "../../localization/hook";

export type FieldDisabledEntry<V> = { name: FieldName<V>, disabled: (values: V) => boolean, reason: FieldName<V> };

const fieldDisabledReasons = <V>(values: V, fieldDisabledMappings: FieldDisabledEntry<V>[]) => (name: FieldName<V>) => {
    return fieldDisabledMappings.filter(m => m.name === name && m.disabled(values)).map(m => m.reason);
}

const fieldDisabled = <V>(values: V, fieldDisabledMappings: FieldDisabledEntry<V>[]) => (name: FieldName<V>) => {
    return fieldDisabledReasons(values, fieldDisabledMappings)(name).length !== 0;
}

export type MutuallyExclusiveOptions<X, Y> = {
    aTruthy: (v: X) => boolean,
    bTruthy: (v: Y) => boolean,
};

/** Generate `fieldDisabled` entries for the mutual exclusion of two `formValue`s */
export const mutuallyExclusive = <V>() =>
    <A extends FieldName<V>, B extends FieldName<V>>(a: A, b: B, options: Partial<MutuallyExclusiveOptions<V[A], V[B]>> = {}): FieldDisabledEntry<V>[] => {
        const defaultTruthy = (value: any) => !!value;

        const opts: MutuallyExclusiveOptions<V[A], V[B]> = {
            aTruthy: defaultTruthy,
            bTruthy: defaultTruthy,
            ...options,
        };

        return [
            { name: a, disabled: (values: V) => opts.bTruthy(values[b]) && !opts.aTruthy(values[a]), reason: b },
            { name: b, disabled: (values: V) => opts.aTruthy(values[a]) && !opts.bTruthy(values[b]), reason: a },
        ];
};

// Both a key on basicInputFormValues and a localization key
export type FieldName<V> = (keyof V) & LocalizationKey;

const fieldComponentMapping = {
    'input': renderInput,
    'checkbox': renderCheckbox,
    'select': renderSelect,
    'select-searchable': renderSearchableSelect,
    'numeric': renderNumeric,
};

export type FieldOptions = {
    required: boolean,
    label: string,
    validate: Validator[],
    component: keyof typeof fieldComponentMapping,
};

export type FieldProps = {
    name: string,
    label: string,
    validate: Validator[],
    disabled: boolean,
    component: (field: any) => JSX.Element,
};

/**
 * Prepare some `<Field>` props:
 * - `name` as passed,
 * - `label` with localization (and asterisk if `required`),
 * - `validate` with(out) `required()`
 * - `disabled` based on `fieldDisabledMappings`
 * - `component` for the archetypes "input", "checkbox", "select", "numeric"
 */
const fieldProps = <V>(values: V, fieldDisabledMappings: FieldDisabledEntry<V>[], { localize }: LocalizationProps) => (name: FieldName<V>, options: Partial<FieldOptions> = {}): FieldProps => {
    const isRequired = options.required || false;

    const label = (options.label || localize('field.' + name)) + (isRequired ? '*' : '');
    const validate = [...(isRequired ? [required()] : []), ...(options.validate || [])];
    const disabled = fieldDisabled(values, fieldDisabledMappings)(name);
    const component = !!options.component ? fieldComponentMapping[options.component] : undefined;

    return {
        name,
        label,
        validate,
        disabled,
        component,
    };
};

export type FieldDisabledMessageProps<V> = { name: FieldName<V> };
const FieldDisabledMessage = <V>(values: V, fieldDisabledMappings: FieldDisabledEntry<V>[], { localize }: LocalizationProps): React.FC<FieldDisabledMessageProps<V>> => props => {
    const reasons = fieldDisabledReasons(values, fieldDisabledMappings)(props.name);
    if (reasons.length === 0) return null;

    const firstReason = reasons[0];

    return React.createElement("p", { style: { opacity: 0.5 } }, localize('info.disabledBy', { reason: localize('field.' + firstReason) }));
};

export function useFormUtility<V>(values: V, fieldDisabledMappings: FieldDisabledEntry<V>[] = []) {
    const loc = useLocalization();

    return React.useMemo(() => ({
        fieldProps: fieldProps(values, fieldDisabledMappings, loc),
        fieldDisabled: fieldDisabled(values, fieldDisabledMappings),
        fieldDisabledReason: fieldDisabledReasons(values, fieldDisabledMappings),
        FieldDisabledMessage: FieldDisabledMessage(values, fieldDisabledMappings, loc),
    }), [values, fieldDisabledMappings, loc]);
};
