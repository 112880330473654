import { JsonRESTApiClient } from "../api/JsonRESTApiClient";
import { isObjectWithProperties } from "../api/simple-validators";
import { ICalculationInput, ICosts } from "./CalculationModels";


/** @see `DH.BlueKitConnect.Backend.Features.Calculation.CalculationController` */
export class CalculationApiV1Client extends JsonRESTApiClient {

    /// API Endpoints

    public async calculateCosts(calculationInput: ICalculationInput): Promise<ICosts> {
        return await this._fetchJson(
            this._getUrl(['calculate-costs']),
            {
                method: 'POST',
                headers: await this._getHeaders(),
                body: JSON.stringify(calculationInput),
            },
            CalculationApiV1Client.ValidateICosts
        );
    }

    /// Validators for API types

    private static ValidateICosts(v: unknown): v is ICosts {
        // @TODO: A more precise validation
        return isObjectWithProperties('blueKitSystemControlComponents')(v) ||
            isObjectWithProperties('aioBasicSet')(v);
    }
}
