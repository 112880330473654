/** A base implementation for a client targeting JSON-based REST-ish APIs. */
export abstract class JsonRESTApiClient {
    /**
     * @param _baseUrl The URL prefix used by `_getUrl()`
     * @param _tokenGetter A function used by `_getHeaders()` that asynchronously returns a `Bearer` token for the `Authorization` HTTP Request Header
     */
    constructor(
        private readonly _baseUrl: string,
        private readonly _tokenGetter: () => Promise<string|null>
    ) {}

    /** Fetch a JSON value; extends the `fetch()` API.
     * @template T The result type
     * @param input Passed to `fetch()` as-is
     * @param init Passed to `fetch()` as-is
     * @param validator A function that receives the parsed JSON value and validates it to match the result type `T`
     * @returns a parsed JSON value of type `T`
     * @throws ApiFetchError if the internal `fetch()` call fails (network error, etc.)
     * @throws ApiStatusCodeError if the HTTP Status Code is not in the `2xx` range
     * @throws ApiSyntaxError if JSON parsing fails
     * @throws ApiInternalError if an internal call throws
     * @throws ApiValidationError if `validator` rejects the parsed JSON value
     */
    protected async _fetchJson<T>(input: RequestInfo, init?: RequestInit, validator?: (v: unknown) => v is T): Promise<T> {
        const response = await this._fetch(input, init);

        let result: any = undefined;
        try {
            result = await response.json();
        } catch (e /* : unknown */) {
            if (e instanceof SyntaxError) {
                throw new ApiSyntaxError(e);
            } else {
                // what/when could this happen?
                throw new ApiInternalError(e);
            }
        }

        if (validator && !validator(result)) {
            throw new ApiValidationError(result);
        }

        return result;
    };

    /** Fetch something; extends the `fetch()` API.
     * @param input Passed to `fetch()` as-is
     * @param init Passed to `fetch()` as-is
     * @returns the result value of `fetch()` of type `Response`
     * @throws ApiFetchError if the internal `fetch()` call fails (network error, etc.)
     * @throws ApiStatusCodeError if the HTTP Status Code is not in the `2xx` range
     * */
    protected async _fetch(input: RequestInfo, init?: RequestInit) {
        let response: Response = undefined;
        try {
            response = await fetch(input, init);
        } catch (e /* : unknown */) {
            throw new ApiFetchError(e);
        }

        if (!response.ok) {
            throw new ApiStatusCodeError(response.status, response.statusText, response);
        }
        return response;
    }

    /** Gets a URL by joining the `parts` and (if present) attaching the URI-encoded `queryparams`
     * @param parts URL path parts that are joined together with "/"
     * @param queryparams An array of query parameter key-value pairs
     * @example _getUrl(["a", "b"], [["message", "Hello?"], ["l", 5]]) produces 
     *          "{_baseUrl}/a/b?message=Hello%3F&l=5"
     */
    protected _getUrl(parts: string[] = [], queryparams: [string,string|number|boolean][] = []) {
        const suffix = parts.join('/'); 
        const querystring = queryparams
            .map(([k,v]) => encodeURIComponent(k) + '=' + encodeURIComponent(v))
            .join('&');

        return this._baseUrl +
            `${suffix.length?'/':''}${suffix}` +
            `${querystring.length?'?':''}${querystring}`;
    }

    /** Get the common headers for requests:
     * - Content-Type: from `contentType` argument (default "application/json"), unset if passing `null`
     * - Authorization: constructed with the `_tokenGetter`
     * @param contentType The value of the "Content-Type" header
     * @returns An object of headers
     */
    protected async _getHeaders(contentType: string|null = 'application/json') {
        const headers: Record<string, string> = {};

        if (!!contentType) {
            headers['Content-Type'] = contentType;
        }

        const token = await this._tokenGetter();
        if (!!token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        return headers;
    }
}

/** Common base error type */
abstract class ApiError extends Error {}

/** Thrown if the internal `fetch()` call fails (network error, etc.) */
export class ApiFetchError extends ApiError {
    constructor(public readonly innerError: Error|unknown) {
        super(`ApiFetchError: ${innerError instanceof Error ? innerError.message : innerError}`);
    }
}

/** Thrown if the HTTP Status Code is not in the `2xx` range */
export class ApiStatusCodeError extends ApiError {
    constructor(public readonly status: number, public readonly statusText: string, public readonly body: Body) {
        super(`ApiStatusCodeError: ${status} ${statusText}`);
    }
}

/** Thrown if parsing the response fails (i.e. if `Body.prototype.json()` throws `SyntaxError`) */
export class ApiSyntaxError extends ApiError {
    constructor(public readonly innerError: SyntaxError) {
        super(`ApiSyntaxError: ${innerError.message}`);
    }
}

/** Thrown if the response content validation fails */
export class ApiValidationError extends ApiError {
    constructor(public readonly value: any, public readonly expectedTypeName?: string) {
        super(`ApiValidationError: ${expectedTypeName ?? '<unnamed>'}`);
    }
}

/** Thrown if an internal call throws (from `fetch()` API) */
export class ApiInternalError extends ApiError {
    constructor(public readonly v: Error|unknown) {
        super(`ApiInternalError: ${v instanceof Error ? v.message : v}`);
    }
}
