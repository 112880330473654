import { syncedErrorsToList } from "../../shared/components/error-list";

export const calculationTabs = [
    'basic-input',
    'configuration',
    'ventilation-components',
    'cost-calculation',
] as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type calculationTabs = typeof calculationTabs[number];
export function isCalculationTab(v: unknown): v is calculationTabs {
    return typeof v === 'string' && calculationTabs.includes(v as any);
}

export const LIFT_AREA_STEP = 20000;

export function getLiftAreaMaxValue(liftAreaValue: number) {
    const LIFT_AREA_MAX_VALUE = 10000 * 10000 * 2.5 / 100;
    const LIFT_AREA_MAX_VALUE_BIG = 10000 * 21000 * 2.5 / 100;
    return (liftAreaValue < LIFT_AREA_MAX_VALUE - LIFT_AREA_STEP * 2) ? LIFT_AREA_MAX_VALUE : LIFT_AREA_MAX_VALUE_BIG;
}

export const genericErrorDict = {'*': "Es ist ein Fehler aufgetreten"};
export const genericErrorList = syncedErrorsToList(genericErrorDict);

export interface IEmpty {
}

interface IActionDef<T, P> {
    meta: T;
    payload?: P;
}

export const createAction = <T, P>(prefix: string, def: IActionDef<T, P>) => {
    return <const>{
        ...def,
        type: `${prefix}/${def.meta}`,
    };
};
