import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Spinner } from "./shared/components/Spinner";
import { ProjectExplorerPage } from "./features/project/ProjectExplorerPage";
import { CalculationPage } from "./features/calculation/CalculationPage";
import { calculationTabs } from './features/calculation/constant';
import { SimulatorPage } from "./features/simulator/SimulatorPage";

import { Layout as LayoutV1 } from './layout/Layout';
import { AccessControl } from './layout/AccessControl';

import { canAccessConnect } from './features/auth/PermissionService';
import { useUser } from './features/auth/User.hook';
import { useAuthenticated } from "./features/auth/Auth.hook";
import { useSettings } from './features/settings/Settings.hook';
import { useFreshworksWidgetPrefillUser } from './features/freshworks-widget/useFreshworksWidget.hook';


const App: React.FC<{}> = () => {
    const { isAuthenticated } = useAuthenticated();
    const { fetchSettings } = useSettings();
    const { fetchUser, user } = useUser();

    // Once authenticated, fetch settings and user
    React.useEffect(() => {
        if (isAuthenticated) {
            console.debug('[App] Loading settings');
            fetchSettings();
            console.debug('[App] Loading user');
            fetchUser();
        }
    }, [isAuthenticated]);

    useFreshworksWidgetPrefillUser(user);

    if (!isAuthenticated) {
        return <LayoutV1><Spinner/></LayoutV1>;
    }

    return (<>
        <Switch>
            <Route exact path="/">
                <LayoutV1>
                    <AccessControl checkFn={canAccessConnect}>
                        <ProjectExplorerPage/>
                    </AccessControl>
                </LayoutV1>
            </Route>
            <Route exact path="/null"><Redirect to="/"/></Route>

            <Route exact path="/calculation"><Redirect to="/calculation/new/basic-input"/></Route>
            <Route exact path="/calculation/new"><Redirect to="/calculation/new/basic-input"/></Route>
            <Route exact path="/calculation/:projectId" render={({ match }) => (
                <Redirect to={`/calculation/${match.params['projectId']}/basic-input`}/>
            )}/>

            <Route exact
                path={
                    "/calculation" +
                    "/:projectId(new|C[0-9]{8}-[0-9]{1,})" +
                    "/:form(basic-input|configuration|ventilation-components|cost-calculation|as-template)"
                }
                render={routeProps => {
                    const projectId = routeProps.match.params["projectId"];
                    const calculationTab = routeProps.match.params["form"] as (calculationTabs|'as-template');
                    return (
                <LayoutV1>
                    <AccessControl checkFn={canAccessConnect}>
                        <CalculationPage projectId={projectId} calculationTab={calculationTab} />
                    </AccessControl>
                </LayoutV1>
                    );
                }}
            />

            <Route exact path="/simulator">
                <LayoutV1>
                    <AccessControl checkFn={canAccessConnect}>
                        <SimulatorPage/>
                    </AccessControl>
                </LayoutV1>
            </Route>
        </Switch>
    </>);
}

export default App;
