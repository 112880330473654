import { JsonRESTApiClient } from "../api/JsonRESTApiClient";
import { isArrayOf, isObjectWithProperties } from "../api/simple-validators";
import { IFile } from "../project/ProjectModels";

/** @see `DH.BlueKitConnect.Backend.Features.File.FileController` */
export class FileApiV1Client extends JsonRESTApiClient {

    /// API Endpoints

    public async uploadFile(projectId: string, file: File, fileName: string): Promise<IFile> {
        this._validateFileForUpload(file);

        const body = new FormData();
        body.append("File", file, fileName);

        return await this._fetchJson(this._getUrl([projectId]), {
            method: 'POST',
            headers: await this._getHeaders(null),
            body,
        }, FileApiV1Client.ValidateIFile);
    }

    public async listFiles(projectId: string): Promise<IFile[]> {
        return await this._fetchJson(
            this._getUrl([projectId]),
            {
                method: 'GET',
                headers: await this._getHeaders(),
            },
            isArrayOf(FileApiV1Client.ValidateIFile)
        );
    }

    public async downloadFile(projectId: string, fileName: string): Promise<Blob> {
        const response = await this._fetch(this._getUrl([projectId, fileName, 'download']), {
            method: 'GET',
            headers: await this._getHeaders(),
        });

        return await response.blob();
    }

    public async deleteFile(projectId: string, fileName: string): Promise<void> {
        await this._fetch(this._getUrl([projectId, fileName]), {
            method: 'DELETE',
            headers: await this._getHeaders(),
        });
    }

    /// Validators for API types

    private static ValidateIFile(v: unknown): v is IFile {
        return isObjectWithProperties('id', 'name', 'path')(v) &&
            typeof v.id === 'string' &&
            typeof v.name === 'string' &&
            typeof v.path === 'string';
    }

    /// Utilities

    private _validateFileForUpload(file: File) {
        if (file.size > 10 * 1024 * 1024) {
            throw new Error('Datei ist zu groß. Erlaubt sind max. 10MB.');
        }
    }
}
